import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import SimpleNavbar from '../../../components/SimpleNavbar'
import IAuthState, { isL4, IUser } from '../../../model/IAuthState'
import IChatMessage, { EmptyChatMessage } from '../../../model/IChatMessage'
import IRipplesState from '../../../model/IRipplesState'
import { setUser } from '../../../redux/ripples.actions'
import {
  fetchChatMessages,
  fetchChatMessagesBeforeDate,
  removeChatMessage,
  sendCatlChatMessage,
  sendChatMessage,
} from '../../../services/ChatUtils'
import DateService from '../../../services/DateUtils'
import { fetchUsersByDomain, getCurrentUser } from '../../../services/UserUtils'
import { isCatlBrokerServiceAvailable } from '../../../services/ServicesUtils'
import { IServicesDefinition } from '../../../model/IService'
const { NotificationManager } = require('react-notifications')

interface StateType {
  loading: boolean
  chatName: string
  messages: IChatMessage[]
  isRemoveMessageModalOpen: boolean
  messageToRemove: IChatMessage | undefined
  chatMessageTextInput: string
  chatMessageDestinationInput: string
  users: {
    name: string
    email: string
  }[]
  enableAutoScroll: boolean
  isChatOverCatlActive: boolean
}

interface PropsType {
  setUser: (user: IUser) => any
  auth: IAuthState
  servicesAvailable: IServicesDefinition[]
}

export class Chat extends Component<PropsType, StateType> {
  public notificationSystem: any = null
  public timerID: number = 0

  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      chatName: '',
      messages: [],
      isRemoveMessageModalOpen: false,
      messageToRemove: undefined,
      chatMessageTextInput: '',
      chatMessageDestinationInput: '',
      users: [],
      enableAutoScroll: true,
      isChatOverCatlActive: false,
    }
    this.getUsers = this.getUsers.bind(this)
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.loadChatMessages = this.loadChatMessages.bind(this)
    this.loadChatName = this.loadChatName.bind(this)
    this.cleanInputFields = this.cleanInputFields.bind(this)
    this.toggleRemoveMessageModal = this.toggleRemoveMessageModal.bind(this)
    this.scrollDownMessages = this.scrollDownMessages.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  private getUsers() {
    fetchUsersByDomain(this.state.chatName)
      .then((data) => {
        const users = data.map((m: any) =>
          Object.assign({}, m, {
            name: m.name,
            email: m.email,
          })
        )
        this.setState({ users })
      })
      .catch((_) => {
        NotificationManager.warning('Failed to fetch users')
      })
  }

  private async loadChatName() {
    const chatName = localStorage.getItem('chat-name')
    if (chatName !== null) {
      this.setState({ chatName })
      this.loadChatMessages()
    } else {
      NotificationManager.error('Cannot read chat messages...')
    }
  }

  private async loadChatMessages() {
    const chatName = localStorage.getItem('chat-name')
    if (chatName !== null) {
      const chatMessages: IChatMessage[] = await fetchChatMessages(chatName)
      this.updateChatMessages(chatMessages)
    }

    if (this.state.messages.length > 0 && this.state.enableAutoScroll) {
      this.scrollDownMessages()
      this.setState({ enableAutoScroll: false })
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    this.setState({ loading: false })

    if (this.props.auth.authenticated) {
      if (!isL4(this.props.auth)) {
        this.loadChatName()
        this.getUsers()
        this.timerID = window.setInterval(this.loadChatMessages, 5000)
      } else {
        NotificationManager.error('Invalid permission...')
      }
    } else {
      NotificationManager.error('Please login')
    }
  }

  public componentWillUnmount() {
    clearInterval(this.timerID)
    localStorage.removeItem('chat-name')
  }

  private renderMessage(message: IChatMessage, index: number) {
    let messageClass: string = ''
    let ownMessage: boolean = false
    if (this.props.auth.currentUser.email === message.user) {
      messageClass = 'chatMessage'
      ownMessage = true
    } else if (
      this.props.auth.currentUser.email !== message.user &&
      this.props.auth.currentUser.email === message.destination
    ) {
      messageClass = 'chatMessage_right_destination'
    } else {
      messageClass = 'chatMessage_right'
    }

    // catl message
    if (
      !message.user.includes('@') ||
      message.text.includes('Context:') ||
      (message.destination && !message.destination.includes('@'))
    ) {
      messageClass += ' catlMessage'
    }

    return (
      <div key={'chatMessage_' + index} id={'message-' + index} className={messageClass}>
        <div className={'chatMessageHeader'}>
          <div className={'chatMessageUser'}>{message.user}</div>
          <div className={'chatMessageDate'}>
            {DateService.formatDate(message.timestamp)}
            {ownMessage ? (
              <i
                id="deleteMessageBtn"
                className="fas fa-trash"
                color="primary"
                title="Remove message"
                onClick={() => this.toggleRemoveMessageModal(message)}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <hr />
        <div className={'chatMessageBody'}>
          <div>
            {message.text.split('\n').map((text, i) => {
              if (text.includes('Context:')) {
                return <b key={'chatMessage_msg' + index}>{text}</b>
              } else {
                return <p key={'chatMessage_msg' + index}>{text}</p>
              }
            })}
          </div>
        </div>
        <span className={'chatMessageDestiantion'}>Destination: {message.destination}</span>
      </div>
    )
  }

  private renderChatMessages() {
    return this.state.messages.map((message, index) => this.renderMessage(message, index))
  }

  private renderChatFooter() {
    return (
      <>
        {this.renderChatFooterLeftContent()}
        {this.renderChatFooterRightContent()}
      </>
    )
  }

  private renderChatFooterLeftContent() {
    return (
      <div className="chatFooter-left">
        <textarea
          className="chatMessageTextInput"
          placeholder="Text message..."
          onChange={(evt) => {
            this.setState({ chatMessageTextInput: evt.target.value })
          }}
          value={this.state.chatMessageTextInput}
        />

        {this.state.isChatOverCatlActive ? (
          <label className="chatMessageDestinationInput">
            <input
              type="text"
              placeholder="Destination node (optional)"
              onChange={(event: any) => this.setState({ chatMessageDestinationInput: event.target.value })}
            />
          </label>
        ) : (
          <>
            <label className="chatMessageDestinationInput">
              <input
                placeholder="Choose destination email (optional)"
                type="text"
                list="data"
                onChange={(event: any) => this.setState({ chatMessageDestinationInput: event.target.value })}
              />
            </label>

            <datalist id="data">
              {this.state.users.map((user, index) => (
                <option key={index} value={user.name}>
                  {' '}
                  {user.email}{' '}
                </option>
              ))}
            </datalist>
          </>
        )}
      </div>
    )
  }

  private renderChatFooterRightContent() {
    return (
      <div className="chatFooter-right">
        {this.state.chatMessageTextInput.length > 0 && (
          <>
            <Button
              className="m-1"
              size="sm"
              color="primary"
              onClick={() =>
                this.state.isChatOverCatlActive ? this.handleSendCatlChatMessage() : this.handleSendChatMessage()
              }
            >
              Send
            </Button>

            {isCatlBrokerServiceAvailable(this.props.servicesAvailable) && (
              <label className={'chatCatlOption'}>
                <input
                  type="checkbox"
                  className={'chatCatlOption'}
                  checked={this.state.isChatOverCatlActive}
                  onChange={() => this.setState({ isChatOverCatlActive: !this.state.isChatOverCatlActive })}
                />
                {'CATL'}
              </label>
            )}
          </>
        )}
      </div>
    )
  }

  private buildRemoveMessageModal() {
    return (
      <Modal isOpen={this.state.isRemoveMessageModalOpen} toggle={() => this.toggleRemoveMessageModal(undefined)}>
        <ModalHeader toggle={() => this.toggleRemoveMessageModal(undefined)}>Remove message</ModalHeader>
        <ModalBody>
          <div> Message will be removed. Do you want to continue?</div>
          <Button color="danger" onClick={() => this.removeChatMessage()}>
            Remove
          </Button>
        </ModalBody>
      </Modal>
    )
  }

  private cleanInputFields() {
    this.setState({
      chatMessageTextInput: '',
      chatMessageDestinationInput: '',
    })
  }

  private toggleRemoveMessageModal(message: IChatMessage | undefined) {
    this.setState({
      isRemoveMessageModalOpen: !this.state.isRemoveMessageModalOpen,
      messageToRemove: message,
    })
  }

  private async handleSendChatMessage() {
    if (this.state.chatMessageTextInput.length > 0) {
      let chatDestination: string = ''
      this.state.users.forEach((user) => {
        if (this.state.chatMessageDestinationInput === user.name) {
          chatDestination = user.email
        }
      })

      const chatMessage: IChatMessage = EmptyChatMessage
      chatMessage.text = this.state.chatMessageTextInput
      chatMessage.destination = chatDestination
      chatMessage.mission = this.state.chatName
      chatMessage.user = this.props.auth.currentUser.email

      const resp = await sendChatMessage(chatMessage)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
        this.cleanInputFields()
        this.setState({ enableAutoScroll: true })
        this.loadChatMessages()
      } else {
        NotificationManager.warning(resp.message)
      }
    } else {
      NotificationManager.warning('Please insert message...')
    }
  }

  private async removeChatMessage() {
    if (this.state.messageToRemove !== undefined) {
      const resp = await removeChatMessage(this.state.messageToRemove)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
        this.toggleRemoveMessageModal(undefined)
        this.loadChatMessages()
      } else {
        NotificationManager.warning(resp.message)
      }
    }
  }

  private async handleSendCatlChatMessage() {
    if (this.state.chatMessageTextInput.length > 0) {
      const chatMessage: IChatMessage = EmptyChatMessage
      chatMessage.text = this.state.chatMessageTextInput
      chatMessage.destination = this.state.chatMessageDestinationInput
      chatMessage.mission = this.state.chatName
      chatMessage.user = this.props.auth.currentUser.email

      const resp = await sendCatlChatMessage(chatMessage)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
        this.cleanInputFields()
        this.setState({ enableAutoScroll: true })
        this.loadChatMessages()
      } else {
        NotificationManager.warning(resp.message)
      }
    } else {
      NotificationManager.warning('Please insert message...')
    }
  }

  private async handleScroll(e: any) {
    const element = e.target
    if (element.scrollTop === 0) {
      const messageAnchor: IChatMessage = this.state.messages[0]

      // fetch older messages
      const chatName = localStorage.getItem('chat-name')
      if (chatName !== null) {
        const newMessages: IChatMessage[] = await fetchChatMessagesBeforeDate(
          chatName,
          this.state.messages[0].timestamp
        )
        this.updateChatMessages(newMessages)
      }

      // auto scroll
      const index = this.state.messages.findIndex((m) => m.id === messageAnchor.id)
      const element = document.getElementById('message-' + index)
      if (element) {
        element.scrollIntoView()
      }
    }
  }

  private updateChatMessages(newMessages: IChatMessage[]) {
    const olderChatMessages: IChatMessage[] = this.state.messages
    newMessages.forEach((m) => {
      let messageIsPresent: boolean = false
      if (this.state.messages.some((item) => m.id === item.id)) {
        messageIsPresent = true
      }

      if (!messageIsPresent) {
        olderChatMessages.push(m)
      }
    })

    olderChatMessages.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
    this.setState({ messages: olderChatMessages })
  }

  private scrollDownMessages() {
    const num: number = this.state.messages.length - 1
    const element = document.getElementById('message-' + num)
    if (element) {
      element.scrollIntoView()
    }
  }

  public render() {
    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      return (
        <>
          <SimpleNavbar auth={this.props} />
          <div className="chatTitle">{this.state.chatName}</div>
          <div className="chatContainer" onScroll={this.handleScroll}>
            {this.renderChatMessages()}
          </div>
          <div className="chatFooter">{this.renderChatFooter()}</div>

          {this.buildRemoveMessageModal()}
        </>
      )
    } else {
      return <></>
    }
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    servicesAvailable: state.servicesAvailable,
  }
}

const actionCreators = {
  setUser,
}

export default connect(mapStateToProps, actionCreators)(Chat)
