import { IServicesDefinition } from '../model/IService'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export async function fetchDefaultServices() {
  return request({
    url: `${apiURL}/services`,
  })
}

export async function fetchVisibleServices() {
  return request({
    url: `${apiURL}/services/visible`,
  })
}

export async function updateDefaultService(serviceName: string, paramKey: string, paramVal: string) {
  return request({
    method: 'POST',
    body: JSON.stringify(paramVal),
    url: `${apiURL}/services/update/${serviceName}/${paramKey}`,
  })
}

export async function updateServiceVisibility(serviceName: string, available: boolean) {
  return request({
    method: 'POST',
    body: JSON.stringify(available),
    url: `${apiURL}/services/update/${serviceName}`,
  })
}

export async function fetchServicesDefinitionByCampaign(campaignName: string) {
  return request({
    url: `${apiURL}/services/definition?campaign=${campaignName}`,
  })
}

export async function addServiceDefinition(campaignName: string, serviceName: string) {
  return request({
    method: 'POST',
    url: `${apiURL}/services/definition/?campaign=${campaignName}&service=${serviceName}`,
  })
}

export async function updateServiceDefinition(
  serviceDefinition: IServicesDefinition,
  paramKey: string,
  paramVal: string
) {
  return request({
    method: 'POST',
    body: JSON.stringify(serviceDefinition),
    url: `${apiURL}/services/definition/update/?paramKey=${paramKey}&paramVal=${paramVal}`,
  })
}

export async function removeServiceDefinition(serviceDefinition: IServicesDefinition) {
  return request({
    method: 'DELETE',
    body: JSON.stringify(serviceDefinition),
    url: `${apiURL}/services/definition/`,
  })
}

export function isChatServiceAvailable(servicesAvailable: IServicesDefinition[]) {
  for (const service of servicesAvailable) {
    if (service.serviceName.includes('CHAT')) {
      return true
    }
  }
  return false
}

export function isCatlBrokerServiceAvailable(servicesAvailable: IServicesDefinition[]) {
  for (const service of servicesAvailable) {
    if (service.serviceName.includes('CATL_BROKER')) {
      return true
    }
  }
  return false
}

export function isMyMapsServiceAvailable(servicesAvailable: IServicesDefinition[]) {
  for (const service of servicesAvailable) {
    if (service.serviceName.includes('MY_MAPS')) {
      return true
    }
  }
  return false
}

export function isContactsServiceAvailable(servicesAvailable: IServicesDefinition[]) {
  for (const service of servicesAvailable) {
    if (service.serviceName.includes('CONTACTS')) {
      return true
    }
  }
  return false
}

export function isAdsbServiceAvailable(servicesAvailable: IServicesDefinition[]) {
  for (const service of servicesAvailable) {
    if (service.serviceName.includes('ADS_B')) {
      return true
    }
  }
  return false
}

export function isIntrusionServiceAvailable(servicesAvailable: IServicesDefinition[]) {
  for (const service of servicesAvailable) {
    if (service.serviceName.includes('INTRUSION')) {
      return true
    }
  }
  return false
}
