const L0 = 'l0'
const L1 = 'l1'
const L2 = 'l2'
const L3 = 'l3'
const L4 = 'l4'

export default interface IAuthState {
  authenticated: boolean
  currentUser: IUser
}

export function isL0(auth: IAuthState) {
  return auth.authenticated && auth.currentUser.role.toLowerCase() === L0
}

export function isL1(auth: IAuthState) {
  return auth.authenticated && auth.currentUser.role.toLowerCase() === L1
}

export function isL2(auth: IAuthState) {
  return auth.authenticated && auth.currentUser.role.toLowerCase() === L2
}

export function isL3(auth: IAuthState) {
  return auth.authenticated && auth.currentUser.role.toLowerCase() === L3
}

export function isL4(auth: IAuthState) {
  return auth.authenticated && auth.currentUser.role.toLowerCase() === L4
}

export function hasL1Permissions(auth: IAuthState) {
  return (
    auth.authenticated && (auth.currentUser.role.toLowerCase() === L0 || auth.currentUser.role.toLowerCase() === L1)
  )
}

export function hasL2Permissions(auth: IAuthState) {
  return (
    auth.authenticated &&
    (auth.currentUser.role.toLowerCase() === L0 ||
      auth.currentUser.role.toLowerCase() === L1 ||
      auth.currentUser.role.toLowerCase() === L2)
  )
}

export function hasL3Permissions(auth: IAuthState) {
  return (
    auth.authenticated &&
    (auth.currentUser.role.toLowerCase() === L0 ||
      auth.currentUser.role.toLowerCase() === L1 ||
      auth.currentUser.role.toLowerCase() === L2 ||
      auth.currentUser.role.toLowerCase() === L3)
  )
}

export function hasL4Permissions(auth: IAuthState) {
  return (
    auth.authenticated &&
    (auth.currentUser.role.toLowerCase() === L0 ||
      auth.currentUser.role.toLowerCase() === L1 ||
      auth.currentUser.role.toLowerCase() === L2 ||
      auth.currentUser.role.toLowerCase() === L3 ||
      auth.currentUser.role.toLowerCase() === L4)
  )
}

export function userHasDomain(auth: IAuthState, domainName: string) {
  return auth.authenticated && auth.currentUser.domain.includes(domainName)
}

export function getUserDomain(auth: IAuthState) {
  if (auth.authenticated) {
    return auth.currentUser.domain
  }
}

export interface IUser {
  id: number
  name: string
  email: string
  role: string
  domain: string[]
}

export const noUser: IUser = {
  email: '',
  id: -1,
  name: '',
  role: '',
  domain: [],
}

export const noAuth: IAuthState = {
  authenticated: false,
  currentUser: noUser,
}

export interface IUserLocation {
  name: string
  email: string
  latitude: number
  longitude: number
  accuracy: number
  timestamp: number
}

export function isUserEqual(u1: IUserLocation, u2: IUserLocation) {
  return u1.email === u2.email
}
