export default class LocalStorageService {
  public setSymbolsType(type: string) {
    localStorage.setItem('PREFERENCE_SYMBOL', type)
  }

  public getSymbolsType() {
    let symbol: string = 'normal'
    const symbolValue = localStorage.getItem('PREFERENCE_SYMBOL')
    if (symbolValue !== null) {
      symbol = symbolValue
    }
    return symbol
  }

  public setMeasurementUnit(unit: string) {
    localStorage.setItem('PREFERENCE_MEASUREMENT_UNIT', unit)
  }

  public getMeasurementUnit() {
    let unit: string = 'metric'
    const unitValue = localStorage.getItem('PREFERENCE_MEASUREMENT_UNIT')
    if (unitValue !== null) {
      unit = unitValue
    }
    return unit
  }

  public setCoordinatesFormat(format: string) {
    localStorage.setItem('PREFERENCE_COORDINATES_FORMAT', format)
  }

  public getCoordinatesFormat() {
    let format: string = 'DD'
    const formatValue = localStorage.getItem('PREFERENCE_COORDINATES_FORMAT')
    if (formatValue !== null) {
      format = formatValue
    }
    return format
  }

  public setCampaignSelected(campaignName: string) {
    localStorage.setItem('CAMPAIGN', campaignName)
  }

  public getCampaignSelected() {
    let campaignSelected: string = 'RIPPLES'
    const campaignValue = localStorage.getItem('CAMPAIGN')
    if (campaignValue !== null) {
      campaignSelected = campaignValue
    }
    return campaignSelected
  }

  public setMissionSelected(missionName: string) {
    localStorage.setItem('MISSION', missionName)
  }

  public getMissionSelected() {
    let missionSelected: string | undefined
    const missionValue = localStorage.getItem('MISSION')
    if (missionValue !== null) {
      missionSelected = missionValue
    }
    return missionSelected
  }
}
