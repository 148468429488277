import { IMapSystem } from '../model/IAsset'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export async function fetchMapSystem(assetName: string) {
  return request({
    url: `${apiURL}/mapSystem/${assetName}`,
  })
}

export async function createMapSystem(mapSystem: IMapSystem) {
  return request({
    method: 'POST',
    body: JSON.stringify(mapSystem),
    url: `${apiURL}/mapSystem`,
  })
}

export async function deleteMapSystem(mapSystem: IMapSystem) {
  return request({
    method: 'DELETE',
    body: JSON.stringify(mapSystem),
    url: `${apiURL}/mapSystem`,
  })
}
