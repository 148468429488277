import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleNavbar from '../../components/SimpleNavbar'
import IAuthState, { getUserDomain, hasL1Permissions, isL0, isL4, IUser } from '../../model/IAuthState'
import IRipplesState, { IAssetsGroup } from '../../model/IRipplesState'
import DateService from '../../services/DateUtils'
import DatePicker from 'react-datepicker'
import {
  setUser,
  setVehicles,
  setSpots,
  setCcus,
  setSidePanelTitle,
  setSidePanelContent,
  setSidePanelVisibility,
} from '../../redux/ripples.actions'
import { getCurrentUser } from '../../services/UserUtils'
import { fetchDomainNames } from '../../services/DomainUtils'
import IAsset, { IMapSystem } from '../../model/IAsset'
import IProfile from '../../model/IProfile'
import SoiService from '../../services/SoiUtils'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import mantaImage from '../../assets/manta_profile.jpg'
import noptilusImage from '../../assets/noptilus_profile.jpg'
import xploreImage from '../../assets/xplore_profile.jpg'
import seaconImage from '../../assets/seacon_profile.jpg'
import wavyBasicImage from '../../assets/wavyBasic_profile.jpg'
import wavyLittoralImage from '../../assets/wavyLittoral_profile.jpg'
import oracleLogo from '../../assets/oracle.jpeg'
import { createMapSystem, deleteMapSystem, fetchMapSystem } from '../../services/MapSystemUtils'
import LocalStorageService from '../../services/LocalStorageService'
import PositionService from '../../services/PositionUtils'
import { fetchCampaignNamesByAsset } from '../../services/CampaignUtils'
const { NotificationManager } = require('react-notifications')

interface StateType {
  isNavOpen: boolean
  domains: string[]
  types: string[]
  typeCheckedState: boolean[]
  assetSelected: IAsset | undefined
  isRemoveAssetModalOpen: boolean
  editAssetName: boolean
  assetNameNewInputValue: string
  isVerticalProfileModalOpen: boolean
  verticalProfileStartTimestamp: Date
  verticalProfileEndTimestamp: Date
  profiles: IProfile[]
  assetCampaigns: string[]
  assetMapSystem: IMapSystem[]
  isMapSystemModalOpen: boolean
  mapSystemToRemove: IMapSystem | undefined
  mapSystemInput: { externalSource: string; externalId: string }
  preferenceCoordsDisplayFormat: string
}

interface PropsType {
  setUser: (user: IUser) => any
  setVehicles: (_: IAsset[]) => void
  setSpots: (_: IAsset[]) => void
  setCcus: (_: IAsset[]) => void
  setSidePanelTitle: (_: string) => void
  setSidePanelVisibility: (_: boolean) => void
  setSidePanelContent: (_: any) => void
  auth: IAuthState
  content: Map<string, string>
  title: string
  vehicles: IAsset[]
  ccus: IAsset[]
  spots: IAsset[]
  assets: IAssetsGroup
  isDarkMode: boolean
}

/**
 * Display asset information
 */
export class AssetProfile extends Component<PropsType, StateType> {
  public notificationSystem: any = null
  public timerID: number = 0
  private soiService: SoiService = new SoiService()
  private localStorageService: LocalStorageService = new LocalStorageService()
  private positionService: PositionService = new PositionService()

  constructor(props: any) {
    super(props)
    this.state = {
      isNavOpen: false,
      domains: [],
      types: [
        'AUV',
        'ASV',
        'CCU',
        'GLIDER',
        'MOBILE_SENSOR',
        'ROV',
        'STATIC_SENSOR',
        'UAV',
        'WAVY_DRIFTER',
        'undefined',
      ],
      typeCheckedState: new Array(10).fill(false),
      assetSelected: undefined,
      isRemoveAssetModalOpen: false,
      editAssetName: false,
      assetNameNewInputValue: '',
      isVerticalProfileModalOpen: false,
      verticalProfileStartTimestamp: new Date(),
      verticalProfileEndTimestamp: new Date(),
      profiles: [],
      assetCampaigns: [],
      assetMapSystem: [],
      isMapSystemModalOpen: false,
      mapSystemToRemove: undefined,
      mapSystemInput: { externalSource: '', externalId: '' },
      preferenceCoordsDisplayFormat: 'DD',
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.updateAssets = this.updateAssets.bind(this)
    this.getAssetInfo = this.getAssetInfo.bind(this)
    this.getAssetCampaigns = this.getAssetCampaigns.bind(this)
    this.getAssetMapSystem = this.getAssetMapSystem.bind(this)
    this.handleAssetChangeDomain = this.handleAssetChangeDomain.bind(this)
    this.handleAssetChangeType = this.handleAssetChangeType.bind(this)
    this.updateAssetData = this.updateAssetData.bind(this)
    this.getAsset = this.getAsset.bind(this)
    this.toggleRemoveAssetModal = this.toggleRemoveAssetModal.bind(this)
    this.redirect_homepage = this.redirect_homepage.bind(this)
    this.toggleVerticalProfileModal = this.toggleVerticalProfileModal.bind(this)
    this.toogleMapSystemModal = this.toogleMapSystemModal.bind(this)
    this.deleteMapSystem = this.deleteMapSystem.bind(this)
    this.addAssetMapSystem = this.addAssetMapSystem.bind(this)
    this.fetchLocalStorage = this.fetchLocalStorage.bind(this)

    if (this.props.auth.authenticated && isL0(this.props.auth)) {
      this.getDomains()
    }
  }

  public componentWillMount() {
    this.fetchLocalStorage()
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  public buildAssetContent() {
    if (this.state.assetSelected !== undefined) {
      // parse type
      let assetType = 'undefined'
      if (this.state.assetSelected.type !== null) {
        assetType = this.state.assetSelected.type
      }

      // parse timestamp
      let date = this.state.assetSelected.lastState.timestamp
      if (date.toString().length > 10) {
        date = date / 1000
      }

      return (
        <ul>
          <li>
            <span className="asset-profile-field">imcID: </span>
            <span className="user-profile-value"> {this.state.assetSelected.imcid} </span>
          </li>
          <li>
            <span className="asset-profile-field">Heading: </span>
            <span className="user-profile-value"> {this.state.assetSelected.lastState.heading.toFixed(2)} </span>
          </li>
          <li>
            <span className="asset-profile-field">last update: </span>
            <span className="user-profile-value"> {DateService.timestampSecToReadableDate(date)} </span>
          </li>
          <li>
            <span className="asset-profile-field">latitude: </span>
            <span className="user-profile-value">
              {' '}
              {this.positionService.formatCoords(
                this.state.assetSelected.lastState.latitude,
                'lat',
                this.state.preferenceCoordsDisplayFormat
              )}{' '}
            </span>
          </li>
          <li>
            <span className="asset-profile-field">longitude: </span>
            <span className="user-profile-value">
              {' '}
              {this.positionService.formatCoords(
                this.state.assetSelected.lastState.longitude,
                'lon',
                this.state.preferenceCoordsDisplayFormat
              )}{' '}
            </span>
          </li>
          <li>
            <span className="asset-profile-field">plan: </span>
            <span className="user-profile-value"> {this.state.assetSelected.planId} </span>
          </li>
          <li>
            <span className="asset-profile-field">Domain: </span>
            <span className="user-profile-value">{this.parseDomains()}</span>
          </li>
          <li>
            <span className="asset-profile-field">type: </span>
            <span className="user-profile-value"> {assetType} </span>
          </li>
          <li>
            <span className="asset-profile-field">Campaigns: </span>
            <span className="user-profile-value">{this.parseCampaigns()}</span>
          </li>
        </ul>
      )
    } else {
      return <></>
    }
  }

  private async getDomains() {
    const domains: string[] = await fetchDomainNames()
    this.setState({ domains })
  }

  private fetchLocalStorage() {
    this.setState({ preferenceCoordsDisplayFormat: this.localStorageService.getCoordinatesFormat() })
  }

  private async updateAssets() {
    try {
      let userDomain = getUserDomain(this.props.auth)
      if (userDomain === undefined) {
        userDomain = ['undifined']
      }
      const soiPromise = this.soiService.fetchSoiData(userDomain)
      const soiData = await soiPromise
      /* Temporarily deactivated
      const vehicles = soiData.vehicles
      await this.soiService.mergeAssetSettings(vehicles, this.props.auth)
      */

      // update redux store
      this.props.setVehicles(soiData.vehicles)
      this.props.setSpots(soiData.spots)
      this.props.setCcus(soiData.ccus)
    } catch (error) {
      NotificationManager.warning('Failed to fetch data')
    }
  }

  private async getAssetInfo() {
    let asset: IAsset | undefined
    if (this.props.auth.authenticated && !isL4(this.props.auth) && this.props.title !== undefined) {
      if (this.props.title.includes('ccu')) {
        asset = this.props.ccus.find((item) => item.name === this.props.title)
      } else if (this.props.title.includes('spot')) {
        asset = this.props.spots.find((item) => item.name === this.props.title)
      } else {
        asset = this.props.vehicles.find((item) => item.name === this.props.title)
      }
    }

    let assetType: string = 'undefined'
    if (asset !== undefined) {
      if (asset.type !== null) {
        assetType = asset.type
      }
    }

    let updatedCheckedState: boolean[] = this.state.typeCheckedState
    this.state.types.forEach((type, i) => {
      if (type === assetType) {
        const updatedCheckedStateAux = this.state.typeCheckedState.map((item, index) => (index === i ? true : false))
        updatedCheckedState = updatedCheckedStateAux
      }
    })

    this.setState({ assetSelected: asset, typeCheckedState: updatedCheckedState })
  }

  public getImage(assetName: string) {
    if (assetName.startsWith('manta')) {
      return mantaImage
    } else if (assetName.startsWith('lauv-noptilus')) {
      return noptilusImage
    } else if (assetName.startsWith('lauv-xplore')) {
      return xploreImage
    } else if (assetName.startsWith('lauv-seacon')) {
      return seaconImage
    } else if (assetName.startsWith('WB')) {
      return wavyBasicImage
    } else if (assetName.startsWith('WL')) {
      return wavyLittoralImage
    } else if (assetName.startsWith('WO')) {
      return wavyLittoralImage
    } else {
      return 'https://rep17.lsts.pt/sites/rep17.lsts.pt/files/paragraph/IMG_7016.jpg'
    }
  }

  private async getAssetMapSystem() {
    if (this.props.auth.authenticated && !isL4(this.props.auth) && this.props.title !== undefined) {
      const mapSystem: IMapSystem[] = await fetchMapSystem(this.props.title)
      this.setState({ assetMapSystem: mapSystem })
    }
  }

  private async getAssetCampaigns() {
    if (this.props.auth.authenticated && !isL4(this.props.auth) && this.props.title !== undefined) {
      const assetCampaigns: string[] = await fetchCampaignNamesByAsset(this.props.title)
      this.setState({ assetCampaigns })
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()

    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      if (JSON.stringify(this.props.content).length === 2) {
        NotificationManager.warning('Please select an asset')
      }
      await this.updateAssets()
      await this.getAssetInfo()
      await this.getAssetCampaigns()
      await this.getAssetMapSystem()
    } else {
      NotificationManager.error('Permission required')
    }
  }

  public componentWillUnmount() {
    clearInterval(this.timerID)
  }

  public onNavToggle() {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  public toggleRemoveAssetModal() {
    this.setState((prevState) => ({
      isRemoveAssetModalOpen: !prevState.isRemoveAssetModalOpen,
    }))
  }

  public render() {
    return (
      <>
        <SimpleNavbar auth={this.props} />
        <div>
          <div className={this.props.isDarkMode ? 'asset-profile darkmode' : 'asset-profile'}>
            <div id="asset-profile-left">
              <div className="asset-profile-img-wrap">
                <img src={this.getImage(this.props.title)} alt="asset" />
              </div>
            </div>

            <div id="asset-profile-right">
              <p>
                {this.props.title !== undefined && this.props.title !== 'Click on something to get info' ? (
                  <>
                    {!this.state.editAssetName ? (
                      <>
                        <span className="asset-profile-field">{this.props.title}</span>
                        {isL0(this.props.auth) ? (
                          <i
                            className="fas fa-pencil-alt"
                            title="Edit asset name"
                            onClick={() =>
                              this.setState({ editAssetName: true, assetNameNewInputValue: this.props.title })
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <input
                          id={'assetName_' + this.props.title}
                          className="assetName-input"
                          type="text"
                          value={this.state.assetNameNewInputValue}
                          onChange={(event) => this.setState({ assetNameNewInputValue: event.target.value })}
                        />
                        {isL0(this.props.auth) ? (
                          <i
                            className="fas fa-check"
                            title="Update asset name"
                            onClick={() => this.updateAssetName()}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {isL0(this.props.auth) ? (
                      <>
                        <Button
                          className="asset-remove"
                          color="danger"
                          size="sm"
                          onClick={() => this.toggleRemoveAssetModal()}
                        >
                          Remove
                        </Button>
                        <Link id="homepage-link" to="/" />

                        {/*
                        <Button
                          className="asset-syncProfiles"
                          color="info"
                          size="sm"
                          onClick={() => this.toggleVerticalProfileModal()}
                        >
                          Vertical Profiles - OCI
                        </Button>
                        */}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <span className="asset-profile-field">{this.props.title}</span>
                )}
              </p>

              {this.state.assetSelected && this.buildAssetContent()}
            </div>

            {this.props.title !== undefined &&
              this.props.title !== 'Click on something to get info' &&
              hasL1Permissions(this.props.auth) && (
                <div className="asset-profile-bottom">
                  {this.buildDomainDialog()}
                  {this.buildTypeDialog()}
                  {this.buildMapSystem()}
                  {this.buildMapSystemModal()}
                  {/*this.buildVerticalProfileModal()*/}
                </div>
              )}
          </div>
        </div>

        <Modal
          id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
          isOpen={this.state.isRemoveAssetModalOpen}
          toggle={this.toggleRemoveAssetModal}
        >
          <ModalHeader toggle={this.toggleRemoveAssetModal}>Remove asset</ModalHeader>
          <ModalBody>The asset will be removed permanently. Do you want to continue?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.handleDeleteAsset()}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  private buildDomainDialog() {
    if (this.props.auth.authenticated && hasL1Permissions(this.props.auth) && this.props.title !== undefined) {
      const asset: IAsset | undefined = this.getAsset()

      const domain: string[] = []
      if (asset !== undefined) {
        asset.domain.forEach((d) => {
          domain.push(d)
        })
      }

      return (
        <div className="asset-domain">
          <label className="domain-label">Domain: </label>
          {this.state.domains.map((d, index) => {
            return (
              <label className={'assetOptDomainLabel'} key={index}>
                <input
                  type="checkbox"
                  className={'assetOptDomain'}
                  value={d}
                  checked={domain.includes(d) ? true : false}
                  onChange={this.handleAssetChangeDomain}
                  asset-id={this.props.title}
                />
                {d}
              </label>
            )
          })}
        </div>
      )
    }
  }

  private buildTypeDialog() {
    if (this.props.auth.authenticated && hasL1Permissions(this.props.auth)) {
      return (
        <div className="asset-type">
          <label className="type-label">Type: </label>
          {this.state.types.map((t, index) => {
            return (
              <label className={'assetOptTypeLabel'} key={index}>
                <input
                  type="checkbox"
                  className={'assetOptType'}
                  value={t}
                  checked={this.state.typeCheckedState[index]}
                  onChange={(e) => this.handleAssetChangeType(e, index)}
                  asset-id={this.props.title}
                />
                {t}
              </label>
            )
          })}
        </div>
      )
    }
  }

  private buildMapSystem() {
    if (this.props.auth.authenticated && hasL1Permissions(this.props.auth)) {
      return (
        <div className="asset-mapSystems">
          <label className="mapSystems-label">Map systems: </label>

          <Button
            className="asset_externalSource"
            color="info"
            size="sm"
            onClick={() => this.toogleMapSystemModal('', '', undefined)}
          >
            Add external source
          </Button>

          {this.state.assetMapSystem.length > 0 ? (
            <Table id="mapSystem-table" hover={true} responsive={true}>
              <thead>
                <tr>
                  <th>External source</th>
                  <th>External ID</th>
                  <th>Last update</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.state.assetMapSystem.map((mapSystem, index) => {
                  return (
                    <tr key={index}>
                      <th>{mapSystem.externalSource}</th>
                      <th>{mapSystem.externalId}</th>
                      <th>{DateService.timestampMsToReadableDate(mapSystem.timestamp)}</th>
                      <th>
                        <i
                          className="action fas fa-trash-alt"
                          title="Remove external source"
                          onClick={(e) => this.toogleMapSystemModal('', '', mapSystem)}
                        />
                      </th>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          ) : (
            <></>
          )}
        </div>
      )
    }
  }

  private buildMapSystemModal() {
    if (this.state.mapSystemToRemove) {
      return (
        <Modal isOpen={this.state.isMapSystemModalOpen} toggle={() => this.toogleMapSystemModal('', '', undefined)}>
          <ModalHeader toggle={() => this.toogleMapSystemModal('', '', undefined)}>Remove external source</ModalHeader>

          <ModalBody>
            <p>
              The{' '}
              <b>
                {this.state.mapSystemToRemove.externalSource}: {this.state.mapSystemToRemove.externalId}
              </b>{' '}
              external source will be removed.
            </p>
          </ModalBody>

          <ModalFooter>
            <Button color="danger" onClick={() => this.deleteMapSystem(this.state.mapSystemToRemove)}>
              Remove
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else {
      return (
        <Modal isOpen={this.state.isMapSystemModalOpen} toggle={() => this.toogleMapSystemModal('', '', undefined)}>
          <ModalHeader toggle={() => this.toogleMapSystemModal('', '', undefined)}>External source</ModalHeader>

          <ModalBody>
            <p>The asset will be update with the external source information.</p>

            <select
              className="mapSystem-input"
              value={this.state.mapSystemInput.externalSource}
              onChange={(event: any) => {
                this.setState({
                  mapSystemInput: {
                    externalSource: event.target.value,
                    externalId: this.state.mapSystemInput.externalId,
                  },
                })
              }}
            >
              <option disabled={true} hidden={true} value="">
                External source
              </option>
              <option value="ADS_B" onClick={(event) => event.stopPropagation()}>
                ADS_B
              </option>
              <option value="AISHub" onClick={(event) => event.stopPropagation()}>
                AISHub
              </option>
              <option value="SPOT" onClick={(event) => event.stopPropagation()}>
                SPOT
              </option>
            </select>
            <input
              className="mapSystem-input"
              placeholder="External source ID"
              value={this.state.mapSystemInput.externalId}
              onChange={(event: any) =>
                this.setState({
                  mapSystemInput: {
                    externalSource: this.state.mapSystemInput.externalSource,
                    externalId: event.target.value,
                  },
                })
              }
            />
          </ModalBody>

          {this.state.mapSystemInput.externalSource.length > 0 && this.state.mapSystemInput.externalId.length > 0 && (
            <ModalFooter>
              <Button color="primary" onClick={() => this.addAssetMapSystem()}>
                Add source
              </Button>
            </ModalFooter>
          )}
        </Modal>
      )
    }
  }

  private toogleMapSystemModal(externalSource: string, externalId: string, removeMapSystem: IMapSystem | undefined) {
    this.setState({
      isMapSystemModalOpen: !this.state.isMapSystemModalOpen,
      mapSystemToRemove: removeMapSystem,
      mapSystemInput: { externalSource, externalId },
    })
  }

  private async addAssetMapSystem() {
    try {
      const newMapSystem: IMapSystem = {
        assetName: this.props.title,
        externalSource: this.state.mapSystemInput.externalSource,
        externalId: this.state.mapSystemInput.externalId,
        timestamp: new Date().getTime(),
      }
      const response = await createMapSystem(newMapSystem)
      if (response.status === 'Success') {
        NotificationManager.success(response.message)
        this.toogleMapSystemModal('', '', undefined)
        await this.getAssetMapSystem()
      } else {
        NotificationManager.warning(response.message)
      }
    } catch (error) {
      NotificationManager.error('Failed to update asset map system')
    }
  }

  private async deleteMapSystem(mapSystem: IMapSystem | undefined) {
    if (mapSystem !== undefined) {
      try {
        const response = await deleteMapSystem(mapSystem)
        if (response.status === 'Success') {
          NotificationManager.success(response.message)
          this.toogleMapSystemModal('', '', undefined)
          await this.getAssetMapSystem()
        } else {
          NotificationManager.warning(response.message)
        }
      } catch (error) {
        NotificationManager.error('Failed to remove asset map system')
      }
    } else {
      NotificationManager.error('Cannot remove external source')
    }
  }

  private buildVerticalProfileModal() {
    return (
      <Modal isOpen={this.state.isVerticalProfileModalOpen} toggle={this.toggleVerticalProfileModal}>
        <ModalHeader toggle={this.toggleVerticalProfileModal}>
          <img id="oracleLogo" src={oracleLogo} alt="oracle" />
          Vertical Profiles - OCI
        </ModalHeader>
        <ModalBody>
          <div className="verticalProfileModal">
            <label>Start date</label>
            <div className="verticalProfileTimestampWrapper">
              <DatePicker
                className="verticalProfile-input"
                id={'verticalProfileStartTimestamp-input'}
                selected={this.state.verticalProfileStartTimestamp}
                onChange={(newDate: Date) => this.setState({ verticalProfileStartTimestamp: newDate })}
                showTimeSelect={true}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                maxDate={new Date()}
                excludeScrollbar={true}
                timeIntervals={15}
                disabled={false}
              />
            </div>

            <label>End date</label>
            <div className="verticalProfileTimestampWrapper">
              <DatePicker
                className="verticalProfile-input"
                id={'verticalProfileEndTimestamp-input'}
                selected={this.state.verticalProfileEndTimestamp}
                onChange={(newDate: Date) => this.setState({ verticalProfileEndTimestamp: newDate })}
                showTimeSelect={true}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                maxDate={new Date()}
                excludeScrollbar={true}
                timeIntervals={15}
                disabled={false}
              />
            </div>
            <Button className="btn_fetch" color="info" size="sm" onClick={() => this.fetchVerticalProfiles()}>
              Fetch vertical profiles
            </Button>
          </div>
        </ModalBody>
        {this.state.profiles.length > 0 ? (
          <ModalFooter>
            <Button className="btn-sync" color="warning" size="sm" onClick={() => this.syncVerticalProfiles()}>
              Sync <b>{this.state.profiles.length}</b> profiles with Oracle
            </Button>
          </ModalFooter>
        ) : (
          <></>
        )}
      </Modal>
    )
  }

  private toggleVerticalProfileModal() {
    this.setState({
      isVerticalProfileModalOpen: !this.state.isVerticalProfileModalOpen,
    })
  }

  private async handleAssetChangeDomain(event: any) {
    const assetID = event.target.getAttribute('asset-id')

    let system: IAsset
    if (assetID.startsWith('spot')) {
      system = this.props.spots.filter((item) => item.name === assetID)[0]
    } else if (assetID.startsWith('ccu')) {
      system = this.props.ccus.filter((item) => item.name === assetID)[0]
    } else {
      system = this.props.vehicles.filter((item) => item.name === assetID)[0]
    }

    const domains: any = document.getElementsByClassName('assetOptDomain')
    const domainSelected: string[] = []
    for (const domain of domains) {
      if (domain.checked) domainSelected.push(domain.value)
    }

    this.updateAssetData(system, domainSelected)
  }

  private async handleAssetChangeType(event: any, changedIndex: any) {
    const updatedCheckedState = this.state.typeCheckedState.map((item, index) =>
      index === changedIndex ? !item : false
    )
    const assetID = event.target.getAttribute('asset-id')
    const asset: IAsset | undefined = this.getAssetWithId(assetID)
    this.setState({ typeCheckedState: updatedCheckedState }, () => this.updateAssetTypeData(asset))
  }

  private async updateAssetData(system: IAsset, domain: string[]) {
    try {
      const newSystem: IAsset = await this.soiService.updateAssetDomain(system, domain)
      const response = await this.soiService.updateAssetDB(newSystem)

      if (response.status === 'Success') {
        NotificationManager.success(response.message)
        await this.updateAssets()
        await this.getAssetInfo()
        this.props.setSidePanelVisibility(false)
      } else {
        NotificationManager.warning('Failed to update asset domain')
      }
    } catch (error) {
      NotificationManager.warning('Failed to update asset domain')
    }
  }

  private async updateAssetTypeData(system: IAsset) {
    const typeSelected: string = this.state.types[this.state.typeCheckedState.indexOf(true)]
    try {
      const newSystem: IAsset = await this.soiService.updateAssetType(system, typeSelected)
      const response = await this.soiService.updateAssetTypeDB(newSystem, typeSelected)

      if (response.status === 'Success') {
        NotificationManager.success(response.message)
        await this.updateAssets()
        await this.getAssetInfo()
        this.props.setSidePanelVisibility(false)
      } else {
        NotificationManager.warning('Failed to update asset domain')
      }
    } catch (error) {
      NotificationManager.warning('Failed to update asset domain')
    }
  }

  private async updateAssetName() {
    const system: IAsset | undefined = this.getAssetWithId(this.props.title)
    let isNameValid: boolean = true
    if (system !== null && system !== undefined) {
      if (this.props.title !== this.state.assetNameNewInputValue) {
        // check if new name is valid
        this.props.vehicles.forEach((vehicle) => {
          if (vehicle.name === this.state.assetNameNewInputValue) isNameValid = false
        })

        if (isNameValid) {
          try {
            const newSystem: IAsset = await this.soiService.updateAssetName(system, this.state.assetNameNewInputValue)
            const response = await this.soiService.updateAssetNameDB(newSystem, this.props.title)

            if (response.status === 'Success') {
              NotificationManager.success(response.message)
              this.setState({ assetSelected: newSystem })
              this.props.setSidePanelTitle(newSystem.name)
              this.redirect_homepage()
            } else {
              NotificationManager.warning('Failed to update asset name')
            }
          } catch (error) {
            NotificationManager.warning('Failed to update asset name')
          }
        } else {
          NotificationManager.warning('Asset name already exist')
        }
      } else {
        NotificationManager.warning('New name should be different from the previous')
      }

      this.setState({ editAssetName: false })
    }
  }

  private getAsset() {
    let asset: IAsset | undefined
    if (this.props.title.includes('ccu')) {
      asset = this.props.ccus.find((item) => item.name === this.props.title)
    } else if (this.props.title.includes('spot')) {
      asset = this.props.spots.find((item) => item.name === this.props.title)
    } else {
      asset = this.props.vehicles.find((item) => item.name === this.props.title)
    }

    return asset
  }

  private getAssetWithId(assetID: any) {
    let system: IAsset | undefined
    if (assetID.startsWith('spot')) {
      system = this.props.spots.filter((item) => item.name === assetID)[0]
    } else if (assetID.startsWith('ccu')) {
      system = this.props.ccus.filter((item) => item.name === assetID)[0]
    } else {
      system = this.props.vehicles.filter((item) => item.name === assetID)[0]
    }
    return system
  }

  private async handleDeleteAsset() {
    if (this.state.assetSelected !== undefined) {
      try {
        const response = await this.soiService.deleteAsset(this.state.assetSelected.name)
        if (response.status === 'Success') {
          const assetsUpdated: IAsset[] = []
          if (this.state.assetSelected.name.startsWith('spot')) {
            this.props.spots.forEach((spot) => {
              if (this.state.assetSelected !== undefined && spot.name !== this.state.assetSelected.name) {
                assetsUpdated.push(spot)
              }
            })
            this.props.setSpots(assetsUpdated)
          } else if (this.state.assetSelected.name.startsWith('ccu')) {
            this.props.ccus.forEach((ccu) => {
              if (this.state.assetSelected !== undefined && ccu.name !== this.state.assetSelected.name) {
                assetsUpdated.push(ccu)
              }
            })
            this.props.setCcus(assetsUpdated)
          } else {
            this.props.vehicles.forEach((vehicle) => {
              if (this.state.assetSelected !== undefined && vehicle.name !== this.state.assetSelected.name) {
                assetsUpdated.push(vehicle)
              }
            })
            this.props.setVehicles(assetsUpdated)
          }

          NotificationManager.success(response.message)
        } else {
          NotificationManager.error(response.message)
        }
      } catch (error) {
        NotificationManager.error('Cannot delete asset')
      }
    }
    this.setState({ assetSelected: undefined })
    this.props.setSidePanelVisibility(false)
    this.props.setSidePanelContent({})
    this.toggleRemoveAssetModal()
    this.redirect_homepage()
  }

  private async fetchVerticalProfiles() {
    if (this.props.title !== undefined) {
      try {
        const verticalProfiles = this.soiService.fetchProfileDataBetweenTimestamps(
          this.props.title,
          this.state.verticalProfileStartTimestamp,
          this.state.verticalProfileEndTimestamp
        )
        const profilesFetched = await verticalProfiles
        this.setState({ profiles: profilesFetched })
        NotificationManager.info('Fetching profiles...')
      } catch (error) {
        NotificationManager.warning('Cannot fetch profiles...')
      }
    }
  }

  private async syncVerticalProfiles() {
    if (this.state.profiles.length > 0 && this.props.title !== undefined) {
      try {
        const response = await this.soiService.syncVerticalProfilesWithOCI(this.state.profiles, this.props.title)
        if (response.status === 'success') {
          this.setState({
            isVerticalProfileModalOpen: !this.state.isVerticalProfileModalOpen,
            verticalProfileStartTimestamp: new Date(),
            verticalProfileEndTimestamp: new Date(),
            profiles: [],
          })
          NotificationManager.success(response.message)
        } else {
          NotificationManager.warning(response.message)
        }
      } catch (error) {
        console.log('Cannot sync vertical profiles with OCI')
      }
    } else {
      NotificationManager.warning('No vertical profiles available')
    }
  }

  private redirect_homepage() {
    const userLink = document.getElementById('homepage-link')
    if (userLink !== null) {
      userLink.click()
    }
  }

  private parseDomains() {
    if (this.state.assetSelected !== undefined) {
      const lastPosition = this.state.assetSelected.domain.length - 1
      let domainParsed = ''
      this.state.assetSelected.domain.forEach((item, index) => {
        if (index !== lastPosition) {
          domainParsed = domainParsed.concat(item, ', ')
        } else {
          domainParsed = domainParsed.concat(item)
        }
      })
      return domainParsed
    } else {
      return <></>
    }
  }

  private parseCampaigns() {
    if (this.state.assetSelected !== undefined) {
      const lastPosition = this.state.assetCampaigns.length - 1
      let campaignParsed = ''

      this.state.assetCampaigns.forEach((item, index) => {
        if (index !== lastPosition) {
          campaignParsed = campaignParsed.concat(item, ', ')
        } else {
          campaignParsed = campaignParsed.concat(item)
        }
      })
      return campaignParsed
    } else {
      return <></>
    }
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    content: state.sidePanelContent,
    title: state.sidePanelTitle,
    vehicles: state.assets.vehicles,
    ccus: state.assets.ccus,
    spots: state.assets.spots,
    isDarkMode: state.isDarkMode,
  }
}

const actionCreators = {
  setUser,
  setVehicles,
  setSpots,
  setCcus,
  setSidePanelTitle,
  setSidePanelVisibility,
  setSidePanelContent,
}

export default connect(mapStateToProps, actionCreators)(AssetProfile)
