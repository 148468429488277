import L from 'leaflet'
import lauv_orange from '../../../assets/LAUV_orange.png'
import lauv_yellow from '../../../assets/LAUV_yellow.png'
import lauv_red from '../../../assets/LAUV_red.png'
import lauv_black from '../../../assets/LAUV_black.png'
import av_generic from '../../../assets/AV_generic.png'
import manta from '../../../assets/manta.png'
import ais_red from '../../../assets/AIS_red.png'
import ais_purple from '../../../assets/AIS_purple.png'
import ais_purple_selected from '../../../assets/AIS_purple_selected.png'
import ais_orange from '../../../assets/AIS_orange.png'
import ais_grey from '../../../assets/AIS_grey.png'
import wavy_basic from '../../../assets/wavy_basic.png'
import wavy_littoral from '../../../assets/wavy_littoral.png'
import wavy_ocean from '../../../assets/wavy_ocean.png'
import wavy_dummy from '../../../assets/wavy_dummy.png'
import wavy_nos from '../../../assets/wavy_nos.png'
import circle_blue from '../../../assets/circle_blue.png'
import circle_green from '../../../assets/circle_green.png'
import circle_red from '../../../assets/circle_red.png'
import circle_orange from '../../../assets/circle_orange.png'
import circle_yellow from '../../../assets/circle_yellow.png'
import circle_gray from '../../../assets/circle_gray.png'
import desktop from '../../../assets/desktop.png'
import mobile from '../../../assets/mobile.png'
import wp from '../../../assets/wp.png'
import wp_start from '../../../assets/wp_start.png'
import wp_finish from '../../../assets/wp_finish.png'
import pin_empty from '../../../assets/pin_empty.png'
import temperature from '../../../assets/temperature.png'
import droplet_dirty from '../../../assets/droplet_dirty.png'
import droplet_clean from '../../../assets/droplet_clean.png'
import blue_arrow from '../../../assets/arrow_blue.png'
import question_marker from '../../../assets/questionMarker.png'
import uav from '../../../assets/uav.png'
import my_location from '../../../assets/myloc.png'
import aircraft from '../../../assets/aircraft.png'
import aircraft_selected from '../../../assets/aircraftSelected.png'
import glider from '../../../assets/glider.png'
import staticSensor from '../../../assets/staticSensor.png'
import caravel from '../../../assets/caravel.png'
import rov from '../../../assets/rov.png'
import buoyMultiparam from '../../../assets/buoy_multiparam.png'
import buoydatawell from '../../../assets/buoy_datawell.png'
import ksat_ais from '../../../assets/ksat/VESSEL_ICON_AIS.png'
import ksat_correlated from '../../../assets/ksat/VESSEL_ICON_CORRELATED.png'
import ksat_high from '../../../assets/ksat/VESSEL_ICON_HIGH.png'
import ksat_low from '../../../assets/ksat/VESSEL_ICON_LOW.png'
import ksat_medium from '../../../assets/ksat/VESSEL_ICON_MEDIUM.png'
import ksat_legend from '../../../assets/ksat/CORRELATION_LEGEND.png'

export const BlueCircleIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: circle_blue,
  },
})

export const BlueCircleSmallIcon = L.Icon.extend({
  options: {
    iconSize: [6, 6],
    iconUrl: circle_blue,
  },
})

export const PCIcon = L.Icon.extend({
  options: {
    iconSize: [16, 16],
    iconUrl: desktop,
  },
})

export const MobileIcon = L.Icon.extend({
  options: {
    iconSize: [16, 16],
    iconUrl: mobile,
  },
})

export const GhostIcon = L.Icon.extend({
  options: {
    iconSize: [24, 24],
    iconUrl: 'https://png.pngtree.com/svg/20170817/ghost_1327317.png',
  },
})

export const AuvIcon = L.Icon.extend({
  options: {
    iconSize: [32, 32],
    iconUrl: 'https://image.flaticon.com/icons/png/128/190/190006.png',
  },
})

export const AuvOrangeIcon = L.Icon.extend({
  options: {
    iconSize: [32, 32],
    iconUrl: lauv_orange,
  },
})

export const AuvOrangeSmallIcon = L.Icon.extend({
  options: {
    iconSize: [22, 22],
    iconUrl: lauv_orange,
  },
})

export const AuvYellowIcon = L.Icon.extend({
  options: {
    iconSize: [32, 32],
    iconUrl: lauv_yellow,
  },
})

export const AuvYellowSmallIcon = L.Icon.extend({
  options: {
    iconSize: [22, 22],
    iconUrl: lauv_yellow,
  },
})

export const AuvRedIcon = L.Icon.extend({
  options: {
    iconSize: [32, 32],
    iconUrl: lauv_red,
  },
})

export const AuvRedSmallIcon = L.Icon.extend({
  options: {
    iconSize: [22, 22],
    iconUrl: lauv_red,
  },
})

export const AuvBlackIcon = L.Icon.extend({
  options: {
    iconSize: [32, 32],
    iconUrl: lauv_black,
  },
})

export const AuvBlackSmallIcon = L.Icon.extend({
  options: {
    iconSize: [22, 22],
    iconUrl: lauv_black,
  },
})

export const AvGenericIcon = L.Icon.extend({
  options: {
    iconSize: [22, 22],
    iconUrl: av_generic,
  },
})

export const AvGenericSmallIcon = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: av_generic,
  },
})

export const mantaIcon = L.Icon.extend({
  options: {
    iconSize: [20, 20],
    iconUrl: manta,
  },
})

export const GreenTriangleIcon = L.Icon.extend({
  options: {
    iconSize: [16, 16],
    iconUrl: 'https://i.imgur.com/HCNwjyG.png',
  },
})

export const YellowTriangleIcon = L.Icon.extend({
  options: {
    iconSize: [16, 16],
    iconUrl: 'https://i.imgur.com/F2VhR2Q.png',
  },
})

export const RedTriangleIcon = L.Icon.extend({
  options: {
    iconSize: [16, 16],
    iconUrl: 'https://i.imgur.com/hqIGg6n.png',
  },
})

export const StartWaypointIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: wp_start,
  },
})

export const WaypointIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: wp,
  },
})

export const RedCircleIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: circle_red,
  },
})

export const RedCircleSmallIcon = L.Icon.extend({
  options: {
    iconSize: [6, 6],
    iconUrl: circle_red,
  },
})

export const GreenCircleIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: circle_green,
  },
})

export const GreenCircleSmallIcon = L.Icon.extend({
  options: {
    iconSize: [6, 6],
    iconUrl: circle_green,
  },
})

export const YellowCircleIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: circle_yellow,
  },
})

export const GrayCircleIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: circle_gray,
  },
})

export const OrangeCircleIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: circle_orange,
  },
})

export const FinishWaypointIcon = L.Icon.extend({
  options: {
    iconSize: [10, 10],
    iconUrl: wp_finish,
  },
})

export const SpotIcon = L.Icon.extend({
  options: {
    iconSize: [25, 25],
    iconUrl: pin_empty,
  },
})

export const CCUIcon = L.Icon.extend({
  options: {
    iconSize: [25, 25],
    iconUrl: pin_empty,
  },
})

export const SensorIcon = L.Icon.extend({
  options: {
    iconSize: [16, 16],
    iconUrl: temperature,
  },
})

export const AwarenessIcon = L.Icon.extend({
  options: {
    iconSize: [20, 20],
    iconUrl: 'https://image.flaticon.com/icons/svg/25/25678.svg',
  },
})

export const AISGreyIcon = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ais_grey,
  },
})

export const AISGreySmallIcon = L.Icon.extend({
  options: {
    iconSize: [12, 12],
    iconUrl: ais_grey,
  },
})

export const AISRedIcon = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ais_red,
  },
})

export const AISRedSmallIcon = L.Icon.extend({
  options: {
    iconSize: [12, 12],
    iconUrl: ais_red,
  },
})

export const AISPurpleIcon = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ais_purple,
  },
})

export const AISPurpleIconSelected = L.Icon.extend({
  options: {
    iconSize: [20, 20],
    iconUrl: ais_purple_selected,
  },
})

export const AISPurpleSmallIcon = L.Icon.extend({
  options: {
    iconSize: [12, 12],
    iconUrl: ais_purple,
  },
})

export const AISPurpleTinyIcon = L.Icon.extend({
  options: {
    iconSize: [6, 6],
    iconUrl: ais_purple,
  },
})

export const AISOrangeIcon = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ais_orange,
  },
})

export const AISOrangeSmallIcon = L.Icon.extend({
  options: {
    iconSize: [12, 12],
    iconUrl: ais_orange,
  },
})

export const GeoJsonMarker = L.Icon.extend({
  options: {
    iconSize: [5, 5],
    iconUrl: circle_yellow,
  },
})

export const WavyBasicIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: wavy_basic,
  },
})

export const WavyLittoralIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: wavy_littoral,
  },
})

export const WavyOceanIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: wavy_ocean,
  },
})

export const WavyDummyIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: wavy_dummy,
  },
})

export const WavyNosIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: wavy_nos,
  },
})

export const DropletDirtyIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: droplet_dirty,
  },
})

export const DropletCleanIcon = L.Icon.extend({
  options: {
    iconSize: [11, 11],
    iconUrl: droplet_clean,
  },
})

export const BlueArrowIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: blue_arrow,
  },
})

export const QuestionMarkerIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: question_marker,
  },
})

export const UavIcon = L.Icon.extend({
  options: {
    iconSize: [25, 25],
    iconUrl: uav,
  },
})

export const MyLocationIcon = L.Icon.extend({
  options: {
    iconSize: [25, 25],
    iconUrl: my_location,
  },
})

export const AircraftIcon = L.Icon.extend({
  options: {
    iconSize: [20, 20],
    iconUrl: aircraft,
  },
})

export const AircraftSelectedIcon = L.Icon.extend({
  options: {
    iconSize: [20, 20],
    iconUrl: aircraft_selected,
  },
})

export const GliderIcon = L.Icon.extend({
  options: {
    iconSize: [32, 32],
    iconUrl: glider,
  },
})

export const StaticSensor = L.Icon.extend({
  options: {
    iconSize: [25, 25],
    iconUrl: staticSensor,
  },
})

export const CaravelIcon = L.Icon.extend({
  options: {
    iconSize: [25, 25],
    iconUrl: caravel,
  },
})

export const RovIcon = L.Icon.extend({
  options: {
    iconSize: [15, 15],
    iconUrl: rov,
  },
})

export const buoyMultiparamIcon = L.Icon.extend({
  options: {
    iconSize: [20, 25],
    iconUrl: buoyMultiparam,
  },
})

export const buoyDatawellIcon = L.Icon.extend({
  options: {
    iconSize: [15, 20],
    iconUrl: buoydatawell,
  },
})

// ---  KSAT icons
export const KsatVesselAIS = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ksat_ais,
  },
})

export const KsatVesselCorrelated = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ksat_correlated,
  },
})

export const KsatVesselHigh = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ksat_high,
  },
})

export const KsatVesselLow = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ksat_low,
  },
})

export const KsatVesselMedium = L.Icon.extend({
  options: {
    iconSize: [17, 17],
    iconUrl: ksat_medium,
  },
})

export const ksatVesselLegend = L.Icon.extend({
  options: {
    iconUrl: ksat_legend,
  },
})
