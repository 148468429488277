import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleNavbar from './../../../components/SimpleNavbar'
import IAuthState, { IUser } from '../../../model/IAuthState'
import IRipplesState from '../../../model/IRipplesState'
import { setUser } from '../../../redux/ripples.actions'
import { getCurrentUser } from '../../../services/UserUtils'

interface StateType {
  isNavOpen: boolean
}

interface PropsType {
  setUser: (user: IUser) => any
  auth: IAuthState
  isDarkMode: boolean
}

export class About extends Component<PropsType, StateType> {
  public notificationSystem: any = null

  constructor(props: any) {
    super(props)
    this.state = {
      isNavOpen: false,
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
  }

  public onNavToggle() {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  public render() {
    return (
      <>
        <SimpleNavbar auth={this.props} />
        <div className="about-background">
          <div className="about-card">
            <div className="about-subcard">
              <h1 className="subcard-title">Ripples</h1>
              <div className="subcard-text">
                <p>
                  Ripples is a decentralised web-based service for <b>secure</b>, <b>persistent</b>, and{' '}
                  <b>collaborative</b> maritime data processing & analysis, aimed for <b>high-level decision making</b>{' '}
                  and <b>situational awareness</b>.
                </p>
                <p>
                  It was built upon the LSTS Toolchain, providing a web-based C2, satellite communication interface,
                  multi-vehicle planning, and a web frontend for those vehicles.
                </p>
              </div>
            </div>

            <div className="about-subcard">
              <h2 className="subcard-title">LSTS</h2>
              <div className="subcard-text">
                <p>
                  The{' '}
                  <a href="https://lsts.fe.up.pt/" className="lsts-ref">
                    Laboratório de Sistemas e Tecnologia Subaquática
                  </a>{' '}
                  (LSTS) is an interdisciplinary research laboratory established in 1997 with researchers drawn from
                  Electrical and Computer Engineering, Mechanical Engineering and from Computer Science.
                </p>
                <p>
                  The LSTS is specialized in the design, construction, and operation of unmanned underwater, surface and
                  air vehicles and on the development of tools and technologies for the deployment of networked vehicle
                  systems.
                </p>
              </div>
            </div>
          </div>

          <footer className="about-footer">
            <a href="https://lsts.fe.up.pt/">
              <i id="footer-icon" className="fas fa-globe" />
            </a>
            <a href="https://twitter.com/lstsfeup">
              <i id="footer-icon" className="fab fa-twitter" />
            </a>
            <a href="https://www.facebook.com/lsts.feup">
              <i id="footer-icon" className="fab fa-facebook" />
            </a>
            <a href="https://www.linkedin.com/company/lsts-feup">
              <i id="footer-icon" className="fab fa-linkedin" />
            </a>
            <a href="https://www.youtube.com/lstsvideos">
              <i id="footer-icon" className="fab fa-youtube" />
            </a>
            <a href="https://github.com/LSTS">
              <i id="footer-icon" className="fab fa-github" />
            </a>
            <a href="https://www.instagram.com/lsts.feup/">
              <i id="footer-icon" className="fab fa-instagram" />
            </a>
          </footer>
        </div>
      </>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    isDarkMode: state.isDarkMode,
  }
}

const actionCreators = {
  setUser,
}

export default connect(mapStateToProps, actionCreators)(About)
