export default interface IChatMessage {
  id: string
  timestamp: Date
  text: string
  mission: string
  user: string
  destination: string
}

export const EmptyChatMessage: IChatMessage = {
  id: '',
  timestamp: new Date(),
  text: '',
  mission: '',
  user: '',
  destination: '',
}

export default class IChatMessage implements IChatMessage {
  constructor(
    public id: string,
    public timestamp: Date,
    public text: string,
    public mission: string,
    public user: string,
    public destination: string
  ) {}
}
