import { request } from './RequestUtils'
const toGeojson = require('@mapbox/togeojson')

const apiURL = process.env.REACT_APP_API_BASE_URL

export default class KMLService {
  public async fetchMapsNamesAndURLS() {
    return request({
      url: `${apiURL}/kml`,
    })
  }

  public async fetchMapsNames() {
    return request({
      url: `${apiURL}/kml/names`,
    })
  }

  public async fetchMapData(mapName: string) {
    const res = await fetch(`${apiURL}/kml/${mapName}`)
    const response = await res.json()
    const xml = response.message
    const dom = new DOMParser().parseFromString(xml, 'text/xml')

    if (mapName.includes('KSAT')) {
      const sourcePoints = toGeojson.kml(dom, { styles: true })
      const updatedPoints = this.parseKSAT(sourcePoints, dom)
      return updatedPoints
    }

    return toGeojson.kml(dom, { styles: true })
  }

  public async addNewMap(mapName: string, mapURL: string) {
    return request({
      body: JSON.stringify({ name: mapName, url: mapURL }),
      method: 'POST',
      url: `${apiURL}/kml`,
    })
  }

  public async deleteMap(mapName: string) {
    return request({
      method: 'DELETE',
      url: `${apiURL}/kml/${mapName}`,
    })
  }

  public async updateMapDomain(mapName: string, domain: string[]) {
    return request({
      method: 'POST',
      body: JSON.stringify(domain),
      url: `${apiURL}/kml/${mapName}`,
    })
  }

  public async fetchMapDomain(mapName: string) {
    return request({
      url: `${apiURL}/kml/domain/${mapName}`,
    })
  }

  public async fetchMapsNamesAndURLSByDomain(userDomain: string[]) {
    return request({
      url: `${apiURL}/kml/domain/maps/${userDomain}`,
    })
  }

  public async fetchMapsNamesByDomain(userDomain: string[]) {
    return request({
      url: `${apiURL}/kml/domain/names/${userDomain}`,
    })
  }

  private parseKSAT(sourcePoints: any, dom: Document) {
    const document = dom.querySelector('Document')
    if (document !== null) {
      document.childNodes.forEach((node) => {
        if (node.nodeName.includes('Folder')) {
          if (
            node.childNodes[0].nextSibling &&
            node.childNodes[0].nextSibling.textContent &&
            node.childNodes[0].nextSibling.textContent.includes('Tracks')
          ) {
            node.childNodes.forEach((child) => {
              const coordinates: number[][] = []
              let trackName: string = ''
              if (child.nodeName.includes('Placemark')) {
                // Parse Placemark: correlated
                if (
                  child.childNodes[7] !== undefined &&
                  child.childNodes[7].nodeName.includes('MultiGeometry') &&
                  child.childNodes[7].childNodes[3].nodeName.includes('LineString')
                ) {
                  // console.log("Placemark - CORRELATED")
                  const lineString = child.childNodes[7].childNodes[3]
                  // console.log(lineString)

                  if (lineString.childNodes[3].nodeName.includes('coordinates')) {
                    // Parse coordinates
                    // console.log("PARSE COORDINATES")
                    if (
                      lineString.childNodes[3].firstChild &&
                      lineString.childNodes[3].firstChild.textContent !== null
                    ) {
                      const coordinatesRaw = lineString.childNodes[3].firstChild.textContent
                      const result = coordinatesRaw.split(/\r?\n/)
                      result.forEach((coord) => {
                        const point = coord.trim().split(',')
                        if (point.length > 1 && child.childNodes[1].textContent !== null) {
                          trackName = child.childNodes[1].textContent
                          coordinates.push([Number(point[0]), Number(point[1]), Number(point[2])])
                        }
                      })
                    }
                  }
                }

                const newFeature = {
                  type: 'Feature',
                  properties: {
                    name: trackName,
                    styleUrl: '#line-000000-1200-nodesc',
                    styleHash: 'c2c637f',
                    styleMapHash: {
                      normal: '#line-000000-1200-nodesc-normal',
                      highlight: '#line-000000-1200-nodesc-highlight',
                    },
                    stroke: '#0bf210',
                    'stroke-opacity': 1,
                    'stroke-width': 1.2,
                  },
                  geometry: {
                    type: 'LineString',
                    coordinates,
                  },
                }

                if (coordinates.length > 0) {
                  sourcePoints.features.push(newFeature)
                }
              }

              // Parse Placemark: AIS
              if (
                child.childNodes[5] !== undefined &&
                child.childNodes[5].nodeName.includes('MultiGeometry') &&
                child.childNodes[5].childNodes[3].nodeName.includes('LineString')
              ) {
                const lineString = child.childNodes[5].childNodes[3]
                // console.log("AIS")
                // console.log(lineString)

                if (lineString.childNodes[3].nodeName.includes('coordinates')) {
                  // Parse coordinates
                  // console.log("PARSE COORDINATES")
                  if (lineString.childNodes[3].firstChild && lineString.childNodes[3].firstChild.textContent !== null) {
                    const coordinatesRaw = lineString.childNodes[3].firstChild.textContent
                    const result = coordinatesRaw.split(/\r?\n/)
                    result.forEach((coord) => {
                      const point = coord.trim().split(',')
                      if (point.length > 1 && child.childNodes[1].textContent !== null) {
                        trackName = child.childNodes[1].textContent
                        coordinates.push([Number(point[0]), Number(point[1]), Number(point[2])])
                      }
                    })
                  }
                }

                const newFeature = {
                  type: 'Feature',
                  properties: {
                    name: trackName,
                    styleUrl: '#line-000000-1200-nodesc',
                    styleHash: 'c2c637f',
                    styleMapHash: {
                      normal: '#line-000000-1200-nodesc-normal',
                      highlight: '#line-000000-1200-nodesc-highlight',
                    },
                    stroke: '#000000',
                    'stroke-opacity': 1,
                    'stroke-width': 1.2,
                  },
                  geometry: {
                    type: 'LineString',
                    coordinates,
                  },
                }

                if (coordinates.length > 0) {
                  sourcePoints.features.push(newFeature)
                }
              }
            })
          }
        }
      })
    }

    return sourcePoints
  }
}
