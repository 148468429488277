import React, { Component } from 'react'
import { Marker, Polyline } from 'react-leaflet'
import { connect } from 'react-redux'
import { setSidePanelContent, setSidePanelTitle, setSidePanelVisibility } from '../../../redux/ripples.actions'
import { RedCircleSmallIcon } from './Icons'
import { LatLng } from 'leaflet'
import DateService from '../../../services/DateUtils'
import { Button } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import IRipplesState from '../../../model/IRipplesState'
import FollowAssetService from '../../../services/FollowAssetUtils'
import PositionService from '../../../services/PositionUtils'

const { NotificationManager } = require('react-notifications')

export interface IAssetTrajectory {
  id: number
  imcId: number
  lat: number
  lon: number
  name: string
  timestamp: Date
}

export interface IAssetTrajectorySummary {
  totalDistance: number // in meters
  totalTime: number // in seconds
}

interface PropsType {
  isFollowAsstLayerActive: boolean
  vehicleSelected: string

  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
}

interface StateType {
  followAssetStartTimestamp: Date
  followAssetEndTimestamp: Date
  isFetchingData: boolean
  displayInfo: boolean
  fetchInterval: number
  assetTrajectory: IAssetTrajectory[]
  assetTrajectorySummary: IAssetTrajectorySummary | null
}

class FollowAsset extends Component<PropsType, StateType> {
  public fetchTimer = 0
  private followAssetService: FollowAssetService = new FollowAssetService()
  private positionService: PositionService = new PositionService()

  constructor(props: PropsType) {
    super(props)

    this.state = {
      followAssetStartTimestamp: new Date(),
      followAssetEndTimestamp: new Date(),
      isFetchingData: false,
      displayInfo: true,
      fetchInterval: 60000, // every minute
      assetTrajectory: [],
      assetTrajectorySummary: null,
    }

    this.updateAssetTrajectory = this.updateAssetTrajectory.bind(this)
    this.handleStartDatepicker = this.handleStartDatepicker.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.stopFetchData = this.stopFetchData.bind(this)
    this.cleanFollowAsset = this.cleanFollowAsset.bind(this)
    this.liveTrack = this.liveTrack.bind(this)
  }

  public buildFollowAssetDialog() {
    if (this.props.isFollowAsstLayerActive) {
      return (
        <div className="followAssetDialog">
          <p>Follow Asset</p>
          <div className="followAsset-date-container">
            <span className="trajectory-datepicker-label">Start date</span>
            <div className="followAsset-start-timestamp">
              <DatePicker
                id={'trajectory-start-timestamp'}
                className="trajectory-input-date"
                selected={this.state.followAssetStartTimestamp}
                onChange={(newDate: Date) => this.handleStartDatepicker(newDate)}
                showTimeSelect={true}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="time"
                maxDate={this.state.followAssetEndTimestamp}
                excludeScrollbar={true}
                timeIntervals={15}
                disabled={false}
              />
            </div>
          </div>

          {this.state.fetchInterval > 5000 ? (
            <div className="followAsset-date-container">
              <span className="trajectory-datepicker-label">End date</span>
              <div className="followAsset-end-timestamp">
                <DatePicker
                  id={'trajectory-end-timestamp'}
                  className="trajectory-input-date"
                  selected={this.state.followAssetEndTimestamp}
                  onChange={(newDate: Date) => this.handleEndDatePicker(newDate)}
                  showTimeSelect={true}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  maxDate={new Date()}
                  excludeScrollbar={true}
                  minDate={this.state.followAssetStartTimestamp}
                  timeIntervals={15}
                  disabled={false}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {this.state.isFetchingData ? (
            <Button className="m-1" color="danger" size="sm" onClick={this.stopFetchData}>
              Stop
            </Button>
          ) : (
            <>
              <Button className="m-1" color="info" size="sm" onClick={this.fetchData}>
                Fetch Positions
              </Button>
              <br />
              <Button className="m-1" color="info" size="sm" onClick={this.liveTrack}>
                Live Track
              </Button>
            </>
          )}

          {this.state.assetTrajectory.length > 0 ? (
            <div>
              <Button className="m-1" color="warning" size="sm" onClick={this.cleanFollowAsset}>
                Clean Positions
              </Button>
              <hr />
              <div className="followAssetSummary">
                {this.state.assetTrajectorySummary ? (
                  <div>
                    <p>Trajectory Summary</p>
                    <span>
                      Total Distance: {this.state.assetTrajectorySummary.totalDistance} m (
                      {this.positionService.metersToNauticalMiles(this.state.assetTrajectorySummary.totalDistance)} NM)
                    </span>
                    <span>Duration: {DateService.secondsToTime(this.state.assetTrajectorySummary.totalTime)}</span>
                  </div>
                ) : (
                  <Button
                    className="m-1"
                    color="info"
                    size="sm"
                    onClick={() =>
                      this.setState({
                        assetTrajectorySummary: this.positionService.calculateSummary(this.state.assetTrajectory),
                      })
                    }
                  >
                    Trajectory Summary
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )
    }
  }

  public handleStartDatepicker(newDate: Date) {
    this.setState({ followAssetStartTimestamp: newDate }, this.updateAssetTrajectory)
  }

  public handleEndDatePicker(newDate: Date) {
    this.setState({ followAssetEndTimestamp: newDate }, this.updateAssetTrajectory)
  }

  public buildAssetTrajectoryWP() {
    if (this.state.assetTrajectory.length > 0) {
      return this.state.assetTrajectory.map((pos, index) => {
        return (
          <Marker
            key={'assetTrajectory_' + index}
            position={[pos.lat, pos.lon]}
            icon={new RedCircleSmallIcon()}
            title={DateService.formatDate(pos.timestamp)}
          />
        )
      })
    }
  }

  public buildAssetTrajectory() {
    if (this.state.assetTrajectory.length > 0) {
      const polyline: LatLng[] = []
      this.state.assetTrajectory.forEach((pos) => {
        polyline.push(new LatLng(pos.lat, pos.lon))
      })
      return <Polyline positions={polyline} color="red" weight={1} dashArray="3" />
    }
  }

  private fetchData() {
    if (this.props.vehicleSelected.length === 0) {
      NotificationManager.info('Select a vehicle to follow')
    } else {
      if (this.state.displayInfo) {
        NotificationManager.info('Fetching positions...')
      }

      this.setState({ isFetchingData: true, displayInfo: false }, this.updateAssetTrajectory)

      this.fetchTimer = window.setInterval(() => {
        if (this.props.isFollowAsstLayerActive) {
          this.updateAssetTrajectory()
        }
      }, this.state.fetchInterval)
    }
  }

  private stopFetchData() {
    this.setState({ isFetchingData: false, displayInfo: true, fetchInterval: 60000 })
    clearInterval(this.fetchTimer)
  }

  private cleanFollowAsset() {
    this.setState({ assetTrajectory: [], assetTrajectorySummary: null })
  }

  private liveTrack() {
    if (this.props.vehicleSelected.length === 0) {
      NotificationManager.info('Select a vehicle to follow')
    } else {
      this.setState({ fetchInterval: 5000, followAssetEndTimestamp: new Date() }, this.fetchData)
    }
  }

  private async updateAssetTrajectory() {
    if (this.state.isFetchingData && this.props.vehicleSelected.length !== 0) {
      if (this.state.fetchInterval === 5000) {
        const resp: IAssetTrajectory[] = await this.followAssetService.fetchAssetTrajectoryAfterTimestamp(
          this.props.vehicleSelected,
          this.state.followAssetStartTimestamp
        )
        this.setState({ assetTrajectory: resp })
      } else {
        const resp: IAssetTrajectory[] = await this.followAssetService.fetchAssetTrajectoryBetweenTimestamps(
          this.props.vehicleSelected,
          this.state.followAssetStartTimestamp,
          this.state.followAssetEndTimestamp
        )
        this.setState({ assetTrajectory: resp })
      }
    }
  }

  public render() {
    return (
      <>
        {this.buildFollowAssetDialog()}
        {this.buildAssetTrajectoryWP()}
        {this.buildAssetTrajectory()}
      </>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    vehicleSelected: state.vehicleSelected,
  }
}
const actionCreators = {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
}

export default connect(mapStateToProps, actionCreators)(FollowAsset)
