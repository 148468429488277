export default interface IContact {
  label: string
  timestamp: Date
  latitude: number
  longitude: number
  state: string
  uncertainty: number
  classificationTypes: string[]
  classificationConfidence: number[]
}

export default class IContact implements IContact {
  constructor(
    public label: string,
    public timestamp: Date,
    public latitude: number,
    public longitude: number,
    public state: string,
    public uncertainty: number,
    public classificationTypes: string[],
    public classificationConfidence: number[]
  ) {}
}
