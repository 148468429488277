import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleNavbar from '../../components/SimpleNavbar'
import IAuthState, { isL4, IUser } from '../../model/IAuthState'
import IRipplesState from '../../model/IRipplesState'
import { setServicesAvailable, setUser } from '../../redux/ripples.actions'
import { getCurrentUser } from '../../services/UserUtils'
import IService, { IServicesDefinition } from '../../model/IService'
import {
  fetchDefaultServices,
  fetchServicesDefinitionByCampaign,
  updateDefaultService,
  updateServiceVisibility,
} from '../../services/ServicesUtils'
import LocalStorageService from '../../services/LocalStorageService'
const { NotificationManager } = require('react-notifications')

interface StateType {
  isNavOpen: boolean
  defaultParams: IService[]
  serviceSelected: string
  serviceInputElem: any | null
  serviceInputValue: string
}

interface PropsType {
  setUser: (user: IUser) => any
  setServicesAvailable: (_: IServicesDefinition[]) => void
  auth: IAuthState
  isDarkMode: boolean
}

/**
 * Display services configuration
 */
export class Services extends Component<PropsType, StateType> {
  private localStorageService: LocalStorageService = new LocalStorageService()
  public notificationSystem: any = null
  public timerID: number = 0

  constructor(props: any) {
    super(props)
    this.state = {
      isNavOpen: false,
      defaultParams: [],
      serviceSelected: '',
      serviceInputElem: null,
      serviceInputValue: '',
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.fetchServices = this.fetchServices.bind(this)
    this.updateService = this.updateService.bind(this)
    this.updateServicesAvailable = this.updateServicesAvailable.bind(this)
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    if (!this.props.auth.authenticated || (this.props.auth.authenticated && isL4(this.props.auth))) {
      NotificationManager.error('Permission required')
    } else {
      this.fetchServices().then(() => {
        if (this.state.defaultParams.length > 0) {
          this.setState({ serviceSelected: this.state.defaultParams[0].name })
        }
      })
      this.timerID = window.setInterval(this.fetchServices, 60000)
    }
  }

  public componentWillUnmount() {
    clearInterval(this.timerID)
  }

  public onNavToggle() {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  private async fetchServices() {
    const response: IService[] = await fetchDefaultServices()
    this.setState({ defaultParams: response })
  }

  private async updateServicesAvailable() {
    const servicesAvailable: IServicesDefinition[] = await fetchServicesDefinitionByCampaign(
      this.localStorageService.getCampaignSelected()
    )
    // update redux store
    this.props.setServicesAvailable(servicesAvailable)
  }

  private enableInputService(settingId: string, index: number) {
    const inputElem: any = document.getElementById('service-' + settingId + '-' + index)
    if (inputElem != null) {
      this.setState({
        serviceInputElem: inputElem,
        serviceInputValue: inputElem.value,
      })
    } else {
      this.setState({
        serviceInputElem: null,
        serviceInputValue: '',
      })
    }
  }

  private enableInputServiceParameter(serviceName: string, parameterName: string, index: number) {
    const inputElem: any = document.getElementById('service-' + serviceName + '-param-' + parameterName + '-' + index)
    if (inputElem != null) {
      this.setState({
        serviceInputElem: inputElem,
        serviceInputValue: inputElem.value,
      })
    } else {
      this.setState({
        serviceInputElem: null,
        serviceInputValue: '',
      })
    }
  }

  private async updateService(serviceName: any, parameterName: string) {
    const newParameterValue = this.state.serviceInputValue

    const response = await updateDefaultService(serviceName, parameterName, newParameterValue)
    if (response.status === 'Success') {
      this.setState({
        serviceInputElem: null,
        serviceInputValue: '',
      })
      this.fetchServices()
      this.updateServicesAvailable()
      NotificationManager.success('Service updated')
    } else {
      NotificationManager.warning('Cannot update service...')
    }
  }

  private async updateServiceVisibility(serviceName: any, available: boolean) {
    const response = await updateServiceVisibility(serviceName, available)
    if (response.status === 'Success') {
      this.fetchServices()
      this.updateServicesAvailable()
      NotificationManager.success(response.message)
    } else {
      NotificationManager.warning(response.message)
    }
  }

  private renderServicesName() {
    return this.state.defaultParams.map((service, i) => {
      let classValue: string = ''
      if (service.available) {
        classValue += 'available'
      } else {
        classValue += 'unavailable'
      }
      if (this.state.serviceSelected === service.name) {
        classValue += ' open'
      }

      return (
        <div key={i} className={classValue} onClick={() => this.setState({ serviceSelected: service.name })}>
          {service.name}
        </div>
      )
    })
  }

  private renderServices() {
    return this.state.defaultParams.map((service, i) => {
      return this.state.serviceSelected === service.name ? (
        <div key={i} className="settings-group">
          <div>
            <h3 className="settings-group-title">{service.name}</h3>
            {service.available ? (
              <i
                className="fas fa-toggle-on fa-lg service-visibility"
                title="Disable service"
                onClick={() => this.updateServiceVisibility(service.name, false)}
              />
            ) : (
              <i
                className="fas fa-toggle-off fa-lg service-visibility"
                title="Enable service"
                onClick={() => this.updateServiceVisibility(service.name, true)}
              />
            )}
          </div>
          {/* Service */}
          {this.state.serviceInputElem && this.state.serviceInputElem.id === 'service-' + service.name + '-' + i ? (
            <div key={i} className="setting-row" setting-input={'service-' + service.name + '-' + i}>
              <span className="setting-title">{'Description'}</span>
              <input
                type="text"
                className="setting-input"
                id={'service-' + service.name + '-' + i}
                value={this.state.serviceInputValue}
                onChange={(event) => this.setState({ serviceInputValue: event.target.value })}
                disabled={false}
              />
              <i
                className="fas fa-check"
                title="Update param"
                onClick={() => this.updateService(service.name, 'description')}
              />
            </div>
          ) : (
            <div key={i} className="setting-row" setting-input={'service-' + service.name + '-' + i}>
              <span className="setting-title">{'Description'}</span>
              <input
                id={'service-' + service.name + '-' + i}
                className="setting-input"
                type="text"
                disabled={true}
                value={service.description === '""' ? '' : service.description}
              />
              <i
                className="fas fa-pencil-alt"
                title="Edit param"
                onClick={() => this.enableInputService(service.name, i)}
              />
            </div>
          )}

          {/* Service parameters */}
          {service.defaultParams.length > 0 ? (
            <div key={service.name} className="service-params-group ">
              {service.defaultParams.map((param, index) => {
                return (
                  <div key={index}>
                    {this.state.serviceInputElem &&
                    this.state.serviceInputElem.id ===
                      'service-' + service.name + '-param-' + param[0] + '-' + index ? (
                      <div
                        className="setting-row"
                        setting-input={'service-' + service.name + '-param-' + param[0] + '-' + index}
                      >
                        <span className="setting-title">{param[0]}</span>
                        <input
                          id={'service-' + service.name + '-param-' + param[0] + '-' + index}
                          className="setting-input"
                          type="text"
                          value={this.state.serviceInputValue}
                          onChange={(event) => this.setState({ serviceInputValue: event.target.value })}
                          disabled={false}
                        />
                        <i
                          className="fas fa-check"
                          title="Update param"
                          onClick={() => this.updateService(service.name, param[0])}
                        />
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="setting-row"
                        setting-input={'service-' + service.name + '-param-' + param[0] + '-' + index}
                      >
                        <span className="setting-title">{param[0]}</span>
                        <input
                          id={'service-' + service.name + '-param-' + param[0] + '-' + index}
                          className="setting-input"
                          type="text"
                          disabled={true}
                          value={param[1] === '""' ? '' : param[1]}
                        />
                        <i
                          className="fas fa-pencil-alt"
                          title="Edit param"
                          onClick={() => this.enableInputServiceParameter(service.name, param[0], index)}
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div key={i} />
      )
    })
  }

  public render() {
    return (
      <>
        <SimpleNavbar auth={this.props} />
        <div className={this.props.isDarkMode ? 'services darkmode' : 'services'}>
          <div className="services-list">{this.renderServicesName()}</div>
          <div className="services-params">{this.renderServices()}</div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    isDarkMode: state.isDarkMode,
  }
}

const actionCreators = {
  setUser,
  setServicesAvailable,
}

export default connect(mapStateToProps, actionCreators)(Services)
