import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export default class ThreddsService {
  public async fetchExternalServers() {
    return request({
      url: `${apiURL}/thredds/serverAll`,
    })
  }

  public async fetchExternalServer(id: string) {
    return request({
      url: `${apiURL}/thredds/server/${id}`,
    })
  }
}
