import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export default class FollowAssetService {
  public async fetchAssetTrajectoryAfterTimestamp(assetName: string, timestamp: Date) {
    const start = Math.floor(timestamp.getTime() / 1000)
    return request({
      url: `${apiURL}/positions/${assetName}/${start}`,
    })
  }

  public async fetchAssetTrajectoryBetweenTimestamps(assetName: string, startTimestamp: Date, endTimestamp: Date) {
    const start = Math.floor(startTimestamp.getTime() / 1000)
    const end = Math.floor(endTimestamp.getTime() / 1000)
    return request({
      url: `${apiURL}/positions/${assetName}/${start}/${end}`,
    })
  }
}
