import ILatLng from '../model/ILatLng'
import { IMapSettings } from '../model/IMyMap'
import { WeatherParam } from '../model/WeatherParam'
import DateService from './DateUtils'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export default class MapUtils {
  public static initCoords = { lat: 41.18, lng: -8.7 }
  public static initZoom = 10

  public static buildRequestTime(deltaHours: number, updateIntervalInHours: number) {
    const date = DateService.timeOffsetToRealTime(deltaHours)
    let hour = date.getHours()
    if (hour % updateIntervalInHours !== 0) {
      hour -= hour % updateIntervalInHours
    }
    date.setHours(hour)
    return DateService.formatDateForRequest(date)
  }

  // public static buildLegendURL(layer: any) {
  //   let url = layer._url + '?'
  //   for (const key in layer.wmsParams) {
  //     if (key === 'request') {
  //       url += 'request=GetLegendGraphic&'
  //     } else if (key === 'layers') {
  //       url += 'layer=' + encodeURIComponent(layer.wmsParams[key]) + '&'
  //     } else if (key !== 'leaflet') {
  //       url += key + '=' + encodeURIComponent(layer.wmsParams[key]) + '&'
  //     }
  //   }
  //   return url
  // }

  public static buildLegendURL(layer: any) {
    // TODO: Remove style from string and replace with palette=redblue
    switch (layer._url) {
      case 'http://nrt.cmems-du.eu/thredds/wms/dataset-duacs-nrt-global-merged-allsat-phy-l4':
        return 'https://nrt.cmems-du.eu/thredds/wms/dataset-duacs-nrt-global-merged-allsat-phy-l4?service=WMS&request=GetLegendGraphic&layer=ugosa&palette=redblue&format=image%2Fpng&transparent=true&version=1.1.1&colorscalerange=-0.8%2C0.8&belowmincolor=extend&belowmaxcolor=extend&width=256&height=256&srs=EPSG%3A3857&'
      default:
        let url = layer._url + '?'
        for (const key in layer.wmsParams) {
          if (key === 'request') {
            url += 'request=GetLegendGraphic&'
          } else if (key === 'layers') {
            url += 'layer=' + encodeURIComponent(layer.wmsParams[key]) + '&'
          } else if (key !== 'leaflet') {
            url += key + '=' + encodeURIComponent(layer.wmsParams[key]) + '&'
          }
        }
        return url
    }
  }

  public static resetMapTime(updateIntervalInHours: number) {
    return MapUtils.buildRequestTime(0, updateIntervalInHours)
  }

  public static fetchWeatherData(location: ILatLng, params: WeatherParam) {
    return request({
      url: `${apiURL}/weather?lat=${location.latitude}&lng=${location.longitude}&params=${params}`,
    })
  }

  public static fetchUserMapSettings() {
    return request({
      url: `${apiURL}/users/map/settings`,
    })
  }

  public static updateMapSettings(settings: IMapSettings) {
    return request({
      method: 'POST',
      body: JSON.stringify(settings),
      url: `${apiURL}/users/map/settings`,
    })
  }
}
