import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleNavbar from '../../../components/SimpleNavbar'
import IAuthState, { hasL2Permissions, isL4, IUser } from '../../../model/IAuthState'
import IRipplesState from '../../../model/IRipplesState'
import { setCampaign, setServicesAvailable, setUser } from '../../../redux/ripples.actions'
import { fetchCampaignUsers, getCurrentUser } from '../../../services/UserUtils'
import ICampaign from '../../../model/ICampaign'
import { createMission, fetchCampaignByName, removeCampaign, updateCampaign } from '../../../services/CampaignUtils'
import DatePicker from 'react-datepicker'
import DateService from '../../../services/DateUtils'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import IService, { IServicesDefinition } from '../../../model/IService'
import {
  addServiceDefinition,
  fetchServicesDefinitionByCampaign,
  fetchVisibleServices,
  removeServiceDefinition,
  updateServiceDefinition,
} from '../../../services/ServicesUtils'
import { Link } from 'react-router-dom'
import LocalStorageService from '../../../services/LocalStorageService'
import IAsset from '../../../model/IAsset'
const { NotificationManager } = require('react-notifications')

interface StateType {
  loading: boolean
  isLeader: boolean
  leaderEmail: string
  leaders: string[]
  users: string[]
  services: IService[]
  startDate: Date
  endDate: Date
  isConfirmationModalOpen: boolean
  isUsersModalOpen: boolean
  isUserConfirmationModalOpen: boolean
  itemToRemove: string
  isAssetModalOpen: boolean
  isAssetConfirmationModalOpen: boolean
  isServicesModalOpen: boolean
  isMissionModalOpen: boolean
  missionInputValue: string
  serviceToEdit: IServicesDefinition | undefined
  serviceInputElem: any | null
  serviceInputValue: string
}

interface PropsType {
  setUser: (user: IUser) => any
  setCampaign: (_: ICampaign | null) => void
  setServicesAvailable: (_: IServicesDefinition[]) => void
  auth: IAuthState
  isDarkMode: boolean
  vehicles: IAsset[]
  campaignSelected: ICampaign | null
}

export class Campaign extends Component<PropsType, StateType> {
  public notificationSystem: any = null
  private localStorageService: LocalStorageService = new LocalStorageService()

  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      isLeader: false,
      leaderEmail: '',
      leaders: [],
      users: [],
      services: [],
      startDate: new Date(),
      endDate: new Date(),
      isConfirmationModalOpen: false,
      isUsersModalOpen: false,
      isUserConfirmationModalOpen: false,
      itemToRemove: '',
      isAssetModalOpen: false,
      isAssetConfirmationModalOpen: false,
      isServicesModalOpen: false,
      isMissionModalOpen: false,
      missionInputValue: '',
      serviceToEdit: undefined,
      serviceInputElem: null,
      serviceInputValue: '',
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.loadCampaign = this.loadCampaign.bind(this)
    this.updateCampaign = this.updateCampaign.bind(this)
    this.removeCampaign = this.removeCampaign.bind(this)
    this.addServiceDefinition = this.addServiceDefinition.bind(this)
    this.updateServiceDefinition = this.updateServiceDefinition.bind(this)
    this.redirectToMissionPage = this.redirectToMissionPage.bind(this)
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  private async loadCampaign() {
    const campaignSelected: ICampaign = await fetchCampaignByName(
      this.localStorageService.getCampaignSelected(),
      this.props.auth.currentUser.email
    )
    this.props.setCampaign(campaignSelected)
    if (this.props.campaignSelected === null) {
      NotificationManager.error('Campaign not found')
      return
    }

    // check if user is campaign leader
    let isLeader: boolean = false
    const leaders: string[] = []
    const users: string[] = []
    if (this.props.campaignSelected.leader.includes(this.props.auth.currentUser.email)) {
      isLeader = true

      // fetch users
      fetchCampaignUsers().then((data: any) => {
        data.forEach((u: any) => {
          if (u.role === 'L0' || u.role === 'L1' || u.role === 'L2') {
            leaders.push(u.email)
          }
          users.push(u.email)
        })
        leaders.sort()
        users.sort()
        this.setState({ users, leaders })
      })
    }

    // fetch services visible
    const services: IService[] = []
    fetchVisibleServices().then((data: any) => {
      data.forEach((s: any) => {
        services.push(s)
      })
      services.sort()
      this.setState({ services })
    })

    const leaderEmail: string = this.props.campaignSelected.leader

    const d1: string = DateService.formatDateOnlySeparated(this.props.campaignSelected.startDate)
    const startDate: Date = new Date(
      parseInt(d1.split('/')[2], 10),
      parseInt(d1.split('/')[1], 10) - 1,
      parseInt(d1.split('/')[0], 10)
    )

    const d2: string = DateService.formatDateOnlySeparated(this.props.campaignSelected.endDate)
    const endDate: Date = new Date(
      parseInt(d2.split('/')[2], 10),
      parseInt(d2.split('/')[1], 10) - 1,
      parseInt(d2.split('/')[0], 10)
    )

    this.setState({
      startDate,
      endDate,
      isLeader,
      leaderEmail,
      isUsersModalOpen: false,
      isUserConfirmationModalOpen: false,
      itemToRemove: '',
      isAssetModalOpen: false,
      isAssetConfirmationModalOpen: false,
      isMissionModalOpen: false,
      isServicesModalOpen: false,
      missionInputValue: '',
      serviceToEdit: undefined,
    })

    // update redux store
    const servicesAvailable: IServicesDefinition[] = await fetchServicesDefinitionByCampaign(
      this.localStorageService.getCampaignSelected()
    )
    this.props.setServicesAvailable(servicesAvailable)
  }

  private async updateCampaign(key: string, value: string | Date) {
    if (this.props.campaignSelected) {
      if (value instanceof Date) {
        // update dates
        value.setHours(1)
        const resp = await updateCampaign(this.props.campaignSelected.name, key, value.getTime())
        if (resp.status === 'Success') {
          NotificationManager.success(resp.message)
          this.loadCampaign()
        } else {
          NotificationManager.warning(resp.message)
        }
      } else {
        const resp = await updateCampaign(this.props.campaignSelected.name, key, value)
        if (resp.status === 'Success') {
          NotificationManager.success(resp.message)
          this.loadCampaign()
        } else {
          NotificationManager.warning(resp.message)
        }
      }
    } else {
      NotificationManager.error('Invalid request')
    }
  }

  private async removeCampaign() {
    if (this.props.campaignSelected) {
      const resp = await removeCampaign(this.props.campaignSelected)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
        // redirect to homepage and update localstorage
        const homeLink = document.getElementById('homepage-link')
        if (homeLink !== null) {
          this.localStorageService.setCampaignSelected('RIPPLES')
          homeLink.click()
        }
      } else {
        NotificationManager.warning(resp.message)
      }
    } else {
      NotificationManager.warning('Campaign not found...')
    }
  }

  private async addServiceDefinition(serviceSelected: string) {
    if (this.props.campaignSelected) {
      const resp = await addServiceDefinition(this.props.campaignSelected.name, serviceSelected)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
      } else {
        NotificationManager.warning(resp.message)
      }
      this.loadCampaign()
    }
  }

  private async updateServiceDefinition(paramKey: string) {
    const newParamVal = this.state.serviceInputValue
    if (newParamVal.length === 0) {
      NotificationManager.warning('Value cannot be empty')
      return
    }

    if (this.state.serviceToEdit !== undefined) {
      const resp = await updateServiceDefinition(this.state.serviceToEdit, paramKey, newParamVal)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
        this.loadCampaign()
      } else {
        NotificationManager.warning(resp.message)
      }
    } else {
      NotificationManager.warning('Cannot update service...')
    }

    // fetch campaigns
    this.setState({
      isServicesModalOpen: false,
      serviceToEdit: undefined,
      serviceInputElem: null,
      serviceInputValue: '',
    })
  }

  private async removeService() {
    if (this.state.serviceToEdit) {
      const resp = await removeServiceDefinition(this.state.serviceToEdit)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
      } else {
        NotificationManager.warning(resp.message)
      }
    } else {
      NotificationManager.warning('Cannot remove service...')
    }
    this.loadCampaign()
  }

  private async createMission() {
    if (this.state.missionInputValue.length > 0) {
      if (this.state.missionInputValue.indexOf(' ') >= 0) {
        NotificationManager.warning('Mission cannot have whitespaces')
        return
      }

      if (this.props.campaignSelected) {
        const resp = await createMission(
          this.state.missionInputValue,
          this.props.campaignSelected.name,
          this.props.auth.currentUser.email
        )
        if (resp.status === 'Success') {
          NotificationManager.success(resp.message)
          this.loadCampaign()
        } else {
          NotificationManager.warning(resp.message)
        }
      }
    } else {
      NotificationManager.warning('Invalid value...')
    }
  }

  private redirectToMissionPage(missionName: string) {
    this.localStorageService.setMissionSelected(missionName)

    const missionLink = document.getElementById('redirectToMission-' + missionName)
    if (missionLink !== null) {
      missionLink.click()
    } else {
      NotificationManager.warning('Cannot open mission...')
    }
  }

  private enableInputService(key: string) {
    if (this.state.serviceToEdit !== undefined) {
      const settingId: number = this.state.serviceToEdit.id
      const inputElem: any = document.getElementById('serviceDefinition-' + settingId + '-' + key)

      if (inputElem != null) {
        this.setState({
          serviceInputElem: inputElem,
          serviceInputValue: inputElem.value,
        })
      } else {
        this.setState({
          serviceInputElem: null,
          serviceInputValue: '',
        })
      }
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    this.setState({ loading: false })

    if (this.props.auth.authenticated) {
      if (!isL4(this.props.auth)) {
        // TODO
        this.loadCampaign()
      } else {
        NotificationManager.error('Invalid permission...')
      }
    } else {
      NotificationManager.error('Please login')
    }
  }

  private renderLeftContainer() {
    if (this.props.campaignSelected) {
      return (
        <div className="left-container campaign-field">
          <h4>Start Date</h4>
          <DatePicker
            className="setting-input-date"
            selected={this.state.startDate}
            onChange={(newDate: Date) => this.updateCampaign('startDate', newDate)}
            dateFormat="dd/MM/yyyy"
            timeCaption="time"
            minDate={new Date()}
            maxDate={this.state.endDate}
            disabled={!this.state.isLeader}
          />

          <h4>End Date</h4>
          <DatePicker
            className="setting-input-date"
            selected={this.state.endDate}
            onChange={(newDate: Date) => this.updateCampaign('endDate', newDate)}
            dateFormat="dd/MM/yyyy"
            timeCaption="time"
            minDate={this.state.startDate}
            disabled={!this.state.isLeader}
          />

          {this.renderLeaders()}
        </div>
      )
    }
  }

  private renderLeaders() {
    if (this.props.campaignSelected) {
      return (
        <>
          <h4>Leader</h4>
          {this.state.isLeader ? (
            <select
              id="campaignLeader"
              value={this.state.leaderEmail}
              onChange={(event: any) => this.updateCampaign('leader', event.target.value)}
              onClick={(event) => event.stopPropagation()}
            >
              {this.state.leaders.map((l, index) => {
                return (
                  <option key={'leaderOption-' + index} value={l} onClick={(event) => event.stopPropagation()}>
                    {l}
                  </option>
                )
              })}
            </select>
          ) : (
            <input type="text" value={this.state.leaderEmail} disabled={true} />
          )}
        </>
      )
    }
  }

  private renderRightContainer() {
    if (this.props.campaignSelected) {
      const isMissionAvailable: boolean = this.props.campaignSelected.available
      return (
        <div className="right-container">
          {hasL2Permissions(this.props.auth) ? (
            <>
              <Button
                color="danger"
                title="Remove campaign"
                onClick={() => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen })}
              >
                Remove
              </Button>
              <Link id="homepage-link" to="/" />

              <Button
                color={isMissionAvailable ? 'success' : 'warning'}
                title="Change campaign status"
                onClick={() => this.updateCampaign('available', (!isMissionAvailable).toString())}
              >
                {isMissionAvailable ? 'Available' : 'Unavailable'}
              </Button>
            </>
          ) : (
            <></>
          )}
          {this.buildConfirmationModal()}
        </div>
      )
    }
  }

  private buildConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isConfirmationModalOpen}
        toggle={() => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen })}
      >
        <ModalHeader toggle={() => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen })}>
          Remove Campaign
        </ModalHeader>
        <ModalBody>The Campaign will be removed permanently. Do you want to continue?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.removeCampaign()}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderUsers() {
    if (this.props.campaignSelected) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Users</h4>
            {this.state.isLeader ? (
              <Button
                className="campaign-group-add"
                title="Add analyst"
                color="info"
                size="sm"
                onClick={() => this.setState({ isUsersModalOpen: !this.state.isUsersModalOpen })}
              >
                Add User
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.props.campaignSelected.users.map((user, index) => {
              return (
                <>
                  <Button
                    key={'user-' + index}
                    title={this.state.isLeader ? 'Click to remove' : ''}
                    color="#e1e3e6"
                    size="sm"
                    onClick={
                      this.state.isLeader
                        ? () =>
                            this.setState({
                              itemToRemove: user,
                              isUserConfirmationModalOpen: !this.state.isUserConfirmationModalOpen,
                            })
                        : undefined
                    }
                  >
                    {user}
                  </Button>
                </>
              )
            })}
          </div>

          {this.buildUsersModal()}
          {this.buildUserConfirmationModal()}
        </div>
      )
    }
  }

  private buildUsersModal() {
    return (
      <Modal
        isOpen={this.state.isUsersModalOpen}
        toggle={() => this.setState({ isUsersModalOpen: !this.state.isUsersModalOpen })}
      >
        <ModalHeader toggle={() => this.setState({ isUsersModalOpen: !this.state.isUsersModalOpen })}>
          Add user to Campaing
        </ModalHeader>
        <ModalBody>
          <select
            className="campaignUsers-input"
            defaultValue={''}
            onChange={(event: any) => this.updateCampaign('users', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose user'}
            </option>
            {this.state.users.map((u, index) => {
              if (this.props.campaignSelected) {
                return (
                  <option
                    key={'userOption-' + index}
                    value={u}
                    disabled={this.props.campaignSelected.users.includes(u)}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {u}
                  </option>
                )
              } else {
                return <></>
              }
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildUserConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isUserConfirmationModalOpen}
        toggle={() =>
          this.setState({ itemToRemove: '', isUserConfirmationModalOpen: !this.state.isUserConfirmationModalOpen })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({ itemToRemove: '', isUserConfirmationModalOpen: !this.state.isUserConfirmationModalOpen })
          }
        >
          Remove user
        </ModalHeader>
        <ModalBody>
          The user <b>{this.state.itemToRemove}</b> will be removed from the campaign. <br />
          Do you want to continue?
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.state.isLeader ? () => this.updateCampaign('users', this.state.itemToRemove) : undefined}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderAssets() {
    if (this.props.campaignSelected) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Assets</h4>
            {this.state.isLeader ? (
              <Button
                className="campaign-group-add"
                title="Add asset"
                color="info"
                size="sm"
                onClick={() => this.setState({ isAssetModalOpen: !this.state.isAssetModalOpen })}
              >
                Add asset
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.props.campaignSelected.assets.map((assetName, i) => {
              return (
                <Button
                  key={'asset-' + i}
                  title={this.state.isLeader ? 'Click to remove' : ''}
                  color="#e1e3e6"
                  size="sm"
                  onClick={
                    this.state.isLeader
                      ? () =>
                          this.setState({
                            itemToRemove: assetName,
                            isAssetConfirmationModalOpen: !this.state.isAssetConfirmationModalOpen,
                          })
                      : undefined
                  }
                >
                  {assetName}
                </Button>
              )
            })}
          </div>
          {this.buildAssetModal()}
          {this.buildAssetConfirmationModal()}
        </div>
      )
    }
  }

  private buildAssetModal() {
    // assets options
    const assetsList: string[] = []
    this.props.vehicles.forEach((assetName: IAsset) => {
      assetsList.push(assetName.name)
    })
    assetsList.sort()

    return (
      <Modal
        isOpen={this.state.isAssetModalOpen}
        toggle={() => this.setState({ isAssetModalOpen: !this.state.isAssetModalOpen })}
      >
        <ModalHeader toggle={() => this.setState({ isAssetModalOpen: !this.state.isAssetModalOpen })}>
          Add asset to Campaign
        </ModalHeader>

        <ModalBody>
          <select
            className="campaignAsset-input"
            defaultValue={''}
            onChange={(event: any) => this.updateCampaign('assets', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose asset'}
            </option>

            {assetsList.map((assetToAdd, index) => {
              if (this.props.campaignSelected) {
                // check if asset is already present
                let isAssetPresent: boolean = false
                this.props.campaignSelected.assets.forEach((assetName) => {
                  if (assetName.includes(assetToAdd)) {
                    isAssetPresent = true
                    return
                  }
                })
                return (
                  <option
                    key={'assetOption-' + index}
                    value={assetToAdd}
                    disabled={isAssetPresent}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {assetToAdd}
                  </option>
                )
              } else {
                return <></>
              }
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildAssetConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isAssetConfirmationModalOpen}
        toggle={() =>
          this.setState({ itemToRemove: '', isAssetConfirmationModalOpen: !this.state.isAssetConfirmationModalOpen })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({ itemToRemove: '', isAssetConfirmationModalOpen: !this.state.isAssetConfirmationModalOpen })
          }
        >
          Remove asset
        </ModalHeader>
        <ModalBody>
          The asset <b>{this.state.itemToRemove}</b> will be removed from the campaign. <br />
          Do you want to continue?
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.state.isLeader ? () => this.updateCampaign('assets', this.state.itemToRemove) : undefined}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderServicesDefinition() {
    if (this.props.campaignSelected) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Services</h4>
            {this.state.isLeader ? (
              <Button
                className="campaign-group-add"
                title="Add asset"
                color="info"
                size="sm"
                onClick={() => this.setState({ isServicesModalOpen: !this.state.isServicesModalOpen })}
              >
                Add service
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.props.campaignSelected.servicesDefinition.map((service, i) => {
              // available services
              let isServiceAvailable: boolean = false
              this.state.services.forEach((s) => {
                if (s.name === service.serviceName) {
                  isServiceAvailable = true
                }
              })

              return (
                <Button
                  key={'service-' + i}
                  className={isServiceAvailable ? '' : 'campaign-btn-edit-disable'}
                  title="Config service"
                  color={'#e1e3e6'}
                  size="sm"
                  onClick={() => this.setState({ serviceToEdit: service })}
                >
                  {service.displayName}
                </Button>
              )
            })}
          </div>
          {this.buildServicesModal()}
          {this.buildServicesEditModal()}
        </div>
      )
    }
  }

  private buildServicesModal() {
    return (
      <Modal
        isOpen={this.state.isServicesModalOpen}
        toggle={() => this.setState({ isServicesModalOpen: !this.state.isServicesModalOpen })}
      >
        <ModalHeader toggle={() => this.setState({ isServicesModalOpen: !this.state.isServicesModalOpen })}>
          Add service to Campaign
        </ModalHeader>
        <ModalBody>
          <select
            className="campaignServices-input"
            defaultValue={''}
            onChange={(event: any) => this.addServiceDefinition(event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose service'}
            </option>
            {this.state.services.map((s, index) => {
              return (
                <option key={'serviceOption-' + index} value={s.name} onClick={(event) => event.stopPropagation()}>
                  {s.name + ': ' + s.description}
                </option>
              )
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildServicesEditModal() {
    if (this.state.serviceToEdit !== undefined) {
      return (
        <Modal
          isOpen={this.state.serviceToEdit !== undefined}
          toggle={() => this.setState({ serviceToEdit: undefined, serviceInputElem: null, serviceInputValue: '' })}
        >
          <ModalHeader
            toggle={() => this.setState({ serviceToEdit: undefined, serviceInputElem: null, serviceInputValue: '' })}
          >
            {'[' + this.state.serviceToEdit.serviceName + '] Service configuration'}
          </ModalHeader>

          <ModalBody>
            {this.renderServiceInfo()}
            {this.renderServiceDisplayName()}
            {this.renderServiceParameters()}
          </ModalBody>

          {this.state.isLeader && (
            <ModalFooter>
              <Button color="danger" onClick={() => this.removeService()}>
                Remove service
              </Button>
            </ModalFooter>
          )}
        </Modal>
      )
    } else {
      return <></>
    }
  }

  private renderServiceInfo() {
    if (this.state.serviceToEdit !== undefined) {
      return (
        <div className="setting-row" setting-input={'service-' + this.state.serviceToEdit.id + '-displayName'}>
          <b>Service: </b>
          {'[' + this.state.serviceToEdit.id + '] ' + this.state.serviceToEdit.serviceName}
          <hr />
        </div>
      )
    }
  }

  private renderServiceDisplayName() {
    if (this.state.serviceToEdit !== undefined) {
      return this.state.serviceInputElem &&
        this.state.serviceInputElem.id === 'serviceDefinition-' + this.state.serviceToEdit.id + '-displayName' ? (
        <div className="setting-row" setting-input={'service-' + this.state.serviceToEdit.id + '-displayName'}>
          <span className="setting-title">{'Display Name'}</span>
          <input
            id={'serviceDefinition-' + this.state.serviceToEdit.id + '-displayName'}
            className="setting-input"
            type="text"
            disabled={false}
            value={this.state.serviceInputValue}
            onChange={(event) => this.setState({ serviceInputValue: event.target.value })}
          />
          <i
            className="fas fa-check"
            title="Update param"
            onClick={() => this.updateServiceDefinition('displayName')}
          />
        </div>
      ) : (
        <div
          className="setting-row"
          setting-input={'serviceDefinition-' + this.state.serviceToEdit.id + '-displayName'}
        >
          <span className="setting-title">{'Display Name'}</span>
          <input
            id={'serviceDefinition-' + this.state.serviceToEdit.id + '-displayName'}
            className="setting-input"
            type="text"
            disabled={true}
            value={this.state.serviceToEdit.displayName}
          />

          {this.state.isLeader && (
            <i
              className="fas fa-pencil-alt"
              title="Edit param"
              onClick={() => this.enableInputService('displayName')}
            />
          )}
        </div>
      )
    }
  }

  private renderServiceParameters() {
    if (this.state.serviceToEdit !== undefined) {
      return this.state.serviceToEdit.params.length > 0 ? (
        <div className="service-params-group">
          {this.state.serviceToEdit.params.map((param, index) => {
            if (this.state.serviceToEdit !== undefined) {
              return (
                <div key={'param-' + index}>
                  {this.state.serviceInputElem &&
                  this.state.serviceInputElem.id ===
                    'serviceDefinition-' + this.state.serviceToEdit.id + '-' + param[0] ? (
                    <div
                      className="setting-row"
                      setting-input={'serviceDefinition-' + this.state.serviceToEdit.id + '-' + param[0]}
                    >
                      <span className="setting-title">{param[0]}</span>
                      <input
                        id={'serviceDefinition-' + this.state.serviceToEdit.id + '-' + param[0]}
                        className="setting-input"
                        type="text"
                        value={this.state.serviceInputValue}
                        onChange={(event) => this.setState({ serviceInputValue: event.target.value })}
                        disabled={false}
                      />
                      <i
                        className="fas fa-check"
                        title="Update param"
                        onClick={() => this.updateServiceDefinition(param[0])}
                      />
                    </div>
                  ) : (
                    <div
                      className="setting-row"
                      setting-input={'serviceDefinition-' + this.state.serviceToEdit.id + '-' + param[0]}
                    >
                      <span className="setting-title">{param[0]}</span>
                      <input
                        id={'serviceDefinition-' + this.state.serviceToEdit.id + '-' + param[0]}
                        className="setting-input"
                        type="text"
                        disabled={true}
                        value={param[1] === '""' ? '' : param[1]}
                      />
                      {this.state.isLeader && (
                        <i
                          className="fas fa-pencil-alt"
                          title="Edit param"
                          onClick={() => this.enableInputService(param[0])}
                        />
                      )}
                    </div>
                  )}
                </div>
              )
            }
            return <></>
          })}
        </div>
      ) : (
        <></>
      )
    }
  }

  private renderMissions() {
    if (this.props.campaignSelected) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Missions</h4>
            {this.state.isLeader ? (
              <Button
                key={'missionBtn'}
                className="campaign-group-add"
                title="Create Mission"
                color="info"
                size="sm"
                onClick={() =>
                  this.setState({ isMissionModalOpen: !this.state.isMissionModalOpen, missionInputValue: '' })
                }
              >
                Create mission
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.props.campaignSelected.missions.map((mission, i) => {
              return (
                <React.Fragment key={'mission-' + i}>
                  <Button
                    title="Open mission"
                    color="#e1e3e6"
                    size="sm"
                    onClick={() => this.redirectToMissionPage(mission.name)}
                  >
                    {mission.name}
                  </Button>
                  <Link id={'redirectToMission-' + mission.name} to={'/campaign/mission'} />
                </React.Fragment>
              )
            })}
          </div>
          {this.buildMissionModal()}
        </div>
      )
    }
  }

  private buildMissionModal() {
    return (
      <Modal
        isOpen={this.state.isMissionModalOpen}
        toggle={() => this.setState({ isMissionModalOpen: !this.state.isMissionModalOpen })}
      >
        <ModalHeader toggle={() => this.setState({ isMissionModalOpen: !this.state.isMissionModalOpen })}>
          New mission
        </ModalHeader>

        <ModalBody>
          <input
            className="campaignMission-input"
            placeholder="Mission name"
            value={this.state.missionInputValue}
            onChange={(event) => {
              if (event.target.value.match('^[A-Za-z0-9_]*$') != null) {
                this.setState({ missionInputValue: event.target.value })
              } else {
                NotificationManager.warning('Invalid value')
              }
            }}
          />
          <div className="requirements-container">
            <span>Requirements:</span>
            <ul>
              <li>Without whitespaces</li>
              <li>Without special characters (e.g., !, @, #, $, %, &, *, -, etc.)</li>
            </ul>
          </div>
        </ModalBody>

        <ModalFooter>
          {this.state.missionInputValue.length > 0 && (
            <Button color="primary" onClick={() => this.createMission()}>
              Create
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }

  public render() {
    if (this.props.campaignSelected === null) {
      return (
        <>
          <SimpleNavbar auth={this.props} />
        </>
      )
    }

    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      return (
        <>
          <SimpleNavbar auth={this.props} />
          <div className="campaignTitle">{this.props.campaignSelected.name}</div>
          <div className={this.props.isDarkMode ? 'campaign darkmode' : 'campaign'}>
            <div className="header">
              {this.renderLeftContainer()}
              {this.state.isLeader && !this.props.campaignSelected.name.includes('RIPPLES') ? (
                this.renderRightContainer()
              ) : (
                <></>
              )}
            </div>

            {this.renderUsers()}
            {this.renderAssets()}
            {this.renderServicesDefinition()}
            {this.renderMissions()}
          </div>
        </>
      )
    }
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    isDarkMode: state.isDarkMode,
    vehicles: state.assets.vehicles,
    campaignSelected: state.campaignSelected,
  }
}

const actionCreators = {
  setUser,
  setCampaign,
  setServicesAvailable,
}

export default connect(mapStateToProps, actionCreators)(Campaign)
