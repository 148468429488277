import IAisShip, { AisShip } from '../../../model/IAisShip'
import IPositionAtTime from '../../../model/IPositionAtTime'
import PositionService from '../../../services/PositionUtils'
import CheckPosition from './CheckPosition'

interface PropsType {
  ship: IAisShip
  perpLinesSize: number
}

export default class AISCanvas {
  public props: PropsType
  private positionService: PositionService = new PositionService()
  private checkPosition: CheckPosition = new CheckPosition()
  constructor(props: PropsType) {
    this.props = props
    this.drawInCanvas = this.drawInCanvas.bind(this)
    this.getCirclesCenter = this.getCirclesCenter.bind(this)
  }

  public drawInCanvas(info: any, displayMeasure: boolean) {
    const ship = this.props.ship
    const ctx = info.canvas.getContext('2d')
    if (ship.sog > 0.2) {
      this.drawAisLines(info, ctx, ship, displayMeasure)
    }
  }

  private drawAisLines(info: any, ctx: any, ship: IAisShip, displayMeasure: boolean) {
    const speed = ship.sog * this.positionService.getKnotsToMs()
    const posIn1H = this.positionService.calculateNextPosition(AisShip.getPositionAtTime(ship), ship.cog, speed, 3600)
    const pointA = info.map.latLngToContainerPoint([ship.latitude, ship.longitude])
    const pointB = info.map.latLngToContainerPoint([posIn1H.latitude, posIn1H.longitude])
    let nPoints = 0

    if (this.checkPosition.isOverLand(posIn1H.latitude.toFixed(13), posIn1H.longitude.toFixed(13))) {
      const posIn50M = this.positionService.calculateNextPosition(
        AisShip.getPositionAtTime(ship),
        ship.cog,
        speed,
        3000
      )
      const posIn45M = this.positionService.calculateNextPosition(
        AisShip.getPositionAtTime(ship),
        ship.cog,
        speed,
        2700
      )
      const posIn40M = this.positionService.calculateNextPosition(
        AisShip.getPositionAtTime(ship),
        ship.cog,
        speed,
        2400
      )
      const posIn35M = this.positionService.calculateNextPosition(
        AisShip.getPositionAtTime(ship),
        ship.cog,
        speed,
        2100
      )
      const posIn30M = this.positionService.calculateNextPosition(
        AisShip.getPositionAtTime(ship),
        ship.cog,
        speed,
        1800
      )
      const posIn25M = this.positionService.calculateNextPosition(
        AisShip.getPositionAtTime(ship),
        ship.cog,
        speed,
        1500
      )
      const posIn20M = this.positionService.calculateNextPosition(
        AisShip.getPositionAtTime(ship),
        ship.cog,
        speed,
        1200
      )
      const posIn15M = this.positionService.calculateNextPosition(AisShip.getPositionAtTime(ship), ship.cog, speed, 900)
      const posIn10M = this.positionService.calculateNextPosition(AisShip.getPositionAtTime(ship), ship.cog, speed, 600)
      const posIn5M = this.positionService.calculateNextPosition(AisShip.getPositionAtTime(ship), ship.cog, speed, 300)
      const posIn4M = this.positionService.calculateNextPosition(AisShip.getPositionAtTime(ship), ship.cog, speed, 240)
      const posIn3M = this.positionService.calculateNextPosition(AisShip.getPositionAtTime(ship), ship.cog, speed, 180)
      const posIn2M = this.positionService.calculateNextPosition(AisShip.getPositionAtTime(ship), ship.cog, speed, 120)

      if (this.checkPosition.isOverLand(posIn2M.latitude.toFixed(13), posIn2M.longitude.toFixed(13))) {
        const newPointB = info.map.latLngToContainerPoint([ship.latitude, ship.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn3M.latitude.toFixed(13), posIn3M.longitude.toFixed(13))) {
        const newPointB = info.map.latLngToContainerPoint([posIn2M.latitude, posIn2M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn4M.latitude.toFixed(13), posIn4M.longitude.toFixed(13))) {
        const newPointB = info.map.latLngToContainerPoint([posIn3M.latitude, posIn3M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn5M.latitude.toFixed(13), posIn5M.longitude.toFixed(13))) {
        const newPointB = info.map.latLngToContainerPoint([posIn4M.latitude, posIn4M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn10M.latitude.toFixed(13), posIn10M.longitude.toFixed(13))) {
        nPoints = 1
        const newPointB = info.map.latLngToContainerPoint([posIn5M.latitude, posIn5M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn15M.latitude.toFixed(13), posIn15M.longitude.toFixed(13))) {
        nPoints = 1
        const newPointB = info.map.latLngToContainerPoint([posIn10M.latitude, posIn10M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn20M.latitude.toFixed(13), posIn20M.longitude.toFixed(13))) {
        nPoints = 2
        const newPointB = info.map.latLngToContainerPoint([posIn15M.latitude, posIn15M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn25M.latitude.toFixed(13), posIn25M.longitude.toFixed(13))) {
        nPoints = 2
        const newPointB = info.map.latLngToContainerPoint([posIn20M.latitude, posIn20M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn30M.latitude.toFixed(13), posIn30M.longitude.toFixed(13))) {
        nPoints = 3
        const newPointB = info.map.latLngToContainerPoint([posIn25M.latitude, posIn25M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn35M.latitude.toFixed(13), posIn35M.longitude.toFixed(13))) {
        nPoints = 3
        const newPointB = info.map.latLngToContainerPoint([posIn30M.latitude, posIn30M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn40M.latitude.toFixed(13), posIn40M.longitude.toFixed(13))) {
        nPoints = 4
        const newPointB = info.map.latLngToContainerPoint([posIn35M.latitude, posIn35M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn45M.latitude.toFixed(13), posIn45M.longitude.toFixed(13))) {
        nPoints = 4
        const newPointB = info.map.latLngToContainerPoint([posIn40M.latitude, posIn40M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else if (this.checkPosition.isOverLand(posIn50M.latitude.toFixed(13), posIn50M.longitude.toFixed(13))) {
        nPoints = 5
        const newPointB = info.map.latLngToContainerPoint([posIn45M.latitude, posIn45M.longitude])
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(newPointB.x, newPointB.y)
        ctx.stroke()
      } else {
        nPoints = 5
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgb(0,0,0,0.5)'
        ctx.moveTo(pointA.x, pointA.y)
        ctx.lineTo(pointB.x, pointB.y)
        ctx.stroke()
      }
    } else {
      nPoints = 6
      ctx.beginPath()
      ctx.lineWidth = 1
      ctx.strokeStyle = 'rgb(0,0,0,0.5)'
      ctx.moveTo(pointA.x, pointA.y)
      ctx.lineTo(pointB.x, pointB.y)
      ctx.stroke()
    }

    this.getCirclesCenter(ship, nPoints).forEach((line: IPositionAtTime, index: number) => {
      const radius = 2
      const center = info.map.latLngToContainerPoint([line.latitude, line.longitude])
      ctx.beginPath()
      ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI, false)
      ctx.fillStyle = 'rgb(127,0,0)'

      if (displayMeasure) {
        ctx.fillText(10 * (index + 1), center.x, center.y)
      }

      ctx.fill()
    })
  }

  private getCirclesCenter(ship: IAisShip, nPoints: number): IPositionAtTime[] {
    const tenMinutes = 600
    const centers: IPositionAtTime[] = []
    const aisCurrentPos = AisShip.getPositionAtTime(ship)
    const shipSpeed = ship.sog * this.positionService.getKnotsToMs()
    for (let i = 1; i <= nPoints; i++) {
      const time = i * tenMinutes
      const pointC = this.positionService.calculateNextPosition(aisCurrentPos, ship.cog, shipSpeed, time)
      centers.push(pointC)
    }
    return centers
  }
}
