import IIntrusion from '../model/IIntrusion'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export async function fetchIntrusionsByCampaignName(campaignName: string) {
  return request({
    url: `${apiURL}/intrusion?campaignName=${campaignName}`,
  })
}

export async function fetchIntrusionStatus(): Promise<IIntrusion[]> {
  const response = await fetch(`${apiURL}/intrusion/status`)
  const intrusions = await response.json()
  return intrusions
}
