import { IUser, IUserLocation } from '../model/IAuthState'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export function getCurrentUser(): Promise<IUser> {
  if (!localStorage.getItem('ACCESS_TOKEN')) {
    return Promise.reject('No access token set.')
  }

  return request({ url: `${apiURL}/user/me` })
}

export async function updateUserLocation(location: IUserLocation) {
  return request({
    method: 'POST',
    body: JSON.stringify(location),
    url: `${apiURL}/users/location`,
  })
}

export async function getUserLastLocation() {
  return request({
    url: `${apiURL}/users/location/`,
  })
}

export async function fetchUsers(userLevel: string) {
  return request({ url: `${apiURL}/user/getUsers/${userLevel}` })
}

export async function fetchCampaignUsers() {
  return request({ url: `${apiURL}/user/campaignUsers` })
}

export async function fetchUser(email: string) {
  return request({ url: `${apiURL}/user/getUser/${email}` })
}

export async function fetchUsersByDomain(domain: string) {
  return request({ url: `${apiURL}/user/getUsersByDomain/${domain}` })
}

export async function updateUserRole(email: string, role: string) {
  return request({
    body: JSON.stringify({ email, role }),
    method: 'POST',
    url: `${apiURL}/user/changeUserRole`,
  })
}

export async function updateUserDomain(email: string, domain: string[]) {
  return request({
    body: JSON.stringify(domain),
    method: 'POST',
    url: `${apiURL}/user/changeUserDomain/${email}`,
  })
}

export async function removeUser(email: string) {
  return request({
    method: 'DELETE',
    url: `${apiURL}/user/${email}`,
  })
}

export function renderPermission(role: string) {
  switch (role) {
    case 'L0':
      return 'L0 (SuperAdmin)'
    case 'L1':
      return 'L1 (Admin)'
    case 'L2':
      return 'L2 (Supervisor)'
    case 'L3':
      return 'L3 (Member)'
    case 'L4':
      return 'L4 (Unregistered)'
    default:
      return role
  }
}
