import IChatMessage from '../model/IChatMessage'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export async function fetchChatMessages(groupName: string) {
  return request({
    url: `${apiURL}/chat/${groupName}`,
  })
}

export async function fetchChatMessagesBeforeDate(groupName: string, timestamp: Date) {
  const dt = new Date(timestamp)
  const dateValue = Math.floor(dt.getTime())
  return request({
    url: `${apiURL}/chat/${groupName}/${dateValue}`,
  })
}

export async function sendChatMessage(message: IChatMessage) {
  return request({
    method: 'POST',
    body: JSON.stringify(message),
    url: `${apiURL}/chat`,
  })
}

export async function removeChatMessage(message: IChatMessage) {
  return request({
    method: 'DELETE',
    body: JSON.stringify(message),
    url: `${apiURL}/chat`,
  })
}

export async function sendCatlChatMessage(message: IChatMessage) {
  return request({
    method: 'POST',
    body: JSON.stringify(message),
    url: `${apiURL}/chat/catl`,
  })
}
