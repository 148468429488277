import { Message } from '@stomp/stompjs'
import React, { Component } from 'react'
import 'react-notifications/lib/notifications.css'
import { connect } from 'react-redux'
import IAisShip from '../../model/IAisShip'
import IAnnotation from '../../model/IAnnotations'
import IAsset, { IAssetPayload } from '../../model/IAsset'
import IAssetState from '../../model/IAssetState'
import UserState, { getUserDomain, isL0, isL1, isL2, isL3, isL4, IUser, IUserLocation } from '../../model/IAuthState'
import IContact from '../../model/IContact'
import IGeoLayer from '../../model/IGeoLayer'
import ILatLng from '../../model/ILatLng'
import IMapElement from '../../model/IMapElement'
import IMyMap from '../../model/IMyMap'
import IObstacle from '../../model/IObstacles'
import IOverlayInfo from '../../model/IOverlayInfo'
import IPlan, { isPlanEqual } from '../../model/IPlan'
import IPollution from '../../model/IPollution'
import IPollutionSample from '../../model/IPollutionSample'
import IProfile from '../../model/IProfile'
import IRipplesState from '../../model/IRipplesState'
import IVehicleParams from '../../model/IVehicleParams'
import { ILogbook } from '../../model/MyLogbook'
import {
  addAnnotation,
  addNewPlan,
  cancelEditPlan,
  editPlan,
  savePlan,
  deleteSelectedPlan,
  setAis,
  setAnnotations,
  setCcus,
  setGeoLayers,
  setMapOverlayInfo,
  setPlans,
  setProfiles,
  setSlider,
  setSpots,
  setUser,
  setVehicles,
  toggleSliderChange,
  toggleSlider,
  toggleVehicleModal,
  updateAIS,
  updateCCU,
  updatePlan,
  updateSpot,
  updateUserLocation,
  updateVehicle,
  setPollution,
  updatePollution,
  setObstacle,
  updateObstacle,
  setPollutionSample,
  updatePollutionSample,
  setContacts,
  updateContacts,
  setMapElements,
  updateMapElements,
  setAircraft,
  setServicesAvailable,
  setCampaign,
  updateIntrusions,
} from '../../redux/ripples.actions'
import AISService from '../../services/AISUtils'
import ContactService from '../../services/ContactUtils'
import GeoLayerService from '../../services/GeoLayerService'
import KMLService from '../../services/KMLService'
import LogbookService from '../../services/LogbookUtils'
import MapElementService from '../../services/MapElementUtils'
import PollutionService from '../../services/PollutionUtils'
import SoiService from '../../services/SoiUtils'
import { getCurrentUser, renderPermission } from '../../services/UserUtils'
import WSService from '../../services/WebSocketService'
import RipplesMap from './components/RipplesMap'
import SidePanel from './components/SidePanel'
import Slider from './components/Slider'
import TopNav from './components/TopNav'
import Sidebar from './components/Sidebar'
import './styles/Ripples.css'
import IAircraft from '../../model/IAircraft'
import AircraftService from '../../services/AircraftUtils'
import LocalStorageService from '../../services/LocalStorageService'
import { IServicesDefinition } from '../../model/IService'
import { fetchServicesDefinitionByCampaign, isIntrusionServiceAvailable } from '../../services/ServicesUtils'
import ICampaign from '../../model/ICampaign'
import { fetchCampaignByName } from '../../services/CampaignUtils'
import IIntrusion, { IMapElementIntrusion } from '../../model/IIntrusion'
const { NotificationManager } = require('react-notifications')

interface StateType {
  loading: boolean
  myMaps: IMyMap[]
  geoServerAddr?: string
  preferenceSymbolType: string
  preferenceCoordsDisplayFormat: string
}

interface PropsType {
  plans: IPlan[]
  selectedPlan: IPlan
  sliderValue: number
  auth: UserState
  vehicles: IAsset[]
  vehicleSelected: string
  vehicleSelectedLastState: IAssetState | null
  planSelectedPosition: ILatLng | null
  mapOverlayInfo: IOverlayInfo
  isSliderVisible: boolean
  isDarkMode: boolean
  aircrafts: IAircraft[]
  aisShips: IAisShip[]
  servicesAvailable: IServicesDefinition[]
  campaignSelected: ICampaign | null
  setVehicles: (_: IAsset[]) => void
  setSpots: (_: IAsset[]) => void
  setCcus: (_: IAsset[]) => void
  setAis: (_: IAisShip[]) => void
  setPlans: (_: IPlan[]) => void
  setSlider: (_: number) => void
  editPlan: (_: IPlan) => void
  addNewPlan: (_: IPlan) => void
  setProfiles: (profiles: IProfile[]) => any
  setUser: (user: IUser) => any
  savePlan: () => void
  deleteSelectedPlan: () => void
  cancelEditPlan: () => void
  updateAIS: (s: IAisShip) => void
  updateVehicle: (v: IAsset) => void
  updateCCU: (ccu: IAsset) => void
  updateSpot: (spot: IAsset) => void
  updatePlan: (p: IPlan) => void
  addAnnotation: (a: IAnnotation) => void
  setAnnotations: (a: IAnnotation[]) => void
  updateUserLocation: (u: IUserLocation) => void
  toggleVehicleModal: () => void
  toggleSliderChange: () => void
  toggleSlider: () => void
  setMapOverlayInfo: (m: string) => void
  setGeoLayers: (layers: IGeoLayer[]) => void
  setPollution: (_: IPollution[]) => void
  updatePollution: (p: IPollution) => void
  setObstacle: (_: IObstacle[]) => void
  updateObstacle: (o: IObstacle) => void
  setPollutionSample: (_: IPollutionSample[]) => void
  updatePollutionSample: (s: IPollutionSample) => void
  setContacts: (_: IContact[]) => void
  updateContacts: (c: IContact) => void
  setMapElements: (_: IMapElement[]) => void
  updateMapElements: (e: IMapElement) => void
  setAircraft: (_: IAircraft[]) => void
  setServicesAvailable: (_: IServicesDefinition[]) => void
  setCampaign: (_: ICampaign | null) => void
  updateIntrusions: (i: IMapElementIntrusion) => void
}

interface LayerInfo {
  name: string
  description: string
  units: string
  lowerValue: number
  higherValue: number
  isLog: boolean
}

interface LayerSettings {
  name: string
  group: string
  legendStyle?: string
  opacity?: number
  units?: string
  lowerValue?: number
  higherValue?: number
  isLog?: boolean
  activeLayer?: string
  subLayers?: Map<string, LayerInfo>
  fileName?: string
  layerName?: string
}

class Ripples extends Component<PropsType, StateType> {
  public soiTimer: number = 0
  public aisTimer: number = 0
  public aircraftTimer: number = 0
  private webSocketsService: WSService = new WSService()
  private aisService: AISService = new AISService()
  private aircraftService: AircraftService = new AircraftService()
  private kmlService: KMLService = new KMLService()
  private geoLayerService: GeoLayerService = new GeoLayerService()
  private soiService: SoiService = new SoiService()
  private logbookService: LogbookService = new LogbookService()
  private pollutionService: PollutionService = new PollutionService()
  private contactService: ContactService = new ContactService()
  private mapElementsService: MapElementService = new MapElementService()
  private localStorageService: LocalStorageService = new LocalStorageService()

  private sidebar: Sidebar = new Sidebar()

  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      myMaps: [],
      preferenceSymbolType: 'normal',
      preferenceCoordsDisplayFormat: 'DD',
    }
    this.handleCancelEditPlan = this.handleCancelEditPlan.bind(this)
    this.handleEditPlan = this.handleEditPlan.bind(this)
    this.handleSavePlan = this.handleSavePlan.bind(this)
    this.handleDeletePlan = this.handleDeletePlan.bind(this)
    this.onSliderChange = this.onSliderChange.bind(this)
    this.handleSendPlanToVehicle = this.handleSendPlanToVehicle.bind(this)
    this.handleUpdatePlanId = this.handleUpdatePlanId.bind(this)
    this.stopUpdates = this.stopUpdates.bind(this)
    this.startUpdates = this.startUpdates.bind(this)
    this.updateSoiData = this.updateSoiData.bind(this)
    this.updateAISData = this.updateAISData.bind(this)
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.handleWsAssetUpdate = this.handleWsAssetUpdate.bind(this)
    this.handleWsAISUpdate = this.handleWsAISUpdate.bind(this)
    this.handleWsAnnotationUpdate = this.handleWsAnnotationUpdate.bind(this)
    this.handleWsUserLocation = this.handleWsUserLocation.bind(this)
    this.handleWsVehicleParams = this.handleWsVehicleParams.bind(this)
    this.onSettingsClick = this.onSettingsClick.bind(this)
    this.updateAssetsData = this.updateAssetsData.bind(this)
    this.updatePollutionData = this.updatePollutionData.bind(this)
    this.handleWsPollutionUpdate = this.handleWsPollutionUpdate.bind(this)
    this.updateObstacleData = this.updateObstacleData.bind(this)
    this.handleWsObstacleUpdate = this.handleWsObstacleUpdate.bind(this)
    this.updatePollutionSampleData = this.updatePollutionSampleData.bind(this)
    this.handleWsPollutionSampleUpdate = this.handleWsPollutionSampleUpdate.bind(this)
    this.updateContactsData = this.updateContactsData.bind(this)
    this.addLayers = this.addLayers.bind(this)
    this.handleWsContactsUpdate = this.handleWsContactsUpdate.bind(this)
    this.updateMapElementsData = this.updateMapElementsData.bind(this)
    this.handleWsMapElementsUpdate = this.handleWsMapElementsUpdate.bind(this)
    this.handleWsIntrusionUpdate = this.handleWsIntrusionUpdate.bind(this)
    this.updateAircraftData = this.updateAircraftData.bind(this)
    this.aircraftToAsset = this.aircraftToAsset.bind(this)
    this.aisShipToAsset = this.aisShipToAsset.bind(this)
    this.fetchLocalStorage = this.fetchLocalStorage.bind(this)
    this.fetchServicesAvailable = this.fetchServicesAvailable.bind(this)
    this.fetchCampaignSelected = this.fetchCampaignSelected.bind(this)
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
      if (isL4(this.props.auth)) {
        NotificationManager.info('Waiting for validation: ' + user.email)
      } else {
        NotificationManager.info(`${renderPermission(user.role)}: ${user.email}`)
      }
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    const myMaps = await this.loadMyMapsData()
    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      const geoLayers = await this.loadGeoLayers()
      this.props.setGeoLayers(geoLayers)
    }
    this.webSocketsService.createWSClient()
    this.webSocketsService.subscribeWSUpdates(
      this.handleWsAssetUpdate,
      this.handleWsAISUpdate,
      this.handleWsAnnotationUpdate,
      this.handleWsUserLocation,
      this.handleWsVehicleParams,
      this.handleWsPollutionUpdate,
      this.handleWsObstacleUpdate,
      this.handleWsPollutionSampleUpdate,
      this.handleWsContactsUpdate,
      this.handleWsIntrusionUpdate
    )
    this.setState({ myMaps })
    this.setState({ loading: false })
    this.updatePollutionData()
    this.updateObstacleData()
    this.updatePollutionSampleData()
    this.updateContactsData()
    this.updateMapElementsData()
    this.fetchLocalStorage()
    this.fetchCampaignSelected()
    this.fetchServicesAvailable()
    this.startUpdates()
    const sidebar = Array.from(
      document.getElementsByClassName('leaflet-control-layers') as HTMLCollectionOf<HTMLElement>
    )
    if (sidebar !== null) sidebar[0].style.display = 'none'
  }

  private toggleDarkModeElement(className: string, darkmodeClass: string) {
    const elements = Array.from(document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>)
    elements.forEach((element) => {
      if (element.className.includes(darkmodeClass) && !this.props.isDarkMode) {
        element.className = element.className.split(darkmodeClass)[0]
      } else if (!element.className.includes(darkmodeClass) && this.props.isDarkMode) {
        element.className = element.className + ' ' + darkmodeClass
      }
    })
  }

  public componentDidUpdate(): void {
    this.toggleDarkModeElement('leaflet-control-layers', 'darkmode')
    this.toggleDarkModeElement('leaflet-control-zoom-in', 'darkmode-button')
    this.toggleDarkModeElement('leaflet-control-zoom-out', 'darkmode-button')
    this.toggleDarkModeElement('leaflet-control-fullscreen-button', 'darkmode-button')
    this.toggleDarkModeElement('leaflet-draw-draw-polyline', 'darkmode-button')
    this.toggleDarkModeElement('leaflet-draw-draw-polygon', 'darkmode-button')
    this.toggleDarkModeElement('leaflet-draw-draw-rectangle', 'darkmode-button')
    this.toggleDarkModeElement('leaflet-draw-edit-edit', 'darkmode-button')
    this.toggleDarkModeElement('leaflet-draw-edit-remove', 'darkmode-button')
    this.toggleDarkModeElement('ripples-tools', 'darkmode-button')
    this.toggleDarkModeElement('ripples-tools-nologin', 'darkmode-button')
  }

  public addLayers(layer: LayerSettings) {
    let exists = false
    this.sidebar.ripplesLayers.forEach((element) => {
      if (element.name === layer.name) {
        exists = true
      }
    })
    if (!exists) this.sidebar.ripplesLayers.set(layer.name, layer)
  }

  public handleWsAnnotationUpdate(m: Message) {
    if (m.body) {
      const annotation: IAnnotation = JSON.parse(m.body)
      this.props.addAnnotation(annotation)
    }
  }

  public handleWsAISUpdate(m: Message) {
    if (m.body) {
      const aisShipPayload: IAisShip = JSON.parse(m.body)
      const aisShip = this.aisService.convertAISToRipples(aisShipPayload)
      this.props.updateAIS(aisShip)
    }
  }

  public handleWsAssetUpdate(m: Message) {
    if (m.body) {
      const userDomain = getUserDomain(this.props.auth)

      const newSystem: IAssetPayload = JSON.parse(m.body)
      const system: IAsset = this.soiService.convertAssetPayloadToAsset(newSystem)

      if (userDomain !== undefined) {
        // asset without domain
        if (system.domain.length === 0) {
          if (system.name.toLowerCase().startsWith('spot')) {
            this.props.updateSpot(system)
          } else if (system.name.toLowerCase().startsWith('ccu')) {
            this.props.updateCCU(system)
          } else if (!this.soiService.isRipplesImc(newSystem)) {
            this.props.updateVehicle(system)
          }

          if (newSystem.plan) {
            if (newSystem.plan.waypoints.length > 0) {
              const plan: IPlan = this.soiService.convertAssetPayloadToPlan(newSystem)
              this.props.updatePlan(plan)
            }
          }
        }

        userDomain.forEach((domain) => {
          if (system.domain.includes(domain)) {
            if (system.name.startsWith('spot')) {
              this.props.updateSpot(system)
            } else if (system.name.startsWith('ccu')) {
              this.props.updateCCU(system)
            } else if (!this.soiService.isRipplesImc(newSystem)) {
              this.props.updateVehicle(system)
            }

            if (newSystem.plan) {
              if (newSystem.plan.waypoints.length > 0) {
                const plan: IPlan = this.soiService.convertAssetPayloadToPlan(newSystem)
                this.props.updatePlan(plan)
              }
            }
            return
          }
        })
      }
    }
  }

  public handleWsUserLocation(m: Message) {
    if (m.body) {
      const location: IUserLocation = JSON.parse(m.body)
      this.props.updateUserLocation(location)
    }
  }

  public handleWsVehicleParams(m: Message) {
    if (m.body) {
      const vehicleParams: IVehicleParams = JSON.parse(m.body)
      const index = this.props.vehicles.findIndex((v: IAsset) => v.name === vehicleParams.name)
      if (index === -1) {
        return
      }
      const vehicleCopy = JSON.parse(JSON.stringify(this.props.vehicles[index]))
      vehicleCopy.settings = Object.entries(vehicleParams.params)
      this.props.updateVehicle(vehicleCopy)
    }
  }

  public handleWsPollutionUpdate(p: Message) {
    if (p.body) {
      const pollutionPayload: IPollution = JSON.parse(p.body)
      // update redux
      this.props.updatePollution(pollutionPayload)
    }
  }

  public handleWsObstacleUpdate(o: Message) {
    if (o.body) {
      const obstaclePayload: IObstacle = JSON.parse(o.body)
      // update redux
      this.props.updateObstacle(obstaclePayload)
    }
  }

  public handleWsPollutionSampleUpdate(s: Message) {
    if (s.body) {
      const samplePayload: IPollutionSample = JSON.parse(s.body)
      // update redux
      this.props.updatePollutionSample(samplePayload)
    }
  }

  public handleWsContactsUpdate(s: Message) {
    if (s.body) {
      const contactPayload: IContact = JSON.parse(s.body)
      // update redux
      this.props.updateContacts(contactPayload)
    }
  }

  public handleWsMapElementsUpdate(e: Message) {
    if (e.body) {
      const mapElementPayload: IMapElement = JSON.parse(e.body)
      // update redux
      this.props.updateMapElements(mapElementPayload)
    }
  }

  public handleWsIntrusionUpdate(e: Message) {
    if (isIntrusionServiceAvailable(this.props.servicesAvailable)) {
      if (e.body) {
        const mapIntrusionPayload: IIntrusion = JSON.parse(e.body)

        // MapElement intrusion
        const mapElementIntrusion: IMapElementIntrusion = {
          mapElementLabel: mapIntrusionPayload.mapElementLabel,
          type: mapIntrusionPayload.type,
        }
        // update redux
        this.props.updateIntrusions(mapElementIntrusion)
      }
    }
  }

  public stopUpdates() {
    clearInterval(this.soiTimer)
    clearInterval(this.aisTimer)
    clearInterval(this.aircraftTimer)
    this.webSocketsService.deactivate()
  }

  public startUpdates() {
    this.updateSoiData()
    this.updateAISData()
    this.updateAircraftData()
    if (!this.soiTimer) {
      this.soiTimer = window.setInterval(this.updateSoiData, 60000)
    }
    if (!this.aisTimer) {
      this.aisTimer = window.setInterval(this.updateAISData, 60000) // get ais data every minute
    }
    if (!this.aircraftTimer) {
      this.aircraftTimer = window.setInterval(this.updateAircraftData, 60000) // get aircraft data every minute
    }
    setInterval(this.updateContactsData, 60000) // get contacts every minute
    setInterval(this.updateMapElementsData, 60000) // get mapElements every minute
    /* Temporarily deactivated
    this.fetchAllAnnotations()
    */
    this.webSocketsService.activate()
  }

  public async fetchAllAnnotations() {
    try {
      const logbook: ILogbook = await this.logbookService.fetchLogbook()
      this.props.setAnnotations(logbook.annotations)
    } catch (e) {
      this.props.setAnnotations([])
    }
  }

  public componentWillUnmount() {
    this.stopUpdates()
  }

  public async loadMyMapsData(): Promise<IMyMap[]> {
    let userDomain: string[] = []
    if (this.props.auth.currentUser.email) {
      userDomain = this.props.auth.currentUser.domain
    } else {
      userDomain.push('null')
    }

    const mapNames: string[] = await this.kmlService.fetchMapsNamesByDomain(userDomain)
    const maps = Promise.all(
      mapNames.map(async (mapName) => {
        const mapData = await this.kmlService.fetchMapData(mapName)
        return { name: mapName, data: mapData }
      })
    )
    return await maps
  }

  public async loadGeoLayers() {
    if (!this.state.geoServerAddr) {
      const geoServer = await this.geoLayerService.fetchGeoServerAddr()
      this.setState({ geoServerAddr: geoServer.url })
    }
    return await this.geoLayerService.fetchGeoLayers()
  }

  public async updateSoiData() {
    try {
      let userDomain = getUserDomain(this.props.auth)
      if (userDomain === undefined) {
        userDomain = ['undefined']
      }
      const soiPromise = this.soiService.fetchSoiData(userDomain)
      const profilesPromise = this.soiService.fetchProfileData()
      const awarenessPromise = this.soiService.fetchAwareness()
      let unassignedPlansPromise
      if (isL0(this.props.auth) || isL1(this.props.auth) || isL2(this.props.auth) || isL3(this.props.auth)) {
        unassignedPlansPromise = this.soiService.fetchUnassignedPlans()
      }
      const soiData = await soiPromise

      const vehicles = soiData.vehicles

      /* Temporarily deactivated
      await this.soiService.fetchSoiSettings([vehicles, spots, ccus])
      await this.soiService.mergeAssetSettings(vehicles, this.props.auth)
      */

      // fetch profiles
      let profiles = await profilesPromise
      profiles = profiles.filter((p) => p.samples.length > 0)
      // make heights symmetric
      /*
      profiles.forEach((p) => {
        p.samples.forEach((a) => (a[0] = -a[0]))
      })
      */
      this.props.setProfiles(profiles)

      // fetch soi awareness
      const assetsAwareness = await awarenessPromise
      assetsAwareness.forEach((assetAwareness) => {
        const vehicle = vehicles.find((v) => v.name === assetAwareness.name)
        if (vehicle) {
          vehicle.awareness = assetAwareness.positions
        }
      })

      if (unassignedPlansPromise) {
        const unassignedPlans: IPlan[] = await unassignedPlansPromise
        soiData.plans = [] // Assigned plans are present in unassignedPlans
        soiData.plans = soiData.plans.concat(unassignedPlans)
      }

      // avoid duplicated plans
      const parsedPlans: IPlan[] = []
      let sortedPlans: IPlan[] = []
      soiData.plans.forEach((plan) => {
        // Check previous plan visibility
        this.props.plans.forEach((p) => {
          if (p.id === plan.id) {
            plan.visible = p.visible
          }
        })

        if (parsedPlans.length === 0) {
          parsedPlans.push(plan)
        } else {
          let insertPlan = true
          parsedPlans.forEach((planAux) => {
            const planWp = plan.waypoints
            const planAuxWp = planAux.waypoints
            if (
              plan.id === planAux.id &&
              /*plan.assignedTo === planAux.assignedTo &&*/
              JSON.stringify(planWp) === JSON.stringify(planAuxWp)
            ) {
              insertPlan = false
            }
          })
          if (insertPlan) {
            parsedPlans.push(plan)
          }
          // sort plans
          sortedPlans = parsedPlans.sort((p1, p2) => {
            const planName1 = p1.id.toUpperCase()
            const planName2 = p2.id.toUpperCase()
            if (planName1 < planName2) {
              return -1
            }
            if (planName1 > planName2) {
              return 1
            }
            return 0
          })
        }
      })

      // update redux store
      this.props.setVehicles(soiData.vehicles)
      this.props.setSpots(soiData.spots)
      // this.props.setPlans(soiData.plans)
      this.props.setPlans(sortedPlans)
      this.props.setCcus(soiData.ccus)
    } catch (error) {
      NotificationManager.warning('Failed to fetch data')
    }
  }

  public async updateAISData() {
    const shipsData: IAisShip[] = await this.aisService.fetchAisData()
    // update redux store
    this.props.setAis(shipsData)
  }

  public async updateAircraftData() {
    const aircraftsData: IAircraft[] = await this.aircraftService.fetchAircraftData()
    // update redux store
    this.props.setAircraft(aircraftsData)
  }

  public aircraftToAsset(aircraft: IAircraft) {
    const index = this.props.aircrafts.map((a) => a.hex).indexOf(aircraft.hex)
    let aircraftsDataUpdated: IAircraft[] = this.props.aircrafts
    aircraftsDataUpdated = [...aircraftsDataUpdated.slice(0, index), ...aircraftsDataUpdated.slice(index + 1)]

    // update redux store
    this.props.setAircraft(aircraftsDataUpdated)

    // update assets
    this.updateSoiData()
  }

  public aisShipToAsset(aisShip: IAisShip) {
    const index = this.props.aisShips.map((ship) => ship.mmsi).indexOf(aisShip.mmsi)
    let aisShipDataUpdated: IAisShip[] = this.props.aisShips
    aisShipDataUpdated = [...aisShipDataUpdated.slice(0, index), ...aisShipDataUpdated.slice(index + 1)]

    // update redux store
    this.props.setAis(aisShipDataUpdated)

    // update assets
    this.updateSoiData()
  }

  public async updateAssetsData(system: IAsset, domain: string[]) {
    try {
      const newSystem: IAsset = await this.soiService.updateAssetDomain(system, domain)
      const response = await this.soiService.updateAssetDB(newSystem)

      if (response.status === 'Success') {
        NotificationManager.success(response.message)
        this.updateSoiData()
      } else {
        NotificationManager.warning('Failed to update asset domain')
      }
    } catch (error) {
      NotificationManager.warning('Failed to update asset domain')
    }
  }

  public async updatePollutionData() {
    const pollutionData: IPollution[] = await this.pollutionService.fetchPollutionData()
    // update redux store
    this.props.setPollution(pollutionData)
  }

  public async updateObstacleData() {
    const obstacleData: IObstacle[] = await this.pollutionService.fetchObstaclesData()
    // update redux store
    this.props.setObstacle(obstacleData)
  }

  public async updatePollutionSampleData() {
    const pollutionSampleData: IPollutionSample[] = await this.pollutionService.fetchPollutionSamplesData()
    // update redux store
    this.props.setPollutionSample(pollutionSampleData)
  }

  public async updateContactsData() {
    const contactData: IContact[] = await this.contactService.fetchContactData()
    // update redux store
    this.props.setContacts(contactData)
  }

  public async updateMapElementsData() {
    const mapElementsData: IMapElement[] = await this.mapElementsService.fetchMapElementData()

    // TODO - fetch MapElements with campaign
    // const mapElementsData: IMapElement[] = await this.mapElementsService.fetchMapElementDataByCampaign('RIPPLES')

    // update redux store
    this.props.setMapElements(mapElementsData)
  }

  public fetchLocalStorage() {
    this.setState({
      preferenceSymbolType: this.localStorageService.getSymbolsType(),
      preferenceCoordsDisplayFormat: this.localStorageService.getCoordinatesFormat(),
    })
  }

  public async fetchServicesAvailable() {
    const servicesAvailable: IServicesDefinition[] = await fetchServicesDefinitionByCampaign(
      this.localStorageService.getCampaignSelected()
    )
    // update redux store
    this.props.setServicesAvailable(servicesAvailable)
  }

  public async fetchCampaignSelected() {
    const campaignSelected: ICampaign = await fetchCampaignByName(
      this.localStorageService.getCampaignSelected(),
      this.props.auth.currentUser.email
    )

    // update local storage if needed
    if (this.localStorageService.getCampaignSelected() !== campaignSelected.name) {
      this.localStorageService.setCampaignSelected(campaignSelected.name)
    }

    // update redux store
    this.props.setCampaign(campaignSelected)
  }

  public handleEditPlan = (p: IPlan) => {
    this.props.editPlan(p)
    this.stopUpdates()
  }

  public async handleSendPlanToVehicle() {
    try {
      const plan: IPlan | undefined = this.props.plans.find((p) => isPlanEqual(p, this.props.selectedPlan))
      const vehicle = this.props.vehicleSelected
      if (!plan) {
        return
      }
      const body = await this.soiService.sendPlanToVehicle(plan, vehicle)
      NotificationManager.success(body.message)
      this.startUpdates()
    } catch (error) {
      NotificationManager.warning(error.message)
      this.handleCancelEditPlan()
    }
  }

  public handleCancelEditPlan() {
    this.props.cancelEditPlan()
    this.startUpdates()
  }

  public async handleSavePlan() {
    // send plan to server
    const plan: IPlan | undefined = this.props.plans.find((p) => isPlanEqual(p, this.props.selectedPlan))
    if (plan) {
      try {
        const response = await this.soiService.sendUnassignedPlan(plan)
        this.startUpdates()
        this.props.savePlan()
        NotificationManager.success(response.message)
      } catch (error) {
        NotificationManager.warning(error.message)
      }
    }
  }

  public async handleUpdatePlanId(previousId: string, newId: string) {
    try {
      await this.soiService.updatePlanId(previousId, newId)
      NotificationManager.success(`Plan id has been updated`)
    } catch (error) {
      NotificationManager.warning(error.message)
    }
  }

  public async handleDeletePlan() {
    try {
      await this.soiService.deleteUnassignedPlan(this.props.selectedPlan.id)
      NotificationManager.success(`Plan ${this.props.selectedPlan.id} has been deleted`)
    } catch (error) {
      NotificationManager.warning(error.message)
    } finally {
      this.props.deleteSelectedPlan() // used to deselect the plan
      this.startUpdates()
    }
  }

  public onSliderChange(sliderValue: number) {
    if (sliderValue === 0) {
      // reset state
      this.startUpdates()
    } else {
      this.stopUpdates()
    }
    this.props.setSlider(sliderValue)
    this.props.toggleSliderChange()
    this.props.setMapOverlayInfo(this.props.mapOverlayInfo.name)
  }

  public onSettingsClick() {
    this.props.toggleVehicleModal()
  }

  public render() {
    if (!this.state.loading) {
      return (
        <div>
          <div className={this.props.isDarkMode ? 'navbar darkmode' : 'navbar'}>
            <TopNav
              handleEditPlan={this.handleEditPlan}
              handleSendPlanToVehicle={this.handleSendPlanToVehicle}
              handleCancelEditPlan={this.handleCancelEditPlan}
              handleSavePlan={this.handleSavePlan}
              handleDeletePlan={this.handleDeletePlan}
              handleUpdatePlanId={this.handleUpdatePlanId}
            />
          </div>
          <RipplesMap
            myMaps={this.state.myMaps}
            geoServerAddr={this.state.geoServerAddr}
            onSettingsClick={this.onSettingsClick}
            updateAssets={this.updateAssetsData}
            setPollutionMarkers={this.updatePollutionData}
            setObstacles={this.updateObstacleData}
            setContactMarkers={this.updateContactsData}
            sidebar={this.sidebar}
            addLayer={this.addLayers}
            layersSettings={this.sidebar.ripplesLayers}
            preferenceSymbolType={this.state.preferenceSymbolType}
            preferenceCoordsDisplayFormat={this.state.preferenceCoordsDisplayFormat}
          />

          <SidePanel
            onSettingsClick={this.onSettingsClick}
            aircraftToAsset={this.aircraftToAsset}
            aisShipToAsset={this.aisShipToAsset}
          />

          {this.props.isSliderVisible && (
            <Slider onChange={this.onSliderChange} min={-48} max={48} value={this.props.sliderValue} />
          )}

          {!this.props.isSliderVisible && (
            <div className="slider closed">
              <i
                id="sliderToggle"
                title={`Toggle slider`}
                className="fas fa-angle-double-up fa-lg"
                onClick={this.props.toggleSlider}
              />
            </div>
          )}
        </div>
      )
    }

    return <></>
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    plans: state.planSet,
    selectedPlan: state.selectedPlan,
    sliderValue: state.sliderValue,
    vehicles: state.assets.vehicles,
    vehicleSelected: state.vehicleSelected,
    vehicleSelectedLastState: state.vehicleSelectedLastState,
    planSelectedPosition: state.planSelectedPosition,
    mapOverlayInfo: state.mapOverlayInfo,
    isSliderVisible: state.isSliderVisible,
    isDarkMode: state.isDarkMode,
    aircrafts: state.assets.aircrafts,
    aisShips: state.assets.aisShips,
    servicesAvailable: state.servicesAvailable,
    campaignSelected: state.campaignSelected,
  }
}

const actionCreators = {
  addAnnotation,
  addNewPlan,
  cancelEditPlan,
  editPlan,
  savePlan,
  deleteSelectedPlan,
  setAis,
  setAnnotations,
  setCcus,
  setMapOverlayInfo,
  setPlans,
  setProfiles,
  setSlider,
  setSpots,
  setUser,
  setVehicles,
  updateVehicle,
  updateSpot,
  updateCCU,
  updatePlan,
  updateAIS,
  updateUserLocation,
  toggleSliderChange,
  toggleVehicleModal,
  setGeoLayers,
  setPollution,
  updatePollution,
  setObstacle,
  updateObstacle,
  setPollutionSample,
  updatePollutionSample,
  setContacts,
  updateContacts,
  toggleSlider,
  setMapElements,
  updateMapElements,
  setAircraft,
  setServicesAvailable,
  setCampaign,
  updateIntrusions,
}

export default connect(mapStateToProps, actionCreators)(Ripples)
