import L, { LatLng } from 'leaflet'
import milsymbol from 'milsymbol'
import React, { Component } from 'react'
import { Marker, Polygon } from 'react-leaflet'
import { connect } from 'react-redux'
import IAisShip from '../../../model/IAisShip'
import IAsset from '../../../model/IAsset'
import IAssetAwareness from '../../../model/IAssetAwareness'
import IRipplesState from '../../../model/IRipplesState'
import {
  setEditVehicle,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
  setToolSelected,
  clearMeasure,
  selectMapElement,
} from '../../../redux/ripples.actions'
import DateService from '../../../services/DateUtils'
import PositionService from '../../../services/PositionUtils'
import AssetAwareness from './AssetAwareness'
import {
  AISGreyIcon,
  AISGreySmallIcon,
  AISPurpleIcon,
  AISPurpleSmallIcon,
  AISPurpleTinyIcon,
  AISRedIcon,
  AISRedSmallIcon,
  AISOrangeIcon,
  AISOrangeSmallIcon,
  AISPurpleIconSelected,
} from './Icons'
import RotatedMarker from './RotatedMarker'
import { ToolSelected } from '../../../model/ToolSelected'
import IMapElement from '../../../model/IMapElement'

interface PropsType {
  isToDrawAISPolygons: boolean
  ship: IAisShip
  sliderValue: number
  currentTime: number
  isAISLayerActive: boolean
  currentZoom: number
  isHighlighted: boolean | undefined
  opacity: number
  symbolType: string
  coordsDisplayFormat: string
  updateHighlight: (ship: any) => void
  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
  setEditVehicle: (v: IAsset | undefined) => void
  sidePanelVerification: () => void
  setToolSelected: (_: ToolSelected) => void
  selectMapElement: (_: IMapElement | null) => void
  clearMeasure: () => void
}

class AISShip extends Component<PropsType, {}> {
  public awarenessMinSpeed: number = 0.2
  public icon = new AISRedIcon()
  public smallIcon = new AISRedSmallIcon()
  public purpleIcon = new AISPurpleIcon()
  public purpleIconSelected = new AISPurpleIconSelected()
  public purpleSmallIcon = new AISPurpleSmallIcon()
  public purpleTinyIcon = new AISPurpleTinyIcon()
  public redIcon = new AISRedIcon()
  public redSmallIcon = new AISRedSmallIcon()
  public orangeIcon = new AISOrangeIcon()
  public orangeSmallIcon = new AISOrangeSmallIcon()
  public awarenessIcon = new AISGreyIcon()
  public awawarenessSmallIcon = new AISGreySmallIcon()
  private positionService: PositionService = new PositionService()

  constructor(props: PropsType) {
    super(props)
    this.onShipClick = this.onShipClick.bind(this)
  }

  public shouldComponentUpdate(nextProps: PropsType) {
    return (
      this.props.isAISLayerActive &&
      (this.props.ship.latitude !== nextProps.ship.latitude ||
        this.props.ship.longitude !== nextProps.ship.longitude ||
        this.props.sliderValue !== nextProps.sliderValue ||
        this.props.currentZoom !== nextProps.currentZoom ||
        this.props.isHighlighted !== nextProps.isHighlighted)
    )
  }

  public getOpacity(lastUpdate: number) {
    const deltaTimeSec = Math.round((Date.now() - lastUpdate) / 1000)
    return 0.36 + (1.0 - 0.36) / (1 + Math.pow(deltaTimeSec / 8000, 0.9))
  }

  public buildShipAwareness() {
    if (this.props.ship.timestamp > Date.now() - 5000) {
      return <></>
    }
    const deltaHours = this.props.sliderValue
    const awareness: IAssetAwareness = {
      name: this.props.ship.name,
      positions: this.props.ship.awareness,
    }
    let vehicleIcon
    const iconSize = Math.min(22, Math.max(5, 2 + this.props.currentZoom * 1.5))
    if (this.props.sliderValue === 0) {
      this.awarenessIcon.options.iconSize = [iconSize, iconSize]
      vehicleIcon = this.awarenessIcon
    } else {
      this.purpleIcon.options.iconSize = [iconSize, iconSize]
      vehicleIcon = this.purpleIcon
    }

    return (
      <AssetAwareness
        awareness={awareness}
        deltaHours={deltaHours}
        icon={vehicleIcon}
        iconAngle={0}
        currentTime={this.props.currentTime}
        zoom={this.props.currentZoom}
        isAIS={true}
      />
    )
  }

  public getDisplayableProperties(ship: IAisShip) {
    const url = 'https://photos.marinetraffic.com/ais/showphoto.aspx?mmsi=' + ship.mmsi.toString()
    const headingReadable = this.positionService.getReadableHeading(ship.heading)

    const shipDestination: string = ship.dest.length > 0 ? ship.dest : 'undefined'

    const properties = {
      'last update': DateService.timeFromNow(ship.timestamp),
      'speed (knots)': ship.sog.toFixed(1),
      length: ship.bow + ship.stern + 'm',
      width: ship.port + ship.starboard + 'm',
      draught: ship.draught + 'm',
      heading: headingReadable,
      latitude: this.positionService.formatCoords(ship.latitude, 'lat', this.props.coordsDisplayFormat),
      longitude: this.positionService.formatCoords(ship.longitude, 'lon', this.props.coordsDisplayFormat),
      destination: shipDestination,
      mmsi: `<a href="https://www.marinetraffic.com/pt/ais/details/ships/${
        ship.mmsi
      }" target="_blank">${ship.mmsi.toString()}</a>`,
      image: `<img class="ship-image" src=${url} alt="No image available"/>`,
    }

    return properties
  }

  public onShipClick(evt: any, ship: IAisShip) {
    evt.originalEvent.view.L.DomEvent.stopPropagation(evt)

    // Clear any tool selection
    this.props.setToolSelected(ToolSelected.NONE)
    this.props.clearMeasure()

    // Clear possible selections
    this.props.selectMapElement(null)

    this.props.setSidePanelTitle(ship.name)
    this.props.setSidePanelContent(this.getDisplayableProperties(ship))
    this.props.setSidePanelVisibility(true)
    this.props.setEditVehicle(undefined)
    this.props.updateHighlight(ship.mmsi)

    this.props.sidePanelVerification()
  }

  public buildAisShipPolygon() {
    const ship = this.props.ship
    const location = ship.location
    const positions = [
      new LatLng(location.bow.latitude, location.bow.longitude),
      new LatLng(location.bowPort.latitude, location.bowPort.longitude),
      new LatLng(location.sternPort.latitude, location.sternPort.longitude),
      new LatLng(location.sternStarboard.latitude, location.sternStarboard.longitude),
      new LatLng(location.bowStarboard.latitude, location.bowStarboard.longitude),
    ]
    if (this.props.sliderValue === 0)
      return (
        <Polygon
          positions={positions}
          color={this.props.isHighlighted ? 'orange' : 'purple'}
          onClick={(e: any) => this.onShipClick(e, ship)}
        />
      )
    else return <Polygon positions={positions} color="#c2a3cc" onClick={(e: any) => this.onShipClick(e, ship)} />
  }

  public buildAisShipMarker() {
    const ship = this.props.ship
    let vehicleOpacity

    this.props.sliderValue === 0
      ? (vehicleOpacity = 1 * this.props.opacity)
      : (vehicleOpacity = 0.2 * this.props.opacity)

    const iconSize = Math.min(22, Math.max(5, 2 + this.props.currentZoom * 1.5))
    this.purpleIcon.options.iconSize = [iconSize, iconSize]
    this.orangeIcon.options.iconSize = [iconSize + 3, iconSize + 3]

    return (
      <RotatedMarker
        position={{ lat: ship.latitude, lng: ship.longitude }}
        zoom={this.props.currentZoom}
        rotationAngle={Math.round(ship.heading !== 511 ? ship.heading : ship.cog)}
        rotationOrigin={'center'}
        icon={this.props.isHighlighted ? this.orangeIcon : this.purpleIcon}
        opacity={vehicleOpacity}
        onClick={(e: any) => this.onShipClick(e, ship)}
      />
    )
  }

  public buildAisShipWithMilitarySymbols() {
    const ship = this.props.ship
    const iconSize = this.props.currentZoom < 9 ? 8 : 12 + this.props.currentZoom / 2
    const mysymbol = new milsymbol.Symbol('SUS-XM------', {
      size: iconSize,
      type: this.props.currentZoom >= 15 ? ship.name.toUpperCase() : '',
      direction: Math.round(ship.heading !== 511 ? ship.heading : ship.cog).toString(),
    })

    const myIcon = L.icon({
      iconUrl: mysymbol.toDataURL(),
      iconAnchor: [mysymbol.getAnchor().x, mysymbol.getAnchor().y],
    })

    return (
      <Marker
        position={{ lat: ship.latitude, lng: ship.longitude }}
        icon={myIcon}
        onClick={(e: any) => this.onShipClick(e, ship)}
      />
    )
  }

  public render() {
    // military symbols
    if (this.props.symbolType === 'military') {
      return <>{this.buildAisShipWithMilitarySymbols()}</>
    } else {
      // normal symbols
      const ship = this.props.ship
      let shipAwareness: JSX.Element | null = null
      if (ship.sog > this.awarenessMinSpeed && ship.awareness.length > 0) {
        shipAwareness = this.buildShipAwareness()
      }

      let shipPolygon: JSX.Element | null = null

      if (this.props.isToDrawAISPolygons) {
        shipPolygon = this.buildAisShipPolygon()
      }
      return (
        <>
          {shipPolygon}
          {this.buildAisShipMarker()}
          {shipAwareness}
        </>
      )
    }
  }
}

const actionCreators = {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  setEditVehicle,
  sidePanelVerification,
  setToolSelected,
  selectMapElement,
  clearMeasure,
}

function mapStateToProps(state: IRipplesState) {
  const { sliderValue } = state
  return {
    sliderValue,
  }
}

export default connect(mapStateToProps, actionCreators)(AISShip)
