export default interface IMapElement {
  label: string
  timestamp: Date
  maxAge: string
  type: string
  user: string
  positions: number[][]
}

export default class IMapElement implements IMapElement {
  constructor(
    public label: string,
    public timestamp: Date,
    public maxAge: string,
    public type: string,
    public user: string,
    public positions: number[][]
  ) {}
}

export enum MapElementType {
  OPERATION,
  INCLUSION,
  EXCLUSION,
  AWARENESS,
  other,
}
