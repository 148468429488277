import React, { Component } from 'react'
import { Marker } from 'react-leaflet'
import milsymbol from 'milsymbol'
import { connect } from 'react-redux'
import {
  selectContact,
  selectMapElement,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
} from '../../../redux/ripples.actions'
import { BlueCircleIcon, QuestionMarkerIcon } from './Icons'
import IContact from '../../../model/IContact'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ContactService from '../../../services/ContactUtils'
import IRipplesState from '../../../model/IRipplesState'
import ILatLng from '../../../model/ILatLng'
import PositionService from '../../../services/PositionUtils'
import IMapElement from '../../../model/IMapElement'
import L from 'leaflet'

const { NotificationManager } = require('react-notifications')

interface PropsType {
  contactMarkers?: IContact[]
  contactSelected: IContact | null
  isDarkMode: boolean
  selectedPosition?: ILatLng | undefined
  coordsDisplayFormat: string
  symbolType: string
  currentZoom: number
  selectContact: (_: IContact | null) => void
  selectMapElement: (_: IMapElement | null) => void
  selectPosition: (enable: boolean) => void
  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
  setContactMarkers: () => void
  sidePanelVerification: () => void
}

interface StateType {
  isContactModalOpen: boolean
  contactInputLabel: string
  contactInputLatitude: number | undefined
  contactInputLongitude: number | undefined
  contactInputState: string
  contactInputUncertanty: number | undefined
  classificationTypesInput: string | undefined
  classificationConfidenceInput: string | undefined
  contactInputTimestamp: Date
}

class Contact extends Component<PropsType, StateType> {
  private contactService: ContactService = new ContactService()
  private positionService: PositionService = new PositionService()

  constructor(props: PropsType) {
    super(props)

    this.state = {
      isContactModalOpen: false,
      contactInputLabel: '',
      contactInputLatitude: undefined,
      contactInputLongitude: undefined,
      contactInputState: '',
      contactInputUncertanty: undefined,
      classificationTypesInput: '',
      classificationConfidenceInput: '',
      contactInputTimestamp: new Date(),
    }

    this.toggleContactModal = this.toggleContactModal.bind(this)
    this.createContact = this.createContact.bind(this)
    this.selectPosition = this.selectPosition.bind(this)
  }

  public shouldComponentUpdate(newProps: PropsType) {
    if (newProps.selectedPosition && newProps.selectedPosition !== this.props.selectedPosition) {
      NotificationManager.success('Position inserted')
      this.setState({
        isContactModalOpen: !this.state.isContactModalOpen,
        contactInputLatitude: newProps.selectedPosition.latitude,
        contactInputLongitude: newProps.selectedPosition.longitude,
      })
    }
    return true
  }

  public buildContacts() {
    if (this.props.contactMarkers) {
      return this.props.contactMarkers.map((contact) => {
        return (
          <Marker
            key={'contact_' + contact.label}
            position={[contact.latitude, contact.longitude]}
            icon={new QuestionMarkerIcon()}
            onClick={(e: any) => this.onContactClick(e, contact)}
          />
        )
      })
    }
  }

  public buildContactsWithMilitarySymbols() {
    if (this.props.contactMarkers) {
      const iconSize = this.props.currentZoom < 9 ? 8 : 12 + this.props.currentZoom / 2

      return this.props.contactMarkers.map((contact) => {
        const mysymbol = new milsymbol.Symbol('SUUPWMC---', {
          size: iconSize + 3,
          uniqueDesignation: this.props.currentZoom >= 14 ? contact.label.toUpperCase() : '',
        })
        const militaryIcon = L.icon({
          iconUrl: mysymbol.toDataURL(),
          iconAnchor: [mysymbol.getAnchor().x, mysymbol.getAnchor().y],
        })
        return (
          <Marker
            key={'contact_' + contact.label}
            position={[contact.latitude, contact.longitude]}
            icon={militaryIcon}
            onClick={(e: any) => this.onContactClick(e, contact)}
          />
        )
      })
    }
  }

  public onContactClick(evt: any, contact: IContact) {
    evt.originalEvent.view.L.DomEvent.stopPropagation(evt)
    this.props.setSidePanelTitle(contact.label)
    this.props.setSidePanelContent({
      Latitude: this.positionService.formatCoords(contact.latitude, 'lat', this.props.coordsDisplayFormat),
      Longitude: this.positionService.formatCoords(contact.longitude, 'lon', this.props.coordsDisplayFormat),
      Timestamp: contact.timestamp,
      State: contact.state,
      Uncertanty: contact.uncertainty.toFixed(1),
      Types: contact.classificationTypes.toString(),
      Confidence: contact.classificationConfidence.toString(),
    })
    this.props.setSidePanelVisibility(true)
    this.props.selectContact(contact)

    // avoid mixed content
    this.props.selectMapElement(null)

    this.props.sidePanelVerification()
  }

  public buildNewContactDialog() {
    if (!this.state.isContactModalOpen) {
      return (
        <div className={'contactDialog'}>
          <Button className="m-1" color="info" size="sm" onClick={this.toggleContactModal}>
            Add contact
          </Button>
        </div>
      )
    }
    // Create new contact
    else {
      return (
        <>
          <Modal
            id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
            isOpen={this.state.isContactModalOpen}
            toggle={this.toggleContactModal}
          >
            <ModalHeader toggle={this.toggleContactModal}>New Contact</ModalHeader>
            <ModalBody>
              <div>
                <input
                  type="text"
                  className="contact-input"
                  id={'contactLabel-input'}
                  placeholder="Label"
                  value={this.state.contactInputLabel}
                  onChange={(event) => this.setState({ contactInputLabel: event.target.value })}
                />
                <select
                  id={this.state.contactInputState === '' ? 'contactState-input-hidden' : 'contactState-input'}
                  className="contact-input"
                  value={this.state.contactInputState}
                  onChange={(event: any) => this.setState({ contactInputState: event.target.value })}
                  onClick={(event) => event.stopPropagation()}
                >
                  <option disabled={true} hidden={true} value="">
                    State
                  </option>
                  <option value="MILEC" onClick={(event) => event.stopPropagation()}>
                    MILEC
                  </option>
                  <option value="MILCO" onClick={(event) => event.stopPropagation()}>
                    MILCO
                  </option>
                  <option value="NON_MILCO" onClick={(event) => event.stopPropagation()}>
                    NON_MILCO
                  </option>
                  <option value="NOMBO" onClick={(event) => event.stopPropagation()}>
                    NOMBO
                  </option>
                  <option value="UNDEFINED" onClick={(event) => event.stopPropagation()}>
                    UNDEFINED
                  </option>
                </select>
                <input
                  type="text"
                  className="contact-input"
                  id={'contactUncertanty-input'}
                  title={'Percentage value'}
                  placeholder="Uncertanty"
                  value={this.state.contactInputUncertanty}
                  onChange={(event: any) => this.setState({ contactInputUncertanty: event.target.value })}
                />
                <input
                  type="text"
                  className="contact-input"
                  id={'contactTypes-input-'}
                  title={'e.g. cylinder, truncated_cone, sonar_side_scanning,...'}
                  placeholder="Classification types (separated by ,)"
                  value={this.state.classificationTypesInput}
                  onChange={(event: any) => this.setState({ classificationTypesInput: event.target.value })}
                />
                <input
                  type="text"
                  className="contact-input"
                  id={'contactConfidence-input-'}
                  title={'Percentage value'}
                  placeholder="Classification confidence (separated by ,)"
                  value={this.state.classificationConfidenceInput}
                  onChange={(event: any) => this.setState({ classificationConfidenceInput: event.target.value })}
                />
                <div id={'contactPosition'}>
                  <div className={'header'}>Position</div>
                  <input
                    type="text"
                    className="contact-input"
                    id={'contactLatitude-input'}
                    placeholder="Latitude"
                    value={this.state.contactInputLatitude}
                    onChange={(event: any) => this.setState({ contactInputLatitude: event.target.value })}
                  />
                  <input
                    type="text"
                    className="contact-input"
                    id={'contactLongitude-input'}
                    placeholder="Longitude"
                    value={this.state.contactInputLongitude}
                    onChange={(event: any) => this.setState({ contactInputLongitude: event.target.value })}
                  />
                  {this.props.selectedPosition === undefined ? (
                    <i
                      title={`Select position`}
                      className="fas fa-map-marker-alt"
                      onClick={() => this.selectPosition()}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="contactTimestampWrapper">
                  <DatePicker
                    className="contact-input"
                    id={'contactTimestamp-input'}
                    selected={this.state.contactInputTimestamp}
                    onChange={(newDate: Date) => this.setState({ contactInputTimestamp: newDate })}
                    showTimeSelect={true}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="time"
                    maxDate={new Date()}
                    excludeScrollbar={true}
                    timeIntervals={15}
                    disabled={false}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.createContact()}>
                Create
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )
    }
  }

  public buildPosition() {
    if (this.props.selectedPosition) {
      return (
        <Marker
          key={'contact_position'}
          position={[this.props.selectedPosition.latitude, this.props.selectedPosition.longitude]}
          icon={new BlueCircleIcon()}
        />
      )
    }
  }

  public toggleContactModal() {
    this.setState((prevState) => ({
      isContactModalOpen: !prevState.isContactModalOpen,
    }))
  }

  public selectPosition() {
    NotificationManager.info('Select contact position')
    this.setState({ isContactModalOpen: false })
    this.props.selectPosition(true)
  }

  // send over TCP & save contact
  public async createContact() {
    if (
      this.state.contactInputLabel.length > 0 &&
      this.state.contactInputLatitude !== undefined &&
      this.state.contactInputLongitude !== undefined &&
      this.state.contactInputState.length > 0 &&
      this.state.contactInputUncertanty !== undefined &&
      this.state.contactInputTimestamp !== undefined &&
      this.state.classificationTypesInput !== undefined &&
      this.state.classificationConfidenceInput !== undefined
    ) {
      // parse types
      const classificationTypes: string[] = []
      const types = this.state.classificationTypesInput.split(',')
      types.forEach((t) => {
        classificationTypes.push(t.replace(/\s/g, ''))
      })

      // parse confidence
      const classificationConfidence: number[] = []
      const confidence = this.state.classificationConfidenceInput.split(',')
      confidence.forEach((c) => {
        const value: number = +c.replace(/\s/g, '')
        classificationConfidence.push(value)
      })

      try {
        const newContact = new IContact(
          this.state.contactInputLabel,
          this.state.contactInputTimestamp,
          this.state.contactInputLatitude,
          this.state.contactInputLongitude,
          this.state.contactInputState,
          this.state.contactInputUncertanty,
          classificationTypes,
          classificationConfidence
        )

        const response = await this.contactService.newContact(newContact)
        if (response.status === 'success') {
          NotificationManager.success(response.message)
          this.setState({
            isContactModalOpen: false,
            contactInputLabel: '',
            contactInputLatitude: undefined,
            contactInputLongitude: undefined,
            contactInputState: '',
            contactInputUncertanty: undefined,
            classificationTypesInput: '',
            classificationConfidenceInput: '',
            contactInputTimestamp: new Date(),
          })
          this.props.selectPosition(false)
        } else {
          NotificationManager.warning(response.message)
          this.setState({
            isContactModalOpen: false,
          })
          this.props.selectPosition(false)
        }
        // update redux
        this.props.setContactMarkers()
        this.props.selectPosition(false)
      } catch (error) {
        NotificationManager.warning('Cannot create Contact')
      }
    } else {
      NotificationManager.warning('Missing information...')
    }
    this.props.selectPosition(false)
  }

  public render() {
    return (
      <>
        {this.props.symbolType === 'military' ? this.buildContactsWithMilitarySymbols() : this.buildContacts()}
        {this.buildPosition()}
        {this.buildNewContactDialog()}
      </>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    contactSelected: state.contactSelected,
    isDarkMode: state.isDarkMode,
  }
}

const actionCreators = {
  selectContact,
  selectMapElement,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
}

export default connect(mapStateToProps, actionCreators)(Contact)
