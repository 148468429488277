import React, { Component } from 'react'
import { connect } from 'react-redux'
import IAircraft from '../../../model/IAircraft'
import IRipplesState from '../../../model/IRipplesState'
import {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
} from '../../../redux/ripples.actions'
import AircraftService from '../../../services/AircraftUtils'
import DateService from '../../../services/DateUtils'
import PositionService from '../../../services/PositionUtils'
import { AircraftIcon, AircraftSelectedIcon } from './Icons'
import RotatedMarker from './RotatedMarker'

interface PropsType {
  aircrafts?: IAircraft[]
  currentZoom: number
  isSidePanelVisible: boolean
  coordsDisplayFormat: string
  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
  sidePanelVerification: () => void
}

interface StateType {
  aircraftSelected?: IAircraft
}

class Aircraft extends Component<PropsType, StateType> {
  public aircraftIcon = new AircraftIcon()
  public aircraftSelectedIcon = new AircraftSelectedIcon()
  private aircraftService: AircraftService = new AircraftService()
  private positionService: PositionService = new PositionService()

  constructor(props: PropsType) {
    super(props)
    this.state = {
      aircraftSelected: undefined,
    }
    this.onAircraftClick = this.onAircraftClick.bind(this)
  }

  public async componentDidUpdate() {
    if (this.state.aircraftSelected && !this.props.isSidePanelVisible) {
      this.setState({ aircraftSelected: undefined })
    }
  }

  public getDisplayableProperties(aircraft: IAircraft) {
    const headingReadable = this.positionService.getReadableHeading(aircraft.headingDeg)
    const categoryReadable = this.aircraftService.getcategoryReadable(aircraft.category)
    const aircraftLastUpdate = new Date(aircraft.timestamp + '')

    const properties = {
      'last update': DateService.timeFromNow(aircraftLastUpdate.getTime()),
      latitude: this.positionService.formatCoords(aircraft.latitudeDeg, 'lat', this.props.coordsDisplayFormat),
      longitude: this.positionService.formatCoords(aircraft.longitudeDeg, 'lon', this.props.coordsDisplayFormat),
      speed: aircraft.speed.toFixed(1) + ' m/s',
      heading: headingReadable,
      height: aircraft.height.toFixed(2) + ' m',
      category: categoryReadable + ' (' + aircraft.category + ')',
      type: aircraft.type,
      flight: aircraft.flight,
      origin: aircraft.origin,
      destination: aircraft.destination,
      airline: aircraft.airline,
      country: aircraft.country,
    }

    return properties
  }

  public onAircraftClick(evt: any, aircraft: IAircraft) {
    evt.originalEvent.view.L.DomEvent.stopPropagation(evt)

    this.props.setSidePanelTitle(aircraft.hex)
    this.props.setSidePanelContent(this.getDisplayableProperties(aircraft))
    this.props.setSidePanelVisibility(true)

    // avoid issues with sidebar
    this.props.sidePanelVerification()

    this.setState({ aircraftSelected: aircraft })
  }

  public buildAircraft() {
    const iconSize = Math.min(22, Math.max(5, 2 + this.props.currentZoom * 1.5))
    this.aircraftIcon.options.iconSize = [iconSize + 4, iconSize + 4]
    this.aircraftSelectedIcon.options.iconSize = [iconSize + 4, iconSize + 4]

    if (this.props.aircrafts) {
      return this.props.aircrafts.map((aircraft) => {
        return (
          <RotatedMarker
            key={'aircraft_' + aircraft.hex}
            position={{ lat: aircraft.latitudeDeg, lng: aircraft.longitudeDeg }}
            zoom={this.props.currentZoom}
            rotationAngle={aircraft.headingDeg}
            rotationOrigin={'center'}
            icon={this.state.aircraftSelected === aircraft ? this.aircraftSelectedIcon : this.aircraftIcon}
            opacity={1}
            onClick={(e: any) => this.onAircraftClick(e, aircraft)}
          />
        )
      })
    }
  }

  public render() {
    return <>{this.buildAircraft()}</>
  }
}

const actionCreators = {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
}

function mapStateToProps(state: IRipplesState) {
  return {
    isSidePanelVisible: state.isSidePanelVisible,
  }
}

export default connect(mapStateToProps, actionCreators)(Aircraft)
