import IContact from '../model/IContact'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export default class ContactService {
  public async fetchContactData(): Promise<IContact[]> {
    const response = await fetch(`${apiURL}/contacts`)
    const contacts = await response.json()
    return contacts
  }

  public async fetchAllContactData(): Promise<IContact[]> {
    const response = await fetch(`${apiURL}/contacts/all`)
    const contacts = await response.json()
    return contacts
  }

  public async newContact(contact: IContact) {
    return request({
      method: 'POST',
      body: JSON.stringify(contact),
      url: `${apiURL}/contacts/sync`,
    })
  }

  public async sendContact(contact: IContact, msgType: string, vehicle: string) {
    return request({
      method: 'POST',
      body: JSON.stringify(contact),
      url: `${apiURL}/contacts/send/${msgType}/${vehicle}`,
    })
  }

  public async sendContactChat(contact: IContact, text: string, from: string, to: string) {
    return request({
      method: 'POST',
      body: JSON.stringify({ contact, text, from, to }),
      url: `${apiURL}/contacts/chat`,
    })
  }

  public async listContactImages(contactID: string) {
    const response = await fetch(`${apiURL}/contacts/listImages/${contactID}`)
    const contactImages = await response.json()
    return contactImages
  }

  public async fetchContactClassification(imgName: string) {
    const response = await fetch(`${apiURL}/contacts/classification/${imgName}`)
    const imageClassification = await response.json()
    return imageClassification
  }
}
