import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleNavbar from '../../components/SimpleNavbar'
import IAuthState, { isL4, IUser } from '../../model/IAuthState'
import IRipplesState from '../../model/IRipplesState'
import { setUser } from '../../redux/ripples.actions'
import { getCurrentUser } from '../../services/UserUtils'
import IIntrusion, { IGroupedIntrusionData } from '../../model/IIntrusion'
import { fetchIntrusionsByCampaignName } from '../../services/IntrusionUtils'
import LocalStorageService from '../../services/LocalStorageService'
import DateService from '../../services/DateUtils'
const { NotificationManager } = require('react-notifications')

interface StateType {
  isNavOpen: boolean
  intrusionData: IIntrusion[]
  groupdIntrusionData: IGroupedIntrusionData
  toggledLabels: any
}

interface PropsType {
  setUser: (user: IUser) => any
  auth: IAuthState
  isDarkMode: boolean
}

/**
 * Display intrusion areas
 */
export class Intrusion extends Component<PropsType, StateType> {
  private localStorageService: LocalStorageService = new LocalStorageService()
  public timerID: number = 0

  constructor(props: any) {
    super(props)
    this.state = {
      isNavOpen: false,
      intrusionData: [],
      groupdIntrusionData: {},
      toggledLabels: {}, // Toggled labels
    }
    this.updateIntrusionData = this.updateIntrusionData.bind(this)
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
  }

  public toggleLabel = (label: any) => {
    this.setState((prevState) => ({
      toggledLabels: {
        ...prevState.toggledLabels,
        [label]: !prevState.toggledLabels[label],
      },
    }))
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    if (!this.props.auth.authenticated || (this.props.auth.authenticated && isL4(this.props.auth))) {
      NotificationManager.error('Permission required')
    } else {
      this.updateIntrusionData()
      this.timerID = window.setInterval(this.updateIntrusionData, 60000)
    }
  }

  public componentWillUnmount() {
    clearInterval(this.timerID)
  }

  public onNavToggle() {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  public async updateIntrusionData() {
    const intrusionList: IIntrusion[] = await fetchIntrusionsByCampaignName(
      this.localStorageService.getCampaignSelected()
    )

    const groupedData = intrusionList.reduce<IGroupedIntrusionData>((acc, current) => {
      const label = current.mapElementLabel

      if (!acc[label]) {
        acc[label] = []
      }

      acc[label].push(current)
      return acc
    }, {})

    // this.setState({ intrusionData: intrusionList })
    this.setState({ groupdIntrusionData: groupedData })
  }

  public render() {
    /*
        const groupedData = this.state.intrusionData.reduce<GroupedData>((acc, current) => {
          const label = current.mapElementLabel
    
          if (!acc[label]) {
            acc[label] = []
          }
    
          acc[label].push(current)
          return acc
        }, {})
        */

    return (
      <>
        <SimpleNavbar auth={this.props} />
        <div className={this.props.isDarkMode ? 'text darkmode' : 'intrusion'}>
          <div className="intrusion-title" />
          <h2>Intrusion Information</h2>

          <div className="intrusion-content">
            {Object.keys(this.state.groupdIntrusionData).map((label) => (
              <div key={label} className="intrusion-group">
                <div className="intrusion-label" onClick={() => this.toggleLabel(label)}>
                  <h4>
                    {label} {this.state.toggledLabels[label] ? '▲' : '▼'}
                  </h4>
                </div>

                {this.state.toggledLabels[label] && (
                  <div className="intrusion-list">
                    {this.state.groupdIntrusionData[label].map((intrusion: IIntrusion) => (
                      <div
                        key={'intrusion_' + intrusion.id}
                        className={intrusion.type.includes('ALERT') ? 'intrusion-item-alert' : 'intrusion-item-warning'}
                      >
                        <strong>System Name:</strong> {intrusion.systemName}
                        <br />
                        <strong>Timestamp:</strong> {DateService.formatDate(intrusion.timestamp)}
                        <br />
                        <strong>Latitude:</strong> {intrusion.latitude}
                        <br />
                        <strong>Longitude:</strong> {intrusion.longitude}
                        <br />
                        <strong>Type:</strong> {intrusion.type}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    isDarkMode: state.isDarkMode,
  }
}

const actionCreators = {
  setUser,
}

export default connect(mapStateToProps, actionCreators)(Intrusion)
