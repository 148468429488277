import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleNavbar from '../../../components/SimpleNavbar'
import IAuthState, { isL4, IUser } from '../../../model/IAuthState'
import IRipplesState from '../../../model/IRipplesState'
import { setCampaign, setUser } from '../../../redux/ripples.actions'
import { getCurrentUser } from '../../../services/UserUtils'
import ICampaign, { IMission } from '../../../model/ICampaign'
import { fetchCampaignByName, fetchMission, removeMission, updateMission } from '../../../services/CampaignUtils'
// import DateService from '../../../services/DateUtils'
import DatePicker from 'react-datepicker'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import LocalStorageService from '../../../services/LocalStorageService'
import DateService from '../../../services/DateUtils'
import { Link } from 'react-router-dom'
import MapElementService from '../../../services/MapElementUtils'
const { NotificationManager } = require('react-notifications')

interface StateType {
  loading: boolean
  missions: IMission[]
  missionSelected?: IMission
  isCoordinator: boolean
  startDate: Date
  endDate: Date
  usersAvailable: string[]
  assetsAvailable: string[]
  mapElementsAvailable: string[]
  isConfirmationModalOpen: boolean
  isCoordinatorsModalOpen: boolean
  isCoordinatorsConfirmationModalOpen: boolean
  isAnalystsModalOpen: boolean
  isAnalystsConfirmationModalOpen: boolean
  isOperatorsModalOpen: boolean
  isOperatorsConfirmationModalOpen: boolean
  isAssetsModalOpen: boolean
  isAssetsConfirmationModalOpen: boolean
  isSystemsOfInterestModalOpen: boolean
  isSystemsOfInterestConfirmationModalOpen: boolean
  isMapElementsModalOpen: boolean
  isMapElementsConfirmationModalOpen: boolean
  itemToRemove: string
}

interface PropsType {
  setUser: (user: IUser) => any
  setCampaign: (_: ICampaign | null) => void
  auth: IAuthState
  isDarkMode: boolean
  campaignSelected: ICampaign | null
}

export class Mission extends Component<PropsType, StateType> {
  public notificationSystem: any = null
  private localStorageService: LocalStorageService = new LocalStorageService()
  private mapElementsService: MapElementService = new MapElementService()

  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      missions: [],
      isCoordinator: false,
      startDate: new Date(),
      endDate: new Date(),
      usersAvailable: [],
      assetsAvailable: [],
      mapElementsAvailable: [],
      isConfirmationModalOpen: false,
      isCoordinatorsModalOpen: false,
      isCoordinatorsConfirmationModalOpen: false,
      isAnalystsModalOpen: false,
      isAnalystsConfirmationModalOpen: false,
      isOperatorsModalOpen: false,
      isOperatorsConfirmationModalOpen: false,
      isAssetsModalOpen: false,
      isAssetsConfirmationModalOpen: false,
      isSystemsOfInterestModalOpen: false,
      isSystemsOfInterestConfirmationModalOpen: false,
      isMapElementsModalOpen: false,
      isMapElementsConfirmationModalOpen: false,
      itemToRemove: '',
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.loadCampaign = this.loadCampaign.bind(this)
    this.loadMissions = this.loadMissions.bind(this)
    this.updateMission = this.updateMission.bind(this)
    this.removeMission = this.removeMission.bind(this)
  }

  public componentWillUnmount() {
    localStorage.removeItem('MISSION')
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  private async loadMissions() {
    const campaignName = this.localStorageService.getCampaignSelected()
    const missionName = this.localStorageService.getMissionSelected()

    if (missionName !== undefined) {
      // load campaign
      await this.loadCampaign()

      const missionId = campaignName + '-' + missionName

      const missionSelected: IMission = await fetchMission(missionId)

      const d1: string = DateService.formatDateOnlySeparated(missionSelected.startDate)
      const startDate: Date = new Date(
        parseInt(d1.split('/')[2], 10),
        parseInt(d1.split('/')[1], 10) - 1,
        parseInt(d1.split('/')[0], 10)
      )

      const d2: string = DateService.formatDateOnlySeparated(missionSelected.endDate)
      const endDate: Date = new Date(
        parseInt(d2.split('/')[2], 10),
        parseInt(d2.split('/')[1], 10) - 1,
        parseInt(d2.split('/')[0], 10)
      )

      // check if user is mission coordinator
      let isCoordinator: boolean = false
      if (missionSelected.coordinators.includes(this.props.auth.currentUser.email)) {
        isCoordinator = true
      }

      // users available
      const usersAvailable: string[] = []
      if (this.props.campaignSelected) {
        usersAvailable.push(this.props.campaignSelected.leader)
        this.props.campaignSelected.users.forEach((user) => {
          if (this.props.campaignSelected && !this.props.campaignSelected.leader.includes(user)) {
            usersAvailable.push(user)
          }
        })
      }

      // assets available
      const assetsAvailable: string[] = []
      if (this.props.campaignSelected) {
        this.props.campaignSelected.assets.forEach((assetName) => {
          assetsAvailable.push(assetName)
        })
      }

      // mapElements available
      const mapElementsAvailable: string[] = await this.mapElementsService.fetchMapElementsLabels()

      this.setState({
        missionSelected,
        startDate,
        endDate,
        isCoordinator,
        usersAvailable,
        assetsAvailable,
        mapElementsAvailable,
        isConfirmationModalOpen: false,
        isCoordinatorsModalOpen: false,
        isCoordinatorsConfirmationModalOpen: false,
        isAnalystsModalOpen: false,
        isAnalystsConfirmationModalOpen: false,
        isOperatorsModalOpen: false,
        isOperatorsConfirmationModalOpen: false,
        isAssetsModalOpen: false,
        isAssetsConfirmationModalOpen: false,
        isSystemsOfInterestModalOpen: false,
        isSystemsOfInterestConfirmationModalOpen: false,
        isMapElementsModalOpen: false,
        isMapElementsConfirmationModalOpen: false,
        itemToRemove: '',
      })
    }
  }

  private async loadCampaign() {
    const campaignSelected: ICampaign = await fetchCampaignByName(
      this.localStorageService.getCampaignSelected(),
      this.props.auth.currentUser.email
    )
    this.props.setCampaign(campaignSelected)
    if (this.props.campaignSelected === null) {
      NotificationManager.error('Campaign not found')
      return
    }
  }

  private async updateMission(key: string, value: string | Date) {
    const campaignName = this.localStorageService.getCampaignSelected()

    if (this.state.missionSelected !== undefined) {
      const missionId = campaignName + '-' + this.state.missionSelected.name

      if (value instanceof Date) {
        // update dates
        value.setHours(1)
        const resp = await updateMission(missionId, key, value.getTime())
        if (resp.status === 'Success') {
          NotificationManager.success(resp.message)
          this.loadMissions()
        } else {
          NotificationManager.warning(resp.message)
        }
      } else {
        // update parameters
        const resp = await updateMission(missionId, key, value)
        if (resp.status === 'Success') {
          NotificationManager.success(resp.message)
          this.loadMissions()
        } else {
          NotificationManager.warning(resp.message)
        }
      }
    } else {
      NotificationManager.error('Invalid request')
    }
  }

  private async removeMission() {
    if (this.state.missionSelected) {
      const resp = await removeMission(this.state.missionSelected)
      if (resp.status === 'Success') {
        NotificationManager.success(resp.message)
        // redirect to campaign page
        const campaignLink = document.getElementById('campaignpage-link')
        if (campaignLink !== null) {
          campaignLink.click()
        }
      } else {
        NotificationManager.warning(resp.message)
      }
    }
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    this.setState({ loading: false })

    if (this.props.auth.authenticated) {
      if (!isL4(this.props.auth)) {
        // TODO
        this.loadMissions()
      } else {
        NotificationManager.error('Invalid permission...')
      }
    } else {
      NotificationManager.error('Please login')
    }
  }

  private renderLeftContainer() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="left-container campaign-field">
          <h4>Start Date</h4>
          <DatePicker
            className="setting-input-date"
            selected={this.state.startDate}
            onChange={(newDate: Date) => this.updateMission('startDate', newDate)}
            dateFormat="dd/MM/yyyy"
            timeCaption="time"
            minDate={new Date()}
            maxDate={this.state.endDate}
            disabled={!this.state.isCoordinator}
          />

          <h4>End Date</h4>
          <DatePicker
            className="setting-input-date"
            selected={this.state.endDate}
            onChange={(newDate: Date) => this.updateMission('endDate', newDate)}
            dateFormat="dd/MM/yyyy"
            timeCaption="time"
            minDate={this.state.startDate}
            disabled={!this.state.isCoordinator}
          />
        </div>
      )
    }
  }

  private renderRightContainer() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="right-container">
          <Button
            color="danger"
            title="Remove mission"
            onClick={() => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen })}
          >
            Remove
          </Button>
          <Link id="campaignpage-link" to="/campaign" />

          {this.buildConfirmationModal()}
        </div>
      )
    }
  }

  private buildConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isConfirmationModalOpen}
        toggle={() => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen })}
      >
        <ModalHeader toggle={() => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen })}>
          Remove Mission
        </ModalHeader>
        <ModalBody>The Mission will be removed permanently. Do you want to continue?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.removeMission()}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderCoordinators() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Coordinators</h4>
            {this.state.isCoordinator ? (
              <Button
                className="campaign-group-add"
                title="Add coordinator"
                color="info"
                size="sm"
                onClick={() => this.setState({ isCoordinatorsModalOpen: !this.state.isCoordinatorsModalOpen })}
              >
                Add user
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.state.missionSelected.coordinators.map((coordinator, i) => {
              return (
                <Button
                  key={'coordinator-' + i}
                  title={this.state.isCoordinator ? 'Click to remove' : ''}
                  color="#e1e3e6"
                  size="sm"
                  onClick={
                    this.state.isCoordinator
                      ? () =>
                          this.setState({
                            itemToRemove: coordinator,
                            isCoordinatorsConfirmationModalOpen: !this.state.isCoordinatorsConfirmationModalOpen,
                          })
                      : undefined
                  }
                >
                  {coordinator}
                </Button>
              )
            })}
          </div>

          {this.buildCoordinatorsModal()}
          {this.buildCoordinatorsConfirmationModal()}
        </div>
      )
    }
  }

  private buildCoordinatorsModal() {
    return (
      <Modal
        isOpen={this.state.isCoordinatorsModalOpen}
        toggle={() => this.setState({ isCoordinatorsModalOpen: !this.state.isCoordinatorsModalOpen })}
      >
        <ModalHeader toggle={() => this.setState({ isCoordinatorsModalOpen: !this.state.isCoordinatorsModalOpen })}>
          Add coordinator to mission
        </ModalHeader>

        <ModalBody>
          <select
            className="campaignUsers-input"
            defaultValue={''}
            onChange={(event: any) => this.updateMission('coordinator', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose user'}
            </option>
            {this.state.usersAvailable.map((u, index) => {
              if (this.props.campaignSelected) {
                return (
                  <option
                    key={'coordinatorOption-' + index}
                    value={u}
                    disabled={this.state.missionSelected ? this.state.missionSelected.coordinators.includes(u) : false}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {u}
                  </option>
                )
              } else {
                return <></>
              }
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildCoordinatorsConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isCoordinatorsConfirmationModalOpen}
        toggle={() =>
          this.setState({
            itemToRemove: '',
            isCoordinatorsConfirmationModalOpen: !this.state.isCoordinatorsConfirmationModalOpen,
          })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({
              itemToRemove: '',
              isCoordinatorsConfirmationModalOpen: !this.state.isCoordinatorsConfirmationModalOpen,
            })
          }
        >
          Remove coordinator
        </ModalHeader>

        <ModalBody>
          The user <b>{this.state.itemToRemove}</b> will be removed from coordinators. <br />
          Do you want to continue?
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={
              this.state.isCoordinator ? () => this.updateMission('coordinator', this.state.itemToRemove) : undefined
            }
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderAnalysts() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Analyst</h4>
            {this.state.isCoordinator ? (
              <Button
                className="campaign-group-add"
                title="Add analyst"
                color="info"
                size="sm"
                onClick={() => this.setState({ isAnalystsModalOpen: !this.state.isAnalystsModalOpen })}
              >
                Add user
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.state.missionSelected.analysts.map((analyst, i) => {
              return (
                <Button
                  key={'analyst-' + i}
                  title={this.state.isCoordinator ? 'Click to remove' : ''}
                  color="#e1e3e6"
                  size="sm"
                  onClick={
                    this.state.isCoordinator
                      ? () =>
                          this.setState({
                            itemToRemove: analyst,
                            isAnalystsConfirmationModalOpen: !this.state.isAnalystsConfirmationModalOpen,
                          })
                      : undefined
                  }
                >
                  {analyst}
                </Button>
              )
            })}
          </div>

          {this.buildAnalystsModal()}
          {this.buildAnalystsConfirmationModal()}
        </div>
      )
    }
  }

  private buildAnalystsModal() {
    return (
      <Modal
        isOpen={this.state.isAnalystsModalOpen}
        toggle={() => this.setState({ isAnalystsModalOpen: !this.state.isAnalystsModalOpen })}
      >
        <ModalHeader>Add analyst to mission</ModalHeader>

        <ModalBody>
          <select
            className="campaignUsers-input"
            defaultValue={''}
            onChange={(event: any) => this.updateMission('analyst', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose user'}
            </option>
            {this.state.usersAvailable.map((u, index) => {
              if (this.props.campaignSelected) {
                return (
                  <option
                    key={'analystOption-' + index}
                    value={u}
                    disabled={this.state.missionSelected ? this.state.missionSelected.analysts.includes(u) : false}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {u}
                  </option>
                )
              } else {
                return <></>
              }
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildAnalystsConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isAnalystsConfirmationModalOpen}
        toggle={() =>
          this.setState({
            itemToRemove: '',
            isAnalystsConfirmationModalOpen: !this.state.isAnalystsConfirmationModalOpen,
          })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({
              itemToRemove: '',
              isAnalystsConfirmationModalOpen: !this.state.isAnalystsConfirmationModalOpen,
            })
          }
        >
          Remove analyst
        </ModalHeader>

        <ModalBody>
          The user <b>{this.state.itemToRemove}</b> will be removed from the analysts. <br />
          Do you want to continue?
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={
              this.state.isCoordinator ? () => this.updateMission('analyst', this.state.itemToRemove) : undefined
            }
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderOperators() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Operators</h4>
            {this.state.isCoordinator ? (
              <Button
                className="campaign-group-add"
                title="Add operator"
                color="info"
                size="sm"
                onClick={() => this.setState({ isOperatorsModalOpen: !this.state.isOperatorsModalOpen })}
              >
                Add user
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.state.missionSelected.operators.map((operator, i) => {
              return (
                <Button
                  key={'operator-' + i}
                  title={this.state.isCoordinator ? 'Click to remove' : ''}
                  color="#e1e3e6"
                  size="sm"
                  onClick={
                    this.state.isCoordinator
                      ? () =>
                          this.setState({
                            itemToRemove: operator,
                            isOperatorsConfirmationModalOpen: !this.state.isOperatorsConfirmationModalOpen,
                          })
                      : undefined
                  }
                >
                  {operator}
                </Button>
              )
            })}
          </div>

          {this.buildOperatorsModal()}
          {this.buildOperatorsConfirmationModal()}
        </div>
      )
    }
  }

  private buildOperatorsModal() {
    return (
      <Modal
        isOpen={this.state.isOperatorsModalOpen}
        toggle={() => this.setState({ isOperatorsModalOpen: !this.state.isOperatorsModalOpen })}
      >
        <ModalHeader>Add operator to mission</ModalHeader>

        <ModalBody>
          <select
            className="campaignUsers-input"
            defaultValue={''}
            onChange={(event: any) => this.updateMission('operator', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose user'}
            </option>
            {this.state.usersAvailable.map((u, index) => {
              if (this.props.campaignSelected) {
                return (
                  <option
                    key={'operatorOption-' + index}
                    value={u}
                    disabled={this.state.missionSelected ? this.state.missionSelected.operators.includes(u) : false}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {u}
                  </option>
                )
              } else {
                return <></>
              }
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildOperatorsConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isOperatorsConfirmationModalOpen}
        toggle={() =>
          this.setState({
            itemToRemove: '',
            isOperatorsConfirmationModalOpen: !this.state.isOperatorsConfirmationModalOpen,
          })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({
              itemToRemove: '',
              isOperatorsConfirmationModalOpen: !this.state.isOperatorsConfirmationModalOpen,
            })
          }
        >
          Remove operator
        </ModalHeader>

        <ModalBody>
          The user <b>{this.state.itemToRemove}</b> will be removed from the operators. <br />
          Do you want to continue?
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={
              this.state.isCoordinator ? () => this.updateMission('operator', this.state.itemToRemove) : undefined
            }
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderAssets() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Assets</h4>
            {this.state.isCoordinator ? (
              <Button
                className="campaign-group-add"
                title="Add asset"
                color="info"
                size="sm"
                onClick={() => this.setState({ isAssetsModalOpen: !this.state.isAssetsModalOpen })}
              >
                Add asset
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.state.missionSelected.assets.map((assetName, i) => {
              return (
                <Button
                  key={'asset-' + i}
                  title={this.state.isCoordinator ? 'Click to remove' : ''}
                  color="#e1e3e6"
                  size="sm"
                  onClick={
                    this.state.isCoordinator
                      ? () =>
                          this.setState({
                            itemToRemove: assetName,
                            isAssetsConfirmationModalOpen: !this.state.isAssetsConfirmationModalOpen,
                          })
                      : undefined
                  }
                >
                  {assetName}
                </Button>
              )
            })}
          </div>

          {this.buildAssetsModal()}
          {this.buildAssetsConfirmationModal()}
        </div>
      )
    }
  }

  private buildAssetsModal() {
    return (
      <Modal
        isOpen={this.state.isAssetsModalOpen}
        toggle={() => this.setState({ isAssetsModalOpen: !this.state.isAssetsModalOpen })}
      >
        <ModalHeader>Add asset to mission</ModalHeader>

        <ModalBody>
          <select
            className="campaignUsers-input"
            defaultValue={''}
            onChange={(event: any) => this.updateMission('assets', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose asset'}
            </option>
            {this.state.assetsAvailable.map((assetName, index) => {
              if (this.props.campaignSelected) {
                // check if asset is already assigned
                let isAssetAvailable: boolean = false
                if (this.state.missionSelected) {
                  this.state.missionSelected.assets.forEach((asset) => {
                    if (asset.includes(assetName)) {
                      isAssetAvailable = true
                    }
                  })
                }

                return (
                  <option
                    key={'assetOption-' + index}
                    value={assetName}
                    disabled={isAssetAvailable}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {assetName}
                  </option>
                )
              } else {
                return <></>
              }
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildAssetsConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isAssetsConfirmationModalOpen}
        toggle={() =>
          this.setState({
            itemToRemove: '',
            isAssetsConfirmationModalOpen: !this.state.isAssetsConfirmationModalOpen,
          })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({
              itemToRemove: '',
              isAssetsConfirmationModalOpen: !this.state.isAssetsConfirmationModalOpen,
            })
          }
        >
          Remove asset
        </ModalHeader>

        <ModalBody>
          The asset <b>{this.state.itemToRemove}</b> will be removed from assets. <br />
          Do you want to continue?
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={this.state.isCoordinator ? () => this.updateMission('assets', this.state.itemToRemove) : undefined}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderSystemsOfInterest() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Systems of Interest</h4>
            {this.state.isCoordinator ? (
              <Button
                className="campaign-group-add"
                title="Add system"
                color="info"
                size="sm"
                onClick={() =>
                  this.setState({ isSystemsOfInterestModalOpen: !this.state.isSystemsOfInterestModalOpen })
                }
              >
                Add system
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.state.missionSelected.systemsOfInterest.map((systemName, i) => {
              return (
                <Button
                  key={'system-' + i}
                  title={this.state.isCoordinator ? 'Click to remove' : ''}
                  color="#e1e3e6"
                  size="sm"
                  onClick={
                    this.state.isCoordinator
                      ? () =>
                          this.setState({
                            itemToRemove: systemName,
                            isSystemsOfInterestConfirmationModalOpen: !this.state
                              .isSystemsOfInterestConfirmationModalOpen,
                          })
                      : undefined
                  }
                >
                  {systemName}
                </Button>
              )
            })}
          </div>

          {this.buildSystemsOfInterestModal()}
          {this.buildSystemsOfInterestConfirmationModal()}
        </div>
      )
    }
  }

  private buildSystemsOfInterestModal() {
    return (
      <Modal
        isOpen={this.state.isSystemsOfInterestModalOpen}
        toggle={() => this.setState({ isSystemsOfInterestModalOpen: !this.state.isSystemsOfInterestModalOpen })}
      >
        <ModalHeader>Add system of interest</ModalHeader>

        <ModalBody>
          <select
            className="campaignUsers-input"
            defaultValue={''}
            onChange={(event: any) => this.updateMission('systemsOfInterest', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose system'}
            </option>
            {this.state.assetsAvailable.map((assetName, index) => {
              if (this.props.campaignSelected) {
                // check if system is already assigned
                let isSystemAvailable: boolean = false
                if (this.state.missionSelected) {
                  this.state.missionSelected.systemsOfInterest.forEach((system) => {
                    if (system.includes(assetName)) {
                      isSystemAvailable = true
                    }
                  })
                }

                return (
                  <option
                    key={'systemOption-' + index}
                    value={assetName}
                    disabled={isSystemAvailable}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {assetName}
                  </option>
                )
              } else {
                return <></>
              }
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildSystemsOfInterestConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isSystemsOfInterestConfirmationModalOpen}
        toggle={() =>
          this.setState({
            itemToRemove: '',
            isSystemsOfInterestConfirmationModalOpen: !this.state.isSystemsOfInterestConfirmationModalOpen,
          })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({
              itemToRemove: '',
              isSystemsOfInterestConfirmationModalOpen: !this.state.isSystemsOfInterestConfirmationModalOpen,
            })
          }
        >
          Remove system of interest
        </ModalHeader>

        <ModalBody>
          The asset <b>{this.state.itemToRemove}</b> will be removed from systems of interest. <br />
          Do you want to continue?
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={
              this.state.isCoordinator
                ? () => this.updateMission('systemsOfInterest', this.state.itemToRemove)
                : undefined
            }
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderMapElements() {
    if (this.state.missionSelected !== undefined) {
      return (
        <div className="campaign-group">
          <div>
            <h4>Map Elements</h4>
            {this.state.isCoordinator ? (
              <Button
                className="campaign-group-add"
                title="Add map element"
                color="info"
                size="sm"
                onClick={() => this.setState({ isMapElementsModalOpen: !this.state.isMapElementsModalOpen })}
              >
                Add area
              </Button>
            ) : (
              <></>
            )}
            <hr />
          </div>
          <div className="campaign-group-list">
            {this.state.missionSelected.mapElements.map((area, i) => {
              return (
                <Button
                  key={'area-' + i}
                  title={this.state.isCoordinator ? 'Click to remove' : ''}
                  color="#e1e3e6"
                  size="sm"
                  onClick={
                    this.state.isCoordinator
                      ? () =>
                          this.setState({
                            itemToRemove: area.label,
                            isMapElementsConfirmationModalOpen: !this.state.isMapElementsConfirmationModalOpen,
                          })
                      : undefined
                  }
                >
                  {area.label}
                </Button>
              )
            })}
          </div>

          {this.buildMapElementsModal()}
          {this.buildMapElementsConfirmationModal()}
        </div>
      )
    }
  }

  private buildMapElementsModal() {
    return (
      <Modal
        isOpen={this.state.isMapElementsModalOpen}
        toggle={() => this.setState({ isMapElementsModalOpen: !this.state.isMapElementsModalOpen })}
      >
        <ModalHeader>Add area to mission</ModalHeader>

        <ModalBody>
          <select
            className="campaignUsers-input"
            defaultValue={''}
            onChange={(event: any) => this.updateMission('mapElements', event.target.value)}
            onClick={(event) => event.stopPropagation()}
          >
            <option value={''} disabled={true} onClick={(event) => event.stopPropagation()}>
              {'Choose area'}
            </option>

            {this.state.mapElementsAvailable.map((areaName, index) => {
              // check if area is already assigned
              let isAreaAvailable: boolean = false
              if (this.state.missionSelected) {
                this.state.missionSelected.mapElements.forEach((mapElement) => {
                  if (mapElement.label.includes(areaName)) {
                    isAreaAvailable = true
                  }
                })
              }
              return (
                <option
                  key={'areaOption-' + index}
                  value={areaName}
                  disabled={isAreaAvailable}
                  onClick={(event) => event.stopPropagation()}
                >
                  {areaName}
                </option>
              )
            })}
          </select>
        </ModalBody>
      </Modal>
    )
  }

  private buildMapElementsConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isMapElementsConfirmationModalOpen}
        toggle={() =>
          this.setState({
            itemToRemove: '',
            isMapElementsConfirmationModalOpen: !this.state.isMapElementsConfirmationModalOpen,
          })
        }
      >
        <ModalHeader
          toggle={() =>
            this.setState({
              itemToRemove: '',
              isMapElementsConfirmationModalOpen: !this.state.isMapElementsConfirmationModalOpen,
            })
          }
        >
          Remove area
        </ModalHeader>

        <ModalBody>
          The area <b>{this.state.itemToRemove}</b> will be removed from map elements. <br />
          Do you want to continue?
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={
              this.state.isCoordinator ? () => this.updateMission('mapElements', this.state.itemToRemove) : undefined
            }
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  public render() {
    if (this.state.missionSelected === undefined) {
      return (
        <>
          <SimpleNavbar auth={this.props} />
          <div className="missionTitle">Invalid mission...</div>
        </>
      )
    }

    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      return (
        <>
          <SimpleNavbar auth={this.props} />
          <div className="missionTitle">{this.state.missionSelected.name}</div>
          <div className={this.props.isDarkMode ? 'campaign darkmode' : 'campaign'}>
            <div className="header">
              {this.renderLeftContainer()}
              {this.state.isCoordinator ? this.renderRightContainer() : <></>}
            </div>

            {this.renderCoordinators()}
            {this.renderAnalysts()}
            {this.renderOperators()}
            {this.renderAssets()}
            {this.renderSystemsOfInterest()}
            {this.renderMapElements()}
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    isDarkMode: state.isDarkMode,
    campaignSelected: state.campaignSelected,
  }
}

const actionCreators = {
  setUser,
  setCampaign,
}

export default connect(mapStateToProps, actionCreators)(Mission)
