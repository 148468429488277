import ICampaign, { IMission } from '../model/ICampaign'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export async function fetchCampaignByName(campaignName: string, userEmail: string) {
  return request({
    url: `${apiURL}/campaign?name=${campaignName}&user=${userEmail}`,
  })
}

export async function fetchCampaignNames(user: string) {
  return request({
    url: `${apiURL}/campaign/names?user=${user}`,
  })
}

export async function fetchCampaignNamesByAsset(assetName: string) {
  return request({
    url: `${apiURL}/campaign/asset?assetName=${assetName}`,
  })
}

export async function createCampaign(name: string, value: string) {
  const action = 'CREATE'
  const key = 'leader'
  return request({
    method: 'POST',
    body: JSON.stringify({ action, name, key, value }),
    url: `${apiURL}/campaign`,
  })
}

export async function updateCampaign(name: string, key: string, value: string | number) {
  const action = 'UPDATE'
  return request({
    method: 'POST',
    body: JSON.stringify({ action, name, key, value }),
    url: `${apiURL}/campaign`,
  })
}

export async function removeCampaign(campaign: ICampaign) {
  return request({
    method: 'DELETE',
    body: JSON.stringify(campaign),
    url: `${apiURL}/campaign`,
  })
}

export async function fetchMission(missionId: string): Promise<IMission> {
  const response = await fetch(`${apiURL}/mission/?id=${missionId}`)
  const mission = await response.json()
  return mission
}

export async function createMission(name: string, key: string, value: string) {
  const action = 'CREATE'
  return request({
    method: 'POST',
    body: JSON.stringify({ action, name, key, value }),
    url: `${apiURL}/mission`,
  })
}

export async function updateMission(name: string, key: string, value: string | number) {
  const action = 'UPDATE'
  return request({
    method: 'POST',
    body: JSON.stringify({ action, name, key, value }),
    url: `${apiURL}/mission`,
  })
}

export async function removeMission(mission: IMission) {
  return request({
    method: 'DELETE',
    body: JSON.stringify(mission),
    url: `${apiURL}/mission`,
  })
}
