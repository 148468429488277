export default class CheckPosition {
  private land = [
    [41.87007214667565, -8.862190246582031],
    [41.84526843121213, -8.867683410644531],
    [41.83503729355457, -8.875579833984375],
    [41.82723495194037, -8.873691558837889],
    [41.817640605526826, -8.871803283691406],
    [41.8118833076401, -8.864765167236328],
    [41.79780773415637, -8.872489929199219],
    [41.77041548653221, -8.873348236083984],
    [41.74992765612834, -8.879356384277344],
    [41.73519798789358, -8.873863220214844],
    [41.699704829626036, -8.856697082519531],
    [41.69867946523769, -8.851890563964844],
    [41.68252781967316, -8.847427368164062],
    [41.68380984445887, -8.842277526855469],
    [41.690892570971236, -8.84472370147705],
    [41.691309177659996, -8.841590881347656],
    [41.688681305689016, -8.83798599243164],
    [41.697013213237994, -8.81601333618164],
    [41.69842312158612, -8.77756118774414],
    [41.69214238294329, -8.774127960205078],
    [41.6872711837914, -8.784255981445312],
    [41.687655765555995, -8.813095092773438],
    [41.69060414937538, -8.816184997558592],
    [41.6872711837914, -8.821678161621094],
    [41.68419444691368, -8.821163177490234],
    [41.681886797701594, -8.826141357421875],
    [41.68291242979089, -8.832321166992188],
    [41.6799636934724, -8.83472442626953],
    [41.67329648719081, -8.82596969604492],
    [41.649955826379, -8.826484680175781],
    [41.63558813788945, -8.819961547851562],
    [41.62930126680884, -8.821163177490234],
    [41.61993397475072, -8.813438415527344],
    [41.612362155265984, -8.811206817626953],
    [41.581038750695754, -8.80331039428711],
    [41.54250553376699, -8.793354034423828],
    [41.542120085565344, -8.790950775146484],
    [41.5201457403486, -8.776016235351562],
    [41.515133002270105, -8.76760482788086],
    [41.51346200330125, -8.762969970703125],
    [41.51346200330125, -8.757648468017578],
    [41.51397616142466, -8.751983642578125],
    [41.51796074840626, -8.743572235107422],
    [41.51628982244004, -8.742713928222656],
    [41.51153387396518, -8.751468658447266],
    [41.50999132914586, -8.762798309326172],
    [41.51346200330125, -8.771553039550781],
    [41.51539007520577, -8.776702880859375],
    [41.52785688696333, -8.784942626953125],
    [41.52759986354819, -8.786144256591797],
    [41.52387290928639, -8.78580093383789],
    [41.523101788498494, -8.78683090209961],
    [41.52554363946853, -8.789405822753906],
    [41.530298558481455, -8.790264129638672],
    [41.53017005175159, -8.791637420654297],
    [41.5254151232938, -8.79129409790039],
    [41.51539007520577, -8.787002563476562],
    [41.50253517770414, -8.789920806884766],
    [41.48903478831431, -8.783912658691406],
    [41.48054738902554, -8.776359558105469],
    [41.46356925533331, -8.775672912597656],
    [41.413895564677304, -8.787345886230469],
    [41.401921867920024, -8.778076171875],
    [41.39599857860365, -8.78030776977539],
    [41.39303673150584, -8.776187896728514],
    [41.38608229923676, -8.77429962158203],
    [41.380801585315346, -8.769493103027344],
    [41.377452617705394, -8.768119812011719],
    [41.377066187268866, -8.76485824584961],
    [41.375262814874915, -8.762454986572266],
    [41.37242884295152, -8.76211166381836],
    [41.37101181069003, -8.76382827758789],
    [41.3699812223855, -8.762798309326172],
    [41.36895061775508, -8.763656616210938],
    [41.36856413680967, -8.761253356933594],
    [41.36186476898025, -8.760223388671875],
    [41.358772520422285, -8.755760192871092],
    [41.3532318743157, -8.754215240478516],
    [41.3500103516271, -8.754215240478516],
    [41.343566827997414, -8.754386901855469],
    [41.34266468380401, -8.746318817138672],
    [41.34936602795912, -8.744773864746094],
    [41.35168556332073, -8.742027282714844],
    [41.3518144239732, -8.736190795898438],
    [41.3518144239732, -8.733272552490234],
    [41.357226341046065, -8.728981018066406],
    [41.35516471140717, -8.727607727050781],
    [41.35052580597025, -8.727779388427734],
    [41.3500103516271, -8.733100891113281],
    [41.3502680793088, -8.73636245727539],
    [41.34988148740366, -8.739967346191406],
    [41.346788669520244, -8.74237060546875],
    [41.343566827997414, -8.742713928222656],
    [41.34176252711261, -8.740482330322266],
    [41.33957152311568, -8.74237060546875],
    [41.34034482706094, -8.74408721923828],
    [41.33970040774419, -8.745288848876953],
    [41.33480261255644, -8.739967346191406],
    [41.310565997147854, -8.740653991699219],
    [41.264517402953786, -8.727436065673828],
    [41.260130127657625, -8.724174499511719],
    [41.24322345853027, -8.726921081542969],
    [41.227345275234555, -8.720569610595703],
    [41.226441522792406, -8.71713638305664],
    [41.218113501754814, -8.715333938598633],
    [41.20584549296149, -8.716621398925781],
    [41.19053954174602, -8.706064224243162],
    [41.18634108159364, -8.70718002319336],
    [41.18769753662004, -8.704776763916016],
    [41.18608270602165, -8.702974319458008],
    [41.18537216794189, -8.703317642211914],
    [41.18479081286916, -8.702716827392578],
    [41.18743916640052, -8.699455261230469],
    [41.1960294295703, -8.68417739868164],
    [41.19790234478943, -8.682546615600586],
    [41.197062768732174, -8.681688308715819],
    [41.19441480447213, -8.682546615600586],
    [41.19202508623781, -8.687009811401367],
    [41.18982905202979, -8.685379028320312],
    [41.188860189991225, -8.691902160644531],
    [41.18511378854675, -8.697996139526367],
    [41.18252993852274, -8.697137832641602],
    [41.180010586590996, -8.699026107788086],
    [41.17910618020693, -8.698253631591795],
    [41.17910618020693, -8.697052001953125],
    [41.17264577142037, -8.689155578613281],
    [41.16954454888413, -8.688297271728516],
    [41.167024697481565, -8.690099716186523],
    [41.162824729791815, -8.685722351074219],
    [41.15978766238589, -8.685979843139648],
    [41.147896512602706, -8.675251007080078],
    [41.14776724912472, -8.658084869384766],
    [41.14905987243725, -8.641433715820312],
    [41.14776724912472, -8.632850646972656],
    [41.14117447384262, -8.612937927246094],
    [41.14285504813144, -8.600234985351562],
    [41.14001097410736, -8.59405517578125],
    [41.1427257747151, -8.587703704833984],
    [41.14375995491069, -8.581352233886717],
    [41.138976734792514, -8.581352233886717],
    [41.13910601559875, -8.584785461425781],
    [41.136391065155486, -8.593883514404297],
    [41.137037492120484, -8.596630096435547],
    [41.13962313627547, -8.598861694335936],
    [41.13962313627547, -8.60504150390625],
    [41.13833032693901, -8.611736297607422],
    [41.137166776749034, -8.616714477539062],
    [41.137166776749034, -8.62100601196289],
    [41.14078664289095, -8.62478256225586],
    [41.141820853665614, -8.631134033203125],
    [41.14595753367537, -8.639202117919922],
    [41.14388922628842, -8.647270202636719],
    [41.13652035105814, -8.66048812866211],
    [41.13677892209895, -8.66323471069336],
    [41.14143302653628, -8.664779663085938],
    [41.14156230250088, -8.667354583740234],
    [41.13794247916908, -8.667011260986328],
    [41.133934584590065, -8.67044448852539],
    [41.113762108129244, -8.664264678955078],
    [41.088667173210624, -8.655681610107422],
    [41.06925730013503, -8.657398223876953],
    [41.049323867571616, -8.655509948730469],
    [41.03288071261766, -8.645038604736328],
    [41.021484621040024, -8.64400863647461],
    [41.009568416569934, -8.647613525390623],
    [41.007754892144106, -8.64572525024414],
    [40.99920474755419, -8.647613525390623],
    [40.995058823610506, -8.64572525024414],
    [40.98948732769741, -8.647441864013672],
    [40.98158276997307, -8.648643493652344],
    [40.973936476692614, -8.649845123291016],
    [40.96551156815455, -8.652591705322266],
    [40.9631297162844, -8.65345001220703],
    [40.96706701685124, -8.65100383758545],
    [40.96790953625355, -8.638472557067871],
    [40.96288666534169, -8.639287948608398],
    [40.96570600124669, -8.643579483032227],
    [40.96531713448955, -8.649373054504395],
    [40.962270932280745, -8.654179573059082],
    [40.957733774724154, -8.656539916992188],
    [40.95744208964048, -8.655166625976562],
    [40.94998747793444, -8.657441139221191],
    [40.94966334528767, -8.656926155090332],
    [40.94421767891174, -8.65774154663086],
    [40.8870435151357, -8.666839599609375],
    [40.83043687764923, -8.688812255859375],
    [40.70406634324247, -8.733444213867188],
    [40.64938745451835, -8.752670288085938],
    [40.67959657544238, -8.72314453125],
    [40.70094304347228, -8.719711303710936],
    [40.70510741061974, -8.656539916992188],
    [40.654075973582415, -8.63800048828125],
    [40.62541876792772, -8.692245483398438],
    [40.64990841734959, -8.707351684570312],
    [40.64000942809261, -8.725204467773438],
    [40.63688312646408, -8.74786376953125],
    [40.48978184687258, -8.792495727539062],
    [40.414018849687714, -8.814468383789062],
    [40.33345980653098, -8.843307495117188],
    [40.18569303805316, -8.906478881835938],
    [40.17887331434696, -8.905105590820312],
    [40.16359202197868, -8.878669738769531],
    [40.14673270055351, -8.872318267822266],
    [40.14922578219204, -8.857555389404297],
    [40.1475856072009, -8.855323791503906],
    [40.146863917657846, -8.831977844238281],
    [40.138859210445894, -8.802452087402344],
    [40.13754687338236, -8.800220489501953],
    [40.13321598124515, -8.799877166748047],
    [40.129016064673706, -8.799190521240234],
    [40.12888481309897, -8.804683685302734],
    [40.13754687338236, -8.825798034667969],
    [40.137415638281986, -8.837471008300781],
    [40.13833427866201, -8.850173950195312],
    [40.13045981526841, -8.863048553466797],
    [40.09448816916972, -8.874549865722656],
    [40.002371935876475, -8.914031982421875],
    [39.784795661568374, -9.018402099609375],
    [39.6088622615816, -9.085006713867188],
    [39.601984897284666, -9.07196044921875],
    [39.588095829645454, -9.073333740234375],
    [39.58558227224178, -9.06972885131836],
    [39.581745614097564, -9.072475433349608],
    [39.584127013122675, -9.079170227050781],
    [39.51847630364586, -9.141998291015625],
    [39.51251701659638, -9.140968322753906],
    [39.51304675170201, -9.136161804199219],
    [39.50576253994294, -9.135475158691406],
    [39.50231882856372, -9.139595031738281],
    [39.50324599841432, -9.147491455078123],
    [39.50801410511705, -9.151439666748045],
    [39.4956958270616, -9.173240661621094],
    [39.47423296775743, -9.19607162475586],
    [39.43141996823385, -9.232120513916016],
    [39.391897322129694, -9.289112091064453],
    [39.378098910713184, -9.315032958984375],
    [39.3714640880158, -9.337177276611328],
    [39.36496135004594, -9.342327117919922],
    [39.359519818280745, -9.357089996337889],
    [39.362572437084125, -9.371166229248047],
    [39.36973893083485, -9.386787414550781],
    [39.3660230629119, -9.403438568115234],
    [39.35991799351635, -9.406700134277344],
    [39.35381239049528, -9.392280578613281],
    [39.354210598262206, -9.380435943603514],
    [39.356997989081975, -9.376487731933594],
    [39.35567067398999, -9.364471435546875],
    [39.35155583694641, -9.367218017578125],
    [39.341201304033454, -9.360008239746092],
    [39.32447151024322, -9.358634948730469],
    [39.31198794598777, -9.344902038574219],
    [39.27186610203728, -9.333915710449219],
    [39.24156021037737, -9.341812133789062],
    [39.21310328979648, -9.3438720703125],
    [39.181973639117345, -9.355545043945312],
    [39.15136267949029, -9.370307922363281],
    [39.11940660636222, -9.391937255859375],
    [39.074377212242275, -9.420433044433592],
    [39.062382289289836, -9.414596557617186],
    [39.02051737539191, -9.423179626464844],
    [38.9991753950833, -9.42352294921875],
    [38.962078391086926, -9.41802978515625],
    [38.950064405159814, -9.414939880371094],
    [38.92496195301519, -9.421463012695312],
    [38.886757140695906, -9.43794250488281],
    [38.82312593339483, -9.472274780273438],
    [38.77871080859691, -9.499053955078125],
    [38.75435101206233, -9.474678039550781],
    [38.75060261288245, -9.471244812011719],
    [38.73774946428237, -9.471931457519531],
    [38.70935723235625, -9.484634399414062],
    [38.69676461413586, -9.463348388671875],
    [38.69596075470557, -9.449272155761719],
    [38.69542484339968, -9.44000244140625],
    [38.69086943518648, -9.424552917480469],
    [38.694620968912254, -9.420433044433592],
    [38.700247900602726, -9.418716430664062],
    [38.70453478951588, -9.404983520507812],
    [38.68202575584783, -9.345588684082031],
    [38.67478920520597, -9.323616027832031],
    [38.69676461413586, -9.289283752441406],
    [38.700515838688716, -9.274864196777344],
    [38.701587580994214, -9.252548217773438],
    [38.699444080321705, -9.239845275878906],
    [38.69596075470557, -9.225425720214844],
    [38.69515688624102, -9.205856323242188],
    [38.69864025100615, -9.186630249023438],
    [38.70480271154007, -9.167404174804688],
    [38.70641022260375, -9.142684936523436],
    [38.717126039425644, -9.117965698242188],
    [38.74123075381228, -9.100799560546875],
    [38.799584013897054, -9.092559814453125],
    [38.83756825896614, -9.0802001953125],
    [38.86216695339701, -9.063034057617188],
    [38.886757140695906, -9.026641845703123],
    [38.88408470638818, -8.979949951171873],
    [38.77068102544212, -8.927078247070312],
    [38.71391146304093, -9.035568237304688],
    [38.701051711849146, -9.040374755859375],
    [38.70265930723801, -8.96209716796875],
    [38.694620968912254, -8.95111083984375],
    [38.67746949445934, -9.003295898437498],
    [38.654951945545015, -8.994369506835938],
    [38.66299474019031, -9.054794311523438],
    [38.66138625351512, -9.079513549804688],
    [38.638863646894464, -9.056854248046873],
    [38.59809045854761, -9.046554565429688],
    [38.6329636990003, -9.080886840820312],
    [38.63457282385875, -9.098739624023438],
    [38.61901643727865, -9.10491943359375],
    [38.64100896209024, -9.149551391601562],
    [38.65334327823747, -9.13581848144531],
    [38.67746949445934, -9.161224365234375],
    [38.66299474019031, -9.255294799804688],
    [38.61311485607905, -9.214096069335938],
    [38.53044174921205, -9.1790771484375],
    [38.46111640240984, -9.1900634765625],
    [38.41647645943579, -9.218215942382812],
    [38.41378642476067, -9.194869995117188],
    [38.436379603, -9.151611328125],
    [38.4428334985915, -9.102859497070312],
    [38.43906879628627, -9.065093994140625],
    [38.464879954577896, -8.997116088867188],
    [38.489606982959856, -8.968963623046875],
    [38.48745714328253, -8.938064575195312],
    [38.51647456686519, -8.909225463867188],
    [38.522384090200845, -8.887252807617188],
    [38.50841534409804, -8.8494873046875],
    [38.489606982959856, -8.812408447265625],
    [38.49014443285755, -8.793869018554688],
    [38.50465406475561, -8.776016235351562],
    [38.53849850597664, -8.784942626953125],
    [38.54762840618704, -8.732757568359375],
    [38.44498466889473, -8.718338012695312],
    [38.41109628993052, -8.649673461914062],
    [38.41486245064945, -8.576202392578125],
    [38.4014109752926, -8.576202392578125],
    [38.40840605494758, -8.757476806640625],
    [38.40679186588536, -8.778762817382812],
    [38.40410147066251, -8.7890625],
    [38.38095993790538, -8.788375854492188],
    [38.375577124692164, -8.798675537109373],
    [38.25974980039479, -8.77326965332031],
    [38.148057572086444, -8.784942626953125],
    [38.050794662666895, -8.821334838867188],
    [37.95502661288625, -8.881072998046875],
    [37.93444993515032, -8.812408447265625],
    [37.87051721701939, -8.791809082031248],
    [37.80381638220768, -8.796615600585938],
    [37.74520008134973, -8.80828857421875],
    [37.72021976910832, -8.788375854492188],
    [37.59845611922412, -8.815155029296875],
    [37.53804390907164, -8.78631591796875],
    [37.44433544620035, -8.797988891601562],
    [37.37943348292772, -8.824081420898438],
    [37.318844002395444, -8.873519897460938],
    [37.29699797218557, -8.870086669921875],
    [37.27186719156333, -8.8604736328125],
    [37.19806537087233, -8.900985717773436],
    [37.19587754834048, -8.913345336914062],
    [37.18821967018367, -8.90716552734375],
    [37.17344871200958, -8.906478881835938],
    [37.16852441744235, -8.90167236328125],
    [37.0266767305112, -8.990249633789062],
    [37.02832127378562, -8.966903686523438],
    [37.00584276741093, -8.946304321289062],
    [37.00693943418586, -8.930511474609375],
    [37.021194662451606, -8.9263916015625],
    [37.04421668967971, -8.895492553710938],
    [37.066683840893084, -8.820648193359375],
    [37.07490186820611, -8.759536743164062],
    [37.08640561029594, -8.733444213867188],
    [37.08257122355467, -8.669586181640625],
    [37.10393176520052, -8.669586181640625],
    [37.12035885341966, -8.649673461914062],
    [37.124191327897925, -8.601608276367188],
    [37.11707372086296, -8.570022583007812],
    [37.11762125285503, -8.532943725585938],
    [37.13103454969984, -8.535261154174805],
    [37.13828768922088, -8.534660339355469],
    [37.138013998466505, -8.529338836669922],
    [37.135208611147924, -8.524703979492188],
    [37.12651809273554, -8.524103164672852],
    [37.11734748735395, -8.521013259887695],
    [37.10612224951858, -8.519468307495117],
    [37.08859660134791, -8.435440063476562],
    [37.100098265217156, -8.383255004882812],
    [37.10119357072203, -8.356475830078125],
    [37.07818882978002, -8.307037353515625],
    [37.073806182683725, -8.293991088867188],
    [37.080927855576014, -8.265838623046875],
    [37.08804885952269, -8.221893310546875],
    [37.09133525109512, -8.180694580078125],
    [37.068327517596586, -8.104476928710938],
    [37.046956942312335, -8.0584716796875],
    [37.00913272027146, -7.9657745361328125],
    [37.010777643303705, -7.9163360595703125],
    [37.02832127378562, -7.829132080078125],
    [37.04914907319391, -7.761840820312501],
    [37.07490186820611, -7.71240234375],
    [37.10612224951858, -7.64923095703125],
    [37.14444546942506, -7.581253051757812],
    [37.16633573907469, -7.513275146484376],
    [37.17782559332976, -7.4494171142578125],
    [37.177278497031615, -7.410964965820313],
    [37.19751842118354, -7.296295166015625],
    [37.209003532428646, -7.161712646484375],
    [41.88592102814744, -6.723632812499999],
    [41.95949009892467, -8.72589111328125],
    [41.87007214667565, -8.862190246582031],
  ]

  private portoTraffic = [
    [41.38903587949705, -8.791809082031252],
    [41.40139823865542, -8.942871093750002],
    [41.34535389604626, -8.951797485351564],
    [41.27108056081835, -8.899612426757814],
    [41.14787927775002, -8.84227752685547],
    [41.235203091626914, -8.840904235839846],
    [41.19233080306874, -8.792152404785158],
    [41.140123015983754, -8.792495727539064],
    [41.13624454108751, -8.802452087402346],
    [41.10494979070845, -8.79936218261719],
    [41.09744717931651, -8.780479431152346],
    [41.071828359553606, -8.794555664062502],
    [41.14064012864206, -8.830604553222658],
    [41.078039899636885, -8.83953094482422],
    [41.02600012683284, -8.828201293945314],
    [40.60130245470004, -9.077453613281252],
    [40.96899399437468, -8.840217590332033],
    [41.014343792370966, -8.804168701171877],
    [41.02185588869759, -8.807945251464846],
    [41.02859014323238, -8.798675537109377],
    [41.014602844436986, -8.792152404785158],
    [41.047494176575626, -8.786659240722658],
    [41.09951695082217, -8.743743896484377],
    [41.08657980888762, -8.744773864746096],
    [41.08269816952203, -8.728294372558596],
    [41.168816602029345, -8.700141906738283],
    [41.19195834513989, -8.681902885437013],
    [41.185354942943434, -8.712844848632814],
    [41.17367732613455, -8.709325790405275],
    [41.251965570640934, -8.750610351562502],
    [41.25412510457959, -8.749237060546877],
    [41.25089868222096, -8.75739097595215],
    [41.239346783838045, -8.76262664794922],
    [41.19131030678462, -8.73241424560547],
    [41.20416222885402, -8.750095367431642],
    [41.19783345696883, -8.756103515625002],
    [41.29015556108616, -8.84056091308594],
    [41.37145839395074, -8.761768341064455],
    [41.37925163591116, -8.766489028930666],
    [41.37165162611383, -8.772497177124025],
    [41.389036145710854, -8.791803717613222],
  ]

  private aveiroTraffic = [
    [40.63973154080813, -8.678855895996096],
    [40.63973154080813, -8.687438964843752],
    [40.66447636403352, -8.704605102539064],
    [40.66004901717681, -8.732070922851564],
    [40.644160235928815, -8.765029907226564],
    [40.68009994512877, -8.812751770019533],
    [40.672288612185994, -8.819274902343752],
    [40.703788722791614, -8.810691833496096],
    [40.70717214637205, -8.777732849121096],
    [40.71081564107525, -8.814125061035158],
    [40.64077361315394, -8.88072967529297],
    [40.59933870971963, -8.843994140625002],
    [40.60116342867947, -8.828201293945314],
    [40.49733604343816, -8.86528015136719],
    [40.489764440173914, -8.856353759765627],
    [40.58317473897628, -8.805198669433596],
    [40.601684767805125, -8.812751770019533],
    [40.61471692444704, -8.788719177246096],
    [40.60585533443119, -8.763313293457033],
    [40.61523815785138, -8.763999938964846],
    [40.617062442744, -8.77738952636719],
    [40.63347876511752, -8.76537322998047],
    [40.64077361315394, -8.759536743164064],
    [40.642076180709786, -8.734130859375002],
    [40.62983104219078, -8.736534118652346],
    [40.63165492856278, -8.72795104980469],
    [40.641034128698585, -8.728981018066408],
    [40.63426039410494, -8.681602478027346],
    [40.63947102017987, -8.678855895996096],
  ]

  private figueiraFozTraffic = [
    [40.14737128834337, -8.84330749511719],
    [40.147896150305634, -8.872833251953127],
    [40.14474691769878, -8.878669738769533],
    [40.18829834151713, -8.923988342285158],
    [40.14290979792178, -8.948707580566408],
    [40.11587214937107, -8.89720916748047],
    [40.130573564279025, -8.868713378906252],
    [40.135035864760255, -8.873863220214846],
    [40.143172246645825, -8.838500976562502],
    [40.14736828104307, -8.84330749511719],
  ]

  private penicheTraffic = [
    [39.358440309827486, -9.37065124511719],
    [39.352069098936425, -9.384727478027346],
    [39.36003302176525, -9.408416748046877],
    [39.37595814376175, -9.40086364746094],
    [39.37861197767225, -9.419403076171877],
    [39.42477253602148, -9.454078674316408],
    [39.350476205430645, -9.426956176757814],
    [39.31648582754111, -9.400177001953127],
    [39.25084832040343, -9.410133361816408],
    [39.25031659158959, -9.397773742675783],
    [39.35764394024068, -9.363784790039064],
    [39.35844127736431, -9.37065124511719],
  ]

  private lisboaTraffic = [
    [38.76579133801277, -9.102172851562502],
    [38.721876205377065, -9.131011962890627],
    [38.69186986450735, -9.319152832031252],
    [38.71008950187591, -9.512786865234377],
    [38.64361901007572, -9.424896240234377],
    [38.62323666532157, -9.415283203125002],
    [38.63181941155078, -9.471588134765627],
    [38.60177530539911, -9.413909912109377],
    [38.579233968377316, -9.437255859375002],
    [38.67042905469804, -9.264221191406252],
    [38.637183106229806, -9.066467285156252],
    [38.686510264279846, -9.048614501953127],
    [38.70794625605491, -9.102172851562502],
    [38.764720558572975, -9.080200195312502],
    [38.765791336991605, -9.102172851562502],
  ]

  private setubalTraffic = [
    [38.57553007110255, -9.333229064941408],
    [38.58411852446539, -9.238471984863283],
    [38.52773805781796, -9.21409606933594],
    [38.41296146100057, -9.223709106445314],
    [38.4083881199853, -9.202423095703127],
    [38.43447917961099, -9.11418914794922],
    [38.44012651846887, -9.11521911621094],
    [38.44039542834762, -9.110412597656252],
    [38.43178981536331, -9.073677062988283],
    [38.453839644985564, -8.97411346435547],
    [38.52048593283972, -8.898925781250002],
    [38.52129176059172, -8.885192871093752],
    [38.48770796148981, -8.820991516113283],
    [38.48127375734014, -8.817043304443361],
    [38.47475610482836, -8.808803558349611],
    [38.4700858780407, -8.801636695861818],
    [38.472135439273856, -8.79532814025879],
    [38.470657073092354, -8.79086494445801],
    [38.47821658134445, -8.788933753967287],
    [38.47099306806795, -8.782067298889162],
    [38.466658612712855, -8.785543441772463],
    [38.460442078602796, -8.80210876464844],
    [38.47156425593328, -8.859443664550783],
    [38.465583358651266, -8.85927200317383],
    [38.465583358651266, -8.863735198974611],
    [38.472706618089674, -8.869228363037111],
    [38.48305425064258, -8.877553939819338],
    [38.494105762843596, -8.89274597167969],
    [38.49319886367766, -8.90420436859131],
    [38.49568441193771, -8.915748596191408],
    [38.48634636768724, -8.925962448120119],
    [38.44523563486059, -8.970680236816408],
    [38.403276396336075, -8.96141052246094],
    [38.401662092704754, -8.967590332031252],
    [38.433672380854624, -8.977203369140627],
    [38.37071430606878, -9.00157928466797],
    [38.4091952011964, -9.056854248046877],
    [38.446042304389486, -8.981323242187502],
    [38.43098298655582, -9.046211242675783],
    [38.415113521324656, -9.070587158203127],
    [38.4204933916954, -9.08226013183594],
    [38.41834149162315, -9.09221649169922],
    [38.39816431115837, -9.086036682128908],
    [38.349716097182586, -9.068870544433596],
    [38.415382524358165, -9.110412597656252],
    [38.425334932480645, -9.112815856933596],
    [38.40300734823452, -9.167747497558596],
    [38.39789524402938, -9.211006164550783],
    [38.403545443436194, -9.23503875732422],
    [38.40435257872771, -9.250488281250002],
    [38.41349948209089, -9.257354736328127],
    [38.41834149162315, -9.23881530761719],
    [38.431251930493346, -9.246711730957033],
    [38.434748110526336, -9.23606872558594],
    [38.4874392279558, -9.24156188964844],
    [38.50571082525894, -9.228515625000002],
    [38.57284596882329, -9.248085021972658],
    [38.568819627373294, -9.31743621826172],
    [38.57526166538744, -9.333229064941408],
  ]

  private sinesTraffic = [
    [37.95311358610707, -8.886909484863283],
    [37.94796982292037, -8.874893188476564],
    [37.954196437728626, -8.873863220214846],
    [37.947428353213844, -8.857383728027346],
    [37.93984735833858, -8.857727050781252],
    [37.92414138116019, -8.830604553222658],
    [37.90138884516638, -8.831634521484377],
    [37.87375130646574, -8.849487304687502],
    [37.889738990708906, -8.857727050781252],
    [37.88621657873562, -8.871803283691408],
    [37.89597053755331, -8.899612426757814],
    [37.91059905267101, -8.899269104003908],
    [37.92468302243709, -8.91918182373047],
    [37.93768121615543, -8.900642395019533],
    [37.956091389656365, -8.90373229980469],
    [37.96529474648063, -8.900299072265627],
    [37.94147192307953, -8.892059326171877],
    [37.93559850026357, -8.888368606567385],
    [37.952249549059964, -8.889012336730959],
    [37.95311471381044, -8.886909484863283],
  ]

  public aveiroArea = [
    [-8.801843105266867, 40.484976114893961],
    [-8.800663754912224, 40.484982932854528],
    [-8.799484404154846, 40.484989738782119],
    [-8.798305052995472, 40.484996532676703],
    [-8.79712570143484, 40.485003314538318],
    [-8.795946349473612, 40.485010084366891],
    [-8.794793527068553, 40.48771829355271],
    [-8.793649442844265, 40.491326974002483],
    [-8.792505238489714, 40.494935641844116],
    [-8.791360913972536, 40.498544297073245],
    [-8.790216469260312, 40.502152939685473],
    [-8.789063131488096, 40.504861087805956],
    [-8.789071904320679, 40.50576156967648],
    [-8.787927219121167, 40.509370187041874],
    [-8.786782413629393, 40.512978791777343],
    [-8.785637487812906, 40.516587383878488],
    [-8.784483725612054, 40.519295482903821],
    [-8.783329871844153, 40.522003569862228],
    [-8.782184613298725, 40.525612124753351],
    [-8.782193300498662, 40.526512604614076],
    [-8.782201988090851, 40.527413084332423],
    [-8.782210676075293, 40.528313563908462],
    [-8.782219364452009, 40.529214043342201],
    [-8.78107392855908, 40.532822584142636],
    [-8.779928372168365, 40.536431112286259],
    [-8.778774049567545, 40.539139149415732],
    [-8.777619635279958, 40.541847174459214],
    [-8.7776282663269, 40.542747652593555],
    [-8.776482362496413, 40.546356143101761],
    [-8.776490979684556, 40.547256620732469],
    [-8.776499597261873, 40.548157098220877],
    [-8.77535354430047, 40.551765575331828],
    [-8.774190192935771, 40.553573086092022],
    [-8.774198781610623, 40.554473562999057],
    [-8.77420737067335, 40.555374039763798],
    [-8.77421596012398, 40.556274516386232],
    [-8.773069651532568, 40.559882967772445],
    [-8.771923222204984, 40.563491406470568],
    [-8.770776672108637, 40.56709983247616],
    [-8.769621468857059, 40.569807770613984],
    [-8.769630001211002, 40.570708245784942],
    [-8.768483209479495, 40.574316646392482],
    [-8.768491727930423, 40.575217121057406],
    [-8.768500246766118, 40.576117595580016],
    [-8.767353305579963, 40.579725982753757],
    [-8.766189263384566, 40.581533409486369],
    [-8.76504210895601, 40.585141771963912],
    [-8.763886372702167, 40.587849648374039],
    [-8.762738990627421, 40.591457985775264],
    [-8.761591487528699, 40.595066310449347],
    [-8.760435445853416, 40.597774149998415],
    [-8.759287714939349, 40.601382449571609],
    [-8.759296118128756, 40.602282921598551],
    [-8.75930452169777, 40.603183393483214],
    [-8.760502796660923, 40.604977925162437],
    [-8.761701136181808, 40.606772443377331],
    [-8.762899540268281, 40.608566948125528],
    [-8.761751764974893, 40.612175265848961],
    [-8.758223768499347, 40.613995446602829],
    [-8.755868876996542, 40.614908684182808],
    [-8.754695579792021, 40.615815520421194],
    [-8.754703925604931, 40.616715990978427],
    [-8.754712271794906, 40.617616461393389],
    [-8.754720618361967, 40.618516931666107],
    [-8.753572238463988, 40.622125166390887],
    [-8.748836886219495, 40.621249994999722],
    [-8.748828617855201, 40.620349524153745],
    [-8.747613871515714, 40.616753932753909],
    [-8.747605620882249, 40.61585346099789],
    [-8.747597370621541, 40.614952989099656],
    [-8.747589120733524, 40.614052517059193],
    [-8.74758087121824, 40.613152044876465],
    [-8.748729426614977, 40.609543862907472],
    [-8.748721163105326, 40.608643390212414],
    [-8.747506642348846, 40.605047788831726],
    [-8.748655068465347, 40.601439603531553],
    [-8.748646808314721, 40.600539129556381],
    [-8.749795098860869, 40.596930931119658],
    [-8.750959846318798, 40.595123669207432],
    [-8.75095155708752, 40.594223194635255],
    [-8.750943268230657, 40.59332271992092],
    [-8.750934979748211, 40.59242224506427],
    [-8.750926691640149, 40.591521770065441],
    [-8.750918403906482, 40.590621294924347],
    [-8.750910116547125, 40.589720819641045],
    [-8.750901829562133, 40.588820344215534],
    [-8.750893542951454, 40.587919868647752],
    [-8.752033204544309, 40.583411166397646],
    [-8.753197661499916, 40.581603879860246],
    [-8.754353725869473, 40.57889610509153],
    [-8.75434539587552, 40.577995628559734],
    [-8.755484665289874, 40.573486887474438],
    [-8.755476321724164, 40.572586410290121],
    [-8.755467978535284, 40.571685932963568],
    [-8.756590357693439, 40.565376221142664],
    [-8.762418304404409, 40.557239906986382],
    [-8.763590483188581, 40.556332991533104],
    [-8.764762630341858, 40.555426064281399],
    [-8.769442378409211, 40.550897759154118],
    [-8.769433854528339, 40.54999728085275],
    [-8.769425331032414, 40.549096802409125],
    [-8.769416807921365, 40.548196323823198],
    [-8.769408285195214, 40.547295845094986],
    [-8.769357156919476, 40.541892969737987],
    [-8.769348636886896, 40.540992490013835],
    [-8.771649464650025, 40.534676107175152],
    [-8.772821128332581, 40.533769098456425],
    [-8.773975602063665, 40.531061117167987],
    [-8.775129984121774, 40.528353123787795],
    [-8.776267059204129, 40.523844156084856],
    [-8.776258452126587, 40.522943674754288],
    [-8.776249845437658, 40.522043193281412],
    [-8.77624123913729, 40.521142711666272],
    [-8.776232633225511, 40.520242229908845],
    [-8.775069839672369, 40.522049755013789],
    [-8.77041804549169, 40.529279737520909],
    [-8.766920063311559, 40.533801619258007],
    [-8.765756788660873, 40.535609050068118],
    [-8.76576525898744, 40.536509531260442],
    [-8.765773729696511, 40.537410012310531],
    [-8.765782200788063, 40.538310493218326],
    [-8.765790672262121, 40.53921097398387],
    [-8.768219328229039, 40.546401861204551],
    [-8.765926267885765, 40.553618646885361],
    [-8.757720902999658, 40.559966972019588],
    [-8.756556935968296, 40.561774307236448],
    [-8.755392906789433, 40.563581630624071],
    [-8.75194192293873, 40.573505920140633],
    [-8.750785851066244, 40.576213673324418],
    [-8.749629687197741, 40.578921414353623],
    [-8.748473431315002, 40.581629143224973],
    [-8.748481683633907, 40.582529620186762],
    [-8.748489936325575, 40.583430097006293],
    [-8.747350036028299, 40.587938766016983],
    [-8.746201761522808, 40.591546945585854],
    [-8.743872068629891, 40.595161364781568],
    [-8.741542126634299, 40.598775736539999],
    [-8.74155029094031, 40.599676211986313],
    [-8.743954030537271, 40.604166114593177],
    [-8.738046746436336, 40.604197205193486],
    [-8.736865288513012, 40.604203387061865],
    [-8.735708093065234, 40.606910983134973],
    [-8.735716181409854, 40.607811458280153],
    [-8.735724270119855, 40.608711933283161],
    [-8.738127952512022, 40.61320195060938],
    [-8.74052380400725, 40.616791439319584],
    [-8.740531959829017, 40.617691912118403],
    [-8.741746344972144, 40.621287580037539],
    [-8.739390970057825, 40.622200483938606],
    [-8.73824983043419, 40.62670904207225],
    [-8.73707608820208, 40.627615700647667],
    [-8.735902314225083, 40.628522347377555],
    [-8.732380801810216, 40.631242216482988],
    [-8.731231007159963, 40.634850231214848],
    [-8.73007306955494, 40.637557761841421],
    [-8.730081090930002, 40.638458233122208],
    [-8.73008911266755, 40.639358704260822],
    [-8.730097134767524, 40.640259175257306],
    [-8.724210485372565, 40.64299099423873],
    [-8.723036268957996, 40.643897510885708],
    [-8.721909504550354, 40.650206842452683],
    [-8.718370553607585, 40.651125344509978],
    [-8.713649198086975, 40.652049688768322],
    [-8.708974164161155, 40.658376672253986],
    [-8.705419209378709, 40.657493836578389],
    [-8.700666695356331, 40.654815762167708],
    [-8.699461494160209, 40.65212014634001],
    [-8.699408205994724, 40.645816825076963],
    [-8.695831353729279, 40.642232260104372],
    [-8.691065313129201, 40.6377528195938],
    [-8.686285047638028, 40.631472216571098],
    [-8.686277616551241, 40.630571737273414],
    [-8.683854566695551, 40.62337919352084],
    [-8.683847170368436, 40.622478712588453],
    [-8.681424682792271, 40.61528610419122],
    [-8.682576839622673, 40.611678557745634],
    [-8.683743656867934, 40.609871964627999],
    [-8.684895599002322, 40.60626439309052],
    [-8.688395397811854, 40.60084450564694],
    [-8.689554406050586, 40.598137368759524],
    [-8.689546939827553, 40.597236884746579],
    [-8.689539473941783, 40.59633640059171],
    [-8.689532008393254, 40.595435916294846],
    [-8.688313451102106, 40.590939176405605],
    [-8.68589166229823, 40.583746618153548],
    [-8.6858842489825, 40.582846131331564],
    [-8.68587683600162, 40.581945644367657],
    [-8.685869423355536, 40.58104515726184],
    [-8.684658778082618, 40.577448852231299],
    [-8.67987613740832, 40.570267397147902],
    [-8.681049665147462, 40.569361312379918],
    [-8.681042320492528, 40.568460822575787],
    [-8.68103497616929, 40.567560332629718],
    [-8.681020288517892, 40.565759352311922],
    [-8.681012945189684, 40.564858861940131],
    [-8.675036428955083, 40.555881791164552],
    [-8.673855807730506, 40.555887323122562],
    [-8.672675186178767, 40.555892843016707],
    [-8.677507093459006, 40.569378060958201],
    [-8.679920119317881, 40.575670333201899],
    [-8.683529444192327, 40.583757898200282],
    [-8.683536826165955, 40.584658385236764],
    [-8.685958397210458, 40.591850993163348],
    [-8.686039998955717, 40.601756324782478],
    [-8.684865977662938, 40.602662458432476],
    [-8.680176918159551, 40.607187358352093],
    [-8.677835807739095, 40.609899979081753],
    [-8.677843120093002, 40.610800462886857],
    [-8.679068682674203, 40.616197782785534],
    [-8.679076013205311, 40.617098265420758],
    [-8.679083344067628, 40.617998747914065],
    [-8.679090675261129, 40.618899230265406],
    [-8.679098006785843, 40.619799712474823],
    [-8.670861892187865, 40.624340938210658],
    [-8.669680065563931, 40.624346435098893],
    [-8.668498238614433, 40.624351919894217],
    [-8.66731641134006, 40.624357392596636],
    [-8.664995619031027, 40.629771200286172],
    [-8.665002764029389, 40.630671682883396],
    [-8.668577353906647, 40.63425722433076],
    [-8.667402530382788, 40.635163180478408],
    [-8.668591742730326, 40.63605818692885],
    [-8.6709991739181, 40.641450083039544],
    [-8.6674671503149, 40.643267507978855],
    [-8.663934934461116, 40.645084825515724],
    [-8.662759859393294, 40.645990734317486],
    [-8.66166290835797, 40.656801907983443],
    [-8.672471337551549, 40.677463648565279],
    [-8.667761862804866, 40.680187076670769],
    [-8.665403399671057, 40.681098481833516],
    [-8.664227717661547, 40.682004403766896],
    [-8.661883374784006, 40.684716686482588],
    [-8.657187065861951, 40.68924063437828],
    [-8.657194120743696, 40.690141108805072],
    [-8.658391253954768, 40.691936680644353],
    [-8.667898622172299, 40.697296064180371],
    [-8.667927428587369, 40.700897949757476],
    [-8.667934631004995, 40.701798420796848],
    [-8.667941833748234, 40.702698891694268],
    [-8.671520470413249, 40.706284272113017],
    [-8.671527722593655, 40.707184741779457],
    [-8.672740087773324, 40.710781093313727],
    [-8.671563988413846, 40.711687087981787],
    [-8.670387857173811, 40.712593070726484],
    [-8.665675837636853, 40.71531641333528],
    [-8.660963433191355, 40.718039564699041],
    [-8.658603545977217, 40.718950833842406],
    [-8.653883577314318, 40.720773228142242],
    [-8.649142486432265, 40.71989401851377],
    [-8.647958967396788, 40.719899303299819],
    [-8.646775448047654, 40.719904575952235],
    [-8.645591928385583, 40.719909836470997],
    [-8.638504425337647, 40.721742089171336],
    [-8.637320871609266, 40.721747265081177],
    [-8.636137317573665, 40.721752428856561],
    [-8.634953763231564, 40.721757580497467],
    [-8.627852430783184, 40.721788235517884],
    [-8.624288521776542, 40.720002450944961],
    [-8.624281902873756, 40.719101976598886],
    [-8.621921470535927, 40.720012498766671],
    [-8.619560973514929, 40.720922972890392],
    [-8.616023360819957, 40.722738831477621],
    [-8.613662686293575, 40.723649185555807],
    [-8.611308392319517, 40.725459966794475],
    [-8.610131197477545, 40.726365339461871],
    [-8.610137627339888, 40.727265814554535],
    [-8.61017621261945, 40.732668662133214],
    [-8.617291776130072, 40.734440034213407],
    [-8.618475558338613, 40.734435062393608],
    [-8.622013719933429, 40.73261912916373],
    [-8.624374594749467, 40.731708604538554],
    [-8.626735404840071, 40.730798031852693],
    [-8.627905780409579, 40.72899201908097],
    [-8.629062723151169, 40.725385048958813],
    [-8.632606848877675, 40.724469265344119],
    [-8.638524859831216, 40.724443504699458],
    [-8.639715289165816, 40.725338787558769],
    [-8.645661005030584, 40.728914542882308],
    [-8.646851609536881, 40.729809750396704],
    [-8.648035304347722, 40.729804475912516],
    [-8.650402693027122, 40.729793890530665],
    [-8.651586386894282, 40.729788579632967],
    [-8.652770080445395, 40.729783256597436],
    [-8.657511920723534, 40.730662311117207],
    [-8.659886437713892, 40.731551998747626],
    [-8.662261017566156, 40.732441636999667],
    [-8.663444756108481, 40.73243620420957],
    [-8.670525445301328, 40.729701954532466],
    [-8.671723655446382, 40.731497369100261],
    [-8.672914652748867, 40.732392304889984],
    [-8.67530405332368, 40.735082603316584],
    [-8.675311363881614, 40.735983067914127],
    [-8.6753186747701, 40.736883532369625],
    [-8.676517144200739, 40.738678893306925],
    [-8.677723022941466, 40.741374704224107],
    [-8.678929000122906, 40.744070500662126],
    [-8.678958449175306, 40.747672350404514],
    [-8.677781790648513, 40.748578406257977],
    [-8.676619764152976, 40.751285374333058],
    [-8.677840579618309, 40.755782099201262],
    [-8.677847929735018, 40.756682560179961],
    [-8.677855280184104, 40.757583021016636],
    [-8.677862630965576, 40.758483481711274],
    [-8.677869982079477, 40.75938394226381],
    [-8.677877333525835, 40.760284402674351],
    [-8.677884685304626, 40.761184862942805],
    [-8.677892037415871, 40.762085323069229],
    [-8.679105774363865, 40.76568156502497],
    [-8.679113144121567, 40.766582024264423],
    [-8.679120514212563, 40.767482483361817],
    [-8.679127884636888, 40.768382942317153],
    [-8.682718098961971, 40.772868366397375],
    [-8.682725519355273, 40.773768823967821],
    [-8.682755204284682, 40.777370652828765],
    [-8.681592891502943, 40.780077659417081],
    [-8.679245856770224, 40.782790266281829],
    [-8.678068587871376, 40.783696323486389],
    [-8.676891286976605, 40.784602368741488],
    [-8.675713954084438, 40.785508402045927],
    [-8.674536589193446, 40.786414423398611],
    [-8.673359192302081, 40.787320432798346],
    [-8.67218176340889, 40.788226430244023],
    [-8.667471727788309, 40.791850300462947],
    [-8.662725401595516, 40.790971692233505],
    [-8.661540624000152, 40.790977124019776],
    [-8.660398589088512, 40.796385289781007],
    [-8.659220823764645, 40.797291155627306],
    [-8.658043026422616, 40.79819700950614],
    [-8.65686519706095, 40.799102851416329],
    [-8.655687335678172, 40.800008681356722],
    [-8.654509442272781, 40.800914499326225],
    [-8.650968570798254, 40.80273142384366],
    [-8.649790564985699, 40.803637193892214],
    [-8.648612527144603, 40.804542951964486],
    [-8.646263291678089, 40.807254889830048],
    [-8.645085142038637, 40.808160611990758],
    [-8.643906960363266, 40.809066322170167],
    [-8.642728746650473, 40.809972020367169],
    [-8.641550500898783, 40.810877706580619],
    [-8.640372223106741, 40.811783380809338],
    [-8.636837197473573, 40.814500331575672],
    [-8.632103138925805, 40.81542139055562],
    [-8.628540784819934, 40.814536254857764],
    [-8.627355581794337, 40.81454133805898],
    [-8.626170378466409, 40.814546409086105],
    [-8.630971778363808, 40.822630173391467],
    [-8.633362768565576, 40.825321289152285],
    [-8.634548162399188, 40.825316143132213],
    [-8.635733555925942, 40.825310984933495],
    [-8.636918949145146, 40.825305814556152],
    [-8.638097511689981, 40.82440017602935],
    [-8.64520284021062, 40.823468370808939],
    [-8.646409026638922, 40.826164467780806],
    [-8.648807742237508, 40.829755711237567],
    [-8.647629232472935, 40.830661458032537],
    [-8.646457635735434, 40.832467646517728],
    [-8.644114252630317, 40.836079987234903],
    [-8.64294936541917, 40.838786592645604],
    [-8.644134997335671, 40.838781346713922],
    [-8.659576681787568, 40.842313842733034],
    [-8.65724786204426, 40.847727341202976],
    [-8.656069152307705, 40.848633172638664],
    [-8.654890410470218, 40.849538992083104],
    [-8.65489747421846, 40.850439441747284],
    [-8.65490453828636, 40.851339891269475],
    [-8.654911602673893, 40.852240340649644],
    [-8.654918667381109, 40.853140789887746],
    [-8.654925732408035, 40.854041238983882],
    [-8.654932797754643, 40.854941687937966],
    [-8.656161210532575, 40.860339006838437],
    [-8.657347224195583, 40.860333622803587],
    [-8.659612311884977, 40.84681609191481],
    [-8.660790941053094, 40.845910224483404],
    [-8.661955222221273, 40.843203445934883],
    [-8.663119409704231, 40.840496654950961],
    [-8.66311223716629, 40.839596204918386],
    [-8.663105064952864, 40.838695754743753],
    [-8.663097893063929, 40.837795304427075],
    [-8.663090721499515, 40.836894853968381],
    [-8.663083550259545, 40.835994403367607],
    [-8.663076379344041, 40.835093952624796],
    [-8.663069208752978, 40.834193501739925],
    [-8.663026192018867, 40.828790793447816],
    [-8.664204457740905, 40.827884890569209],
    [-8.665375491658668, 40.826078523894665],
    [-8.666546462424867, 40.824272145163498],
    [-8.667702910041848, 40.820664849869694],
    [-8.667695680529661, 40.81976439740243],
    [-8.667666765751314, 40.816162586112959],
    [-8.668830259394387, 40.813455729213146],
    [-8.669993659526858, 40.81074885990359],
    [-8.671149693180578, 40.807141524253311],
    [-8.672305603634189, 40.803534175554788],
    [-8.673461390920899, 40.79992681381249],
    [-8.673454088873108, 40.799026358949149],
    [-8.673446787155587, 40.798125903943763],
    [-8.672232744910401, 40.794529624461511],
    [-8.673395684379805, 40.791822714928237],
    [-8.675743272452859, 40.789110227766606],
    [-8.679282740572749, 40.787292547562139],
    [-8.680460055800445, 40.78638647823368],
    [-8.681637339027947, 40.78548039695616],
    [-8.68635362370634, 40.782756407991897],
    [-8.687530762613298, 40.781850266948879],
    [-8.689877425740185, 40.779137493536112],
    [-8.691046918996348, 40.777330861103508],
    [-8.692208800311825, 40.774623760818486],
    [-8.693370588415503, 40.771916648184749],
    [-8.693363024800982, 40.771016191948355],
    [-8.694517126520141, 40.767408610101363],
    [-8.69569388506123, 40.766502385884351],
    [-8.696870611634413, 40.765596149736545],
    [-8.69804730624119, 40.764689901659096],
    [-8.699208688341105, 40.761982728304609],
    [-8.700377632363487, 40.760175999608187],
    [-8.700369977323261, 40.759275542619996],
    [-8.700362322629156, 40.758375085489632],
    [-8.700354668281223, 40.757474628217153],
    [-8.700347014279409, 40.756574170802523],
    [-8.700339360623717, 40.75567371324577],
    [-8.700331707314094, 40.754773255546844],
    [-8.700324054350565, 40.753872797705803],
    [-8.700316401733085, 40.752972339722596],
    [-8.700308749461644, 40.752071881597317],
    [-8.700301097536199, 40.751171423329879],
    [-8.699086493744874, 40.747575401048387],
    [-8.697872022881496, 40.743979362881277],
    [-8.69786440600042, 40.743078902968058],
    [-8.696665284795833, 40.741283769355682],
    [-8.695451062348992, 40.737687700182647],
    [-8.696612092819201, 40.734980542715498],
    [-8.69660449533961, 40.734080081198456],
    [-8.697757805819258, 40.730472449261029],
    [-8.697750194102307, 40.729571987216119],
    [-8.69774258272952, 40.728671525029114],
    [-8.697734971700818, 40.727771062699972],
    [-8.69772736101625, 40.726870600228729],
    [-8.69888049072042, 40.723262953800798],
    [-8.698872865807171, 40.722362490802062],
    [-8.697658880336565, 40.718766431593068],
    [-8.700018219385973, 40.717854367520133],
    [-8.701194037280993, 40.716948085746779],
    [-8.702369823287695, 40.716041792067116],
    [-8.703545577407505, 40.715135486482275],
    [-8.707080381169273, 40.713316961578755],
    [-8.709423869755502, 40.710603780020506],
    [-8.711767168828631, 40.707890550904551],
    [-8.71411027842195, 40.705177274244541],
    [-8.716453198568733, 40.702463950054046],
    [-8.717620651880452, 40.700657038405872],
    [-8.718788042535831, 40.698850114820715],
    [-8.719947468918042, 40.696142715538109],
    [-8.71993956765052, 40.695242251633111],
    [-8.719931666740134, 40.694341787585948],
    [-8.719923766186863, 40.693441323396613],
    [-8.719915865990702, 40.69254085906509],
    [-8.719907966151629, 40.691640394591424],
    [-8.719900066669647, 40.690739929975564],
    [-8.718677665229375, 40.686243610528422],
    [-8.718669783808437, 40.685343144869883],
    [-8.718661902743744, 40.684442679069178],
    [-8.717447567808497, 40.680846806954861],
    [-8.71743970443937, 40.679946340254084],
    [-8.718590989188446, 40.676338480465667],
    [-8.719765830089559, 40.675432009754523],
    [-8.722107492266334, 40.672718565624223],
    [-8.723274282803585, 40.670911592162305],
    [-8.72442510278322, 40.667303671825515],
    [-8.725591737855087, 40.665496674345611],
    [-8.726750326568354, 40.662789197076229],
    [-8.729099282461338, 40.660976078361941],
    [-8.732630539328472, 40.659156778931873],
    [-8.733804889723245, 40.658250166138096],
    [-8.734979208324253, 40.657343541483847],
    [-8.736153495132946, 40.656436904970313],
    [-8.737319626519758, 40.654629789184945],
    [-8.738485695432027, 40.652822661507102],
    [-8.738477557010032, 40.651922193862426],
    [-8.738469418955784, 40.651021726075548],
    [-8.739619089779309, 40.647413650153197],
    [-8.740801308774206, 40.647407434698408],
    [-8.745521949513293, 40.646481984540614],
    [-8.746704150767842, 40.64647570877306],
    [-8.74788635164971, 40.646469420903763],
    [-8.74906855215818, 40.646463120932673],
    [-8.750250752292564, 40.646456808859824],
    [-8.756153373175035, 40.645524601308871],
    [-8.757369117596117, 40.649120077809741],
    [-8.756195247723182, 40.650026928189561],
    [-8.755038067512652, 40.652734696601151],
    [-8.753872448821639, 40.654541988194545],
    [-8.752715098695806, 40.657249732472891],
    [-8.751557656206687, 40.659957464566801],
    [-8.750400121335916, 40.662665184472964],
    [-8.749242494065196, 40.665372892188067],
    [-8.748084774376157, 40.668080587708793],
    [-8.746926962250482, 40.670788271031768],
    [-8.746935219749849, 40.671688734308873],
    [-8.749341963581205, 40.676178447591582],
    [-8.748167514050428, 40.677085216357725],
    [-8.747001293181661, 40.678892435403696],
    [-8.745835009780114, 40.680699642559532],
    [-8.744685125248989, 40.684307761328753],
    [-8.743535118957807, 40.687915867286918],
    [-8.743543335788898, 40.68881632845499],
    [-8.742393193266849, 40.692424421226001],
    [-8.742401396030729, 40.693324881880102],
    [-8.741251117233389, 40.696932961458792],
    [-8.740100716566557, 40.70054102821252],
    [-8.740108890806255, 40.701441487979864],
    [-8.73895835378136, 40.705049541531487],
    [-8.738966513934251, 40.705950000783815],
    [-8.737815840506842, 40.709558041128147],
    [-8.73665691387656, 40.712265610127965],
    [-8.735506010930935, 40.715873625164051],
    [-8.734346884039448, 40.718581169452875],
    [-8.733187664407515, 40.721288701496817],
    [-8.73203642422162, 40.724896678727518],
    [-8.730877004196524, 40.727604186038626],
    [-8.729725534221814, 40.731212137920878],
    [-8.72856591371254, 40.733919620484421],
    [-8.727414213838584, 40.737527547001967],
    [-8.727422227677081, 40.738428003076073],
    [-8.729797936239962, 40.739316242957202],
    [-8.735725292063551, 40.740185944789474],
    [-8.736909152831332, 40.740179757649983],
    [-8.741652798692451, 40.741055340985312],
    [-8.746396566691679, 40.741930728329194],
    [-8.747580456045883, 40.741924431517283],
    [-8.752324360605584, 40.742799574082227],
    [-8.753508264043026, 40.742793216357931],
    [-8.758252305099946, 40.74366811412014],
    [-8.759436222603076, 40.743661695479837],
    [-8.764180400093885, 40.744536348415394],
    [-8.765364331645218, 40.744529868855558],
    [-8.770108645506559, 40.745404276940612],
    [-8.771292591088566, 40.745397736457704],
    [-8.776037041257128, 40.746271899668407],
    [-8.777221000852254, 40.746265298258891],
    [-8.781965587264661, 40.747139216571419],
    [-8.783149560855422, 40.74713255423169],
    [-8.787894283448317, 40.748006227622177],
    [-8.792639127794727, 40.748879704859135],
    [-8.79382312972713, 40.748872932793461],
    [-8.795007131256545, 40.748866148582763],
    [-8.79619113238223, 40.748859352227143],
    [-8.797366172756005, 40.747952101385678],
    [-8.798523230591941, 40.745243953723516],
    [-8.798514255899633, 40.744343511027708],
    [-8.79850528161311, 40.743443068189315],
    [-8.799653228785251, 40.739834464776628],
    [-8.799644240566398, 40.738934021441089],
    [-8.799635252753916, 40.738033577962945],
    [-8.800783049667011, 40.734424961146367],
    [-8.800774047929554, 40.733524517171411],
    [-8.800765046599054, 40.732624073053884],
    [-8.800756045675513, 40.731723628793766],
    [-8.801903678062127, 40.728114998225152],
    [-8.803051188947228, 40.724506354971666],
    [-8.803042159788095, 40.723605909861497],
    [-8.803033131037168, 40.722705464608751],
    [-8.803024102694421, 40.72180501921342],
    [-8.803015074759804, 40.720904573675497],
    [-8.803006047233337, 40.720004127994997],
    [-8.802997020114978, 40.719103682171983],
    [-8.802987993404718, 40.718203236206364],
    [-8.802978967102515, 40.717302790098159],
    [-8.802969941208364, 40.716402343847399],
    [-8.802960915722263, 40.715501897454061],
    [-8.804108162036458, 40.711893237987695],
    [-8.806447636612198, 40.709178116875911],
    [-8.808786921997887, 40.706462948584466],
    [-8.809951935710352, 40.704655123334547],
    [-8.811098625565569, 40.701046392111863],
    [-8.811089495503923, 40.700145944821593],
    [-8.811080365854988, 40.69924549738878],
    [-8.811062107795067, 40.697444602095267],
    [-8.81105297938408, 40.696544154234644],
    [-8.812199476614291, 40.69293540860744],
    [-8.812190334342828, 40.692034960253416],
    [-8.813336696115149, 40.688426201631337],
    [-8.814482936735335, 40.684817430369534],
    [-8.81562905623608, 40.681208646472392],
    [-8.815619871593242, 40.680308196925438],
    [-8.815610687365503, 40.679407747235913],
    [-8.815583137172718, 40.676706397311648],
    [-8.815573954605181, 40.675805947051693],
    [-8.814372919444793, 40.674012030591769],
    [-8.811989308878463, 40.672225060388989],
    [-8.810770203191266, 40.668630199831405],
    [-8.810761087973194, 40.667729747408131],
    [-8.811907128780705, 40.664120990553975],
    [-8.811897999721827, 40.663220537637187],
    [-8.811888871075503, 40.662320084577843],
    [-8.811879742841711, 40.661419631375935],
    [-8.811870615020425, 40.66051917803145],
    [-8.811861487611619, 40.65961872454438],
    [-8.811852360615266, 40.65871827091474],
    [-8.811843234031368, 40.657817817142536],
    [-8.811834107859873, 40.656917363227777],
    [-8.811824982100756, 40.656016909170418],
    [-8.811815856754043, 40.655116454970518],
    [-8.811806731819681, 40.654216000628068],
    [-8.811797607297647, 40.653315546143006],
    [-8.811788483187941, 40.652415091515415],
    [-8.811779359490512, 40.651514636745205],
    [-8.811770236205357, 40.650614181832516],
    [-8.811761113332429, 40.649713726777193],
    [-8.81175199087175, 40.648813271579343],
    [-8.811742868823297, 40.647912816238929],
    [-8.811733747186993, 40.647012360755944],
    [-8.811724625962889, 40.64611190513039],
    [-8.811715505150909, 40.645211449362286],
    [-8.811706384751053, 40.644310993451597],
    [-8.811697264763295, 40.643410537398417],
    [-8.811688145187635, 40.64251008120263],
    [-8.811679026024018, 40.641609624864294],
    [-8.811669907272455, 40.640709168383417],
    [-8.81166078893291, 40.639808711759962],
    [-8.811651671005334, 40.638908254994],
    [-8.811642553489758, 40.63800779808544],
    [-8.811633436386126, 40.637107341034337],
    [-8.811624319694442, 40.6362068838407],
    [-8.81161520341465, 40.635306426504478],
    [-8.811606087546778, 40.6344059690257],
    [-8.811596972090751, 40.633505511404394],
    [-8.811587857046566, 40.632605053640546],
    [-8.811578742414223, 40.631704595734156],
    [-8.811569628193704, 40.630804137685203],
    [-8.811560514384947, 40.629903679493715],
    [-8.811551400987961, 40.629003221159657],
    [-8.811542288002739, 40.628102762683056],
    [-8.81153317542921, 40.627202304063943],
    [-8.811524063267397, 40.626301845302272],
    [-8.811514951517276, 40.625401386398053],
    [-8.811505840178794, 40.624500927351285],
    [-8.811496729251951, 40.623600468161996],
    [-8.811487618736724, 40.622700008830144],
    [-8.811478508633112, 40.621799549355764],
    [-8.811460289660577, 40.619998629979392],
    [-8.811451180791632, 40.619098170077393],
    [-8.811442072334174, 40.618197710032867],
    [-8.814959666881023, 40.615475454825706],
    [-8.816141305204926, 40.615468472576715],
    [-8.817322943114517, 40.615461478239602],
    [-8.818504580609137, 40.615454471814374],
    [-8.819686217687991, 40.615447453301094],
    [-8.822040241031402, 40.614532921384573],
    [-8.823203331401997, 40.612724949596405],
    [-8.823194067232084, 40.611824490765692],
    [-8.823184803480661, 40.610924031792422],
    [-8.823175540147718, 40.61002357267656],
    [-8.823166277233238, 40.6091231134181],
    [-8.823119968937105, 40.604620814986994],
    [-8.823110708532974, 40.603720354873026],
    [-8.823101448547146, 40.602819894616459],
    [-8.823092188979626, 40.601919434217329],
    [-8.823082929830386, 40.601018973675615],
    [-8.823073671099378, 40.600118512991337],
    [-8.823064412786623, 40.599218052164431],
    [-8.824208670343459, 40.595609145476388],
    [-8.824199398276003, 40.594708684159748],
    [-8.825343521180924, 40.59109976455067],
    [-8.826487523639333, 40.58749083237209],
    [-8.827631405683777, 40.583881887628323],
    [-8.827622091550712, 40.582981425129695],
    [-8.827612777838318, 40.582080962488511],
    [-8.828756511200556, 40.57847200449239],
    [-8.82874718375944, 40.577571541362822],
    [-8.829890782639454, 40.573962570465511],
    [-8.831043616942889, 40.571254050837283],
    [-8.832205730371484, 40.569445983088904],
    [-8.832196359668133, 40.568545519209238],
    [-8.832186989387971, 40.567645055186958],
    [-8.832177619530977, 40.566744591022086],
    [-8.832168250097149, 40.565844126714637],
    [-8.832158881086436, 40.564943662264611],
    [-8.832149512498866, 40.564043197671928],
    [-8.832140144334359, 40.563142732936662],
    [-8.832130776592942, 40.562242268058803],
    [-8.83212140927459, 40.56134180303836],
    [-8.832112042379226, 40.560441337875311],
    [-8.832083944231142, 40.557739941530656],
    [-8.833227041063783, 40.554130929549054],
    [-8.834379411611481, 40.551422371312242],
    [-8.835522282289228, 40.547813334609664],
    [-8.836674455383156, 40.545104752204736],
    [-8.836665032877395, 40.544204285380836],
    [-8.837807663408379, 40.540595223630213],
    [-8.837798227228067, 40.539694756320699],
    [-8.837788791473823, 40.538794288868573],
    [-8.838931273791415, 40.535185213915007],
    [-8.840073636127393, 40.531576126449011],
    [-8.841215878514117, 40.527967026474855],
    [-8.842358000984008, 40.524357913996937],
    [-8.842348509314592, 40.523457445033415],
    [-8.842339018073687, 40.522556975927301],
    [-8.843480992515985, 40.518947850267281],
    [-8.84347148763297, 40.518047380677189],
    [-8.844613328168618, 40.514438242181761],
    [-8.845764581908766, 40.511729561468371],
    [-8.846915744417599, 40.509020868807525],
    [-8.848057253738201, 40.505411693535862],
    [-8.849198643341374, 40.501802505786792],
    [-8.850339913259486, 40.498193305564691],
    [-8.850330323632599, 40.497292834076468],
    [-8.850320734438625, 40.496392362445597],
    [-8.851461856637224, 40.492783149076928],
    [-8.845554955111142, 40.491919182545807],
    [-8.840827647889775, 40.491047696045165],
    [-8.837279892867867, 40.490168834705159],
    [-8.832552811008391, 40.48929700752106],
    [-8.82782584891074, 40.488424985836126],
    [-8.826646444557685, 40.488432069415019],
    [-8.82191961635098, 40.487559804887312],
    [-8.820740225673495, 40.48756682807285],
    [-8.816013531420278, 40.486694320725931],
    [-8.814834154435641, 40.486701283521654],
    [-8.813654777039719, 40.486708234283732],
    [-8.808928230923913, 40.485835435788452],
    [-8.807748867241704, 40.485842326164523],
    [-8.806569503152486, 40.485849204507289],
    [-8.801843105266867, 40.484976114893961],
  ]

  // check if a point (p2) is left of two points (p0,p1)
  private isLeft(p0: any, p1: any, p2: any) {
    return (p1[0] - p0[0]) * (p2[1] - p0[1]) - (p2[0] - p0[0]) * (p1[1] - p0[1])
  }

  private pointInPolyWindingNumber(point: any, polygon: any) {
    if (polygon.length === 0) {
      return false
    }

    const newPoints = polygon.slice(0)
    newPoints.push(polygon[0])
    // winding number counter
    let wn = 0

    // loop through all edges of the polygon
    for (let i = 0; i < polygon.length; i++) {
      if (newPoints[i][1] <= point[1]) {
        if (newPoints[i + 1][1] > point[1]) {
          if (this.isLeft(newPoints[i], newPoints[i + 1], point) > 0) {
            wn++
          }
        }
      } else {
        if (newPoints[i + 1][1] <= point[1]) {
          if (this.isLeft(newPoints[i], newPoints[i + 1], point) < 0) {
            wn--
          }
        }
      }
    }
    // the point is outside only when this winding number wn===0, otherwise it's inside
    return wn !== 0
  }

  public isOverLand(x: any, y: any): boolean {
    if (this.pointInPolyWindingNumber([x, y], this.land)) {
      return true
    }

    return false
  }

  public isHighTraffic(x: any, y: any): boolean {
    if (
      this.pointInPolyWindingNumber([x, y], this.portoTraffic) ||
      this.pointInPolyWindingNumber([x, y], this.aveiroTraffic) ||
      this.pointInPolyWindingNumber([x, y], this.figueiraFozTraffic) ||
      this.pointInPolyWindingNumber([x, y], this.penicheTraffic) ||
      this.pointInPolyWindingNumber([x, y], this.lisboaTraffic) ||
      this.pointInPolyWindingNumber([x, y], this.setubalTraffic) ||
      this.pointInPolyWindingNumber([x, y], this.sinesTraffic)
    ) {
      return true
    }

    return false
  }

  public CCW(p1: any, p2: any, p3: any) {
    return (p3.y - p1.y) * (p2.x - p1.x) > (p2.y - p1.y) * (p3.x - p1.x)
  }

  public isIntersecting(p1: any, p2: any, p3: any, p4: any) {
    return this.CCW(p1, p3, p4) !== this.CCW(p2, p3, p4) && this.CCW(p1, p2, p3) !== this.CCW(p1, p2, p4)
  }
}
