import React, { Component } from 'react'
import { Popup } from 'react-leaflet'
import IPosHeadingAtTime from '../../../model/ILatLngHead'
import DateService from '../../../services/DateUtils'
import PositionService from '../../../services/PositionUtils'
import RotatedMarker from './RotatedMarker'
import CheckPosition from './CheckPosition'

interface PropsType {
  vehicle: string
  position: IPosHeadingAtTime
  icon: L.Class
  rotationAngle: number
  zoom: number
  isAIS?: boolean
}
export default class EstimatedPosition extends Component<PropsType, {}> {
  private positionService: PositionService = new PositionService()
  private checkPosition: CheckPosition = new CheckPosition()

  shouldComponentUpdate(nextProps: PropsType) {
    return (
      this.props.position.latitude !== nextProps.position.latitude ||
      this.props.position.longitude !== nextProps.position.longitude
    )
  }

  public render() {
    const estimatedPos = this.props.position

    if (
      this.props.isAIS !== undefined &&
      this.props.isAIS &&
      this.checkPosition.isOverLand(estimatedPos.latitude.toFixed(13), estimatedPos.longitude.toFixed(13))
    )
      return <></>
    else
      return (
        <RotatedMarker
          key={'estimated_' + this.props.vehicle}
          rotationAngle={this.props.rotationAngle}
          rotationOrigin={'center'}
          position={this.positionService.getLatLng(estimatedPos)}
          zoom={this.props.zoom}
          icon={this.props.icon}
          opacity={0.7}
          zIndexOffset={-999}
        >
          <Popup>
            <h3>Estimated Position of {this.props.vehicle}</h3>
            <ul>
              <li>Lat: {estimatedPos.latitude.toFixed(5)}</li>
              <li>Lng: {estimatedPos.longitude.toFixed(5)}</li>
              <li>Date: {DateService.timestampMsToReadableDate(estimatedPos.timestamp)}</li>
            </ul>
          </Popup>
        </RotatedMarker>
      )
  }
}
