import IAircraft from '../model/IAircraft'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export default class AircraftService {
  public async fetchAircraftData(): Promise<IAircraft[]> {
    const response = await fetch(`${apiURL}/aircraft`)
    const aircrafts = await response.json()
    return aircrafts
  }

  public async addAssetFromAircraft(system: IAircraft, aircraftName: string) {
    return request({
      method: 'POST',
      url: `${apiURL}/aircraft/${system.hex}/${aircraftName}`,
    })
  }

  public getcategoryReadable(cat: string) {
    switch (cat) {
      case 'A1':
        return 'Light'
      case 'A2':
        return 'Small'
      case 'A3':
        return 'Large'
      case 'A4':
        return 'High vortex large'
      case 'A5':
        return 'Heavy'
      case 'A6':
        return 'High performance'
      case 'A7':
        return 'Rotorcraft'
      case 'B1':
        return 'Glider / sailplane'
      case 'B2':
        return 'Lighter-than-air'
      case 'B3':
        return 'Parachutist / skydiver'
      case 'B4':
        return 'Ultralight / hang-glider / paraglider'
      case 'B5':
        return 'Reserved'
      case 'B6':
        return 'Unmanned aerial vehicle'
      case 'B7':
        return 'Space / trans-atmospheric vehicle'
      case 'C1':
        return 'Surface vehicle (emergency)'
      case 'C2':
        return 'Surface vehicle (service)'
      case 'C3':
        return 'Point obstacle'
      case 'C4':
        return 'Cluster obstacle'
      case 'C5':
        return 'Line obstacle'
      case 'C6':
        return 'Reserved'
      case 'C7':
        return 'Reserved'
      default:
        return 'undefined'
    }
  }
}
